import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import FormField from "../../components/localForms/formField/formField";
import Tooltip from "../../components/localForms/tooltip/tooltip";

const KontentItemInputTypeSingleInput = (props) => {
  const {
    data,
    errors,
    touched,
    onChange,
    onBlur,
    meta,
  } = props;

  const { elements } = data;

  return (
    <Col xs={12} md={6}>
      <Tooltip
        id={elements.id.value}
        tooltipMessage={elements.tooltip.value}
      >
        <FormField
          id={elements.id.value}
          type={elements.data_type.value[0].codename}
          maxLength={elements.max_length.value}
          errors={errors}
          touched={touched}
          meta={meta}
          label={elements.label.value}
          placeholder={elements.placeholder.value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Tooltip>
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeSingleInput on kontent_item_input_type_single_input {
    id
    elements {
      data_type {
        value {
          codename
        }
      }
      id {
        value
      }
      label {
        value
      }
      max_length {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip {
        value
      }
      placeholder {
        value
      }
    }     
  } 
`;

KontentItemInputTypeSingleInput.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeSingleInput;
