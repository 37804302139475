import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./textSection.scss";

const TextSection = (props) => {
  const {
    headline,
    paragraphHeadline,
    textHeadline,
    text,
    textBeforeMedia,
    media,
    mediaAlignLeft,
    className,
  } = props;

  return (
    <Row className={classNames(
      "text-section",
      { "text-section--media": media },
      className,
    )}
    >
      <Col sm={10} lg={8}>
        {headline && (
          <h2>{headline}</h2>
        )}
        {paragraphHeadline && (
          <h3>{paragraphHeadline}</h3>
        )}
        {textHeadline && (
          <h4>{textHeadline}</h4>
        )}
        {textBeforeMedia && (
          <div className="text-section__text-before">{textBeforeMedia}</div>
        )}
        {(media || text) && (
          <div className="text-section__text-container">
            {media && (
              <div
                className={classNames(
                  "text-section__media",
                  { "text-section__media--align-left": mediaAlignLeft },
                )}
              >
                {media}
              </div>
            )}
            {text && <div className="text-section__text">{text}</div>}
          </div>
        )}
      </Col>
    </Row>
  );
};

TextSection.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  media: PropTypes.oneOfType([PropTypes.object]),
  mediaAlignLeft: PropTypes.bool,
  paragraphHeadline: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textBeforeMedia: PropTypes.string,
  textHeadline: PropTypes.string,
};

TextSection.defaultProps = {
  className: null,
  headline: null,
  media: null,
  mediaAlignLeft: false,
  paragraphHeadline: null,
  text: null,
  textBeforeMedia: null,
  textHeadline: null,
};

export default TextSection;
