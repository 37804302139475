import * as Yup from "yup";

const MAX_LENGTH_ERROR = "form.error.max_length_exact";
const MIN_LENGTH_ERROR = "form.error.min_length_exact";
const MIN_LENGTH_ERROR_DIGITS = "form.error.exact_digits";
const GT_OR_EQ_ERROR = "form.error.gt_or_eq";
const LT_OR_EQ_ERROR = "form.error.lt_or_eq";
const REQUIRED_ERROR = "form.required";
const INVALID_DONOR_ID_PATTERN = "form.error.donor_id_pattern";
const INVALID_SOCIAL_SECURITY_NO = "form.error.social_security_number";
const INVALID_PHONE_PATTERN = "form.error.phone_pattern";
// const INVALID_MOBILE_PATTERN_IN = "form.error.mobile";
const INVALID_COUNTRY_CODE_PATTERN = "form.error.invalid_country_code";
const INVALID_EMAIL_PATTERN = "registration.additional.error.email";

const MAX_LENGTH_VALIDATOR = "max";
const MIN_LENGTH_VALIDATOR = "min";
const PATTERN_VALIDATOR = "matches";
const EMAIL_VALIDATOR = "email";
const REQUIRED_VALIDATOR = "required";
const ONE_OF_VALIDATOR = "oneOf";
const WHEN_VALIDATOR = "when";

const ZIP_PATTERN = [/\s*([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})\s*/, 5];

const PHONE_PATTERN = [/^[\d\s\-+/]{5,}$/, INVALID_PHONE_PATTERN, { max: 25, min: 5 }];

const MOBILE_PATTERN = [/^[\d\s\-+/]{5,}$/, INVALID_PHONE_PATTERN];

let instance = null;

export default class Validators {
  intl = null;

  constructor(intl) {
    if (!instance) {
      instance = this;
    }

    this.intl = intl;

    return instance;
  }

   createValidator = (validatorType, args) => ({
     params: [...args],
     type: validatorType,
   });

   getTranslation = (id, value = null) => this.intl.formatMessage(
     { id },
     { value },
   );

   getValidations = (key) => {
     switch (key) {
       /**
       * Person
       */
       case "firstname":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [40, this.getTranslation(MAX_LENGTH_ERROR, 40)],
           ),
         ];
       case "lastname":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "donor_id":
         return [
           this.createValidator(
             MIN_LENGTH_VALIDATOR,
             [4, this.getTranslation(MIN_LENGTH_ERROR, 4)],
           ),
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [20, this.getTranslation(MAX_LENGTH_ERROR, 20)],
           ),
           this.createValidator(
             PATTERN_VALIDATOR, [/^[A-Z]{3,5}[0-9]+$/,
               this.getTranslation(INVALID_DONOR_ID_PATTERN)],
           ),
         ];
         /**
       * Address
       */
       case "info":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "apartment":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "building":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "co":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "street":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [50, this.getTranslation(MAX_LENGTH_ERROR, 50)],
           ),
         ];
       case "houseno":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [10, this.getTranslation(MAX_LENGTH_ERROR, 10)],
           ),
         ];
       case "zipcode": {
         const [pattern, exactLength] = ZIP_PATTERN;

         return [
           this.createValidator(
             PATTERN_VALIDATOR,
             [
               pattern,
               this.getTranslation(MIN_LENGTH_ERROR_DIGITS, exactLength),
             ],
           ),
         ];
       }
       case "city":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [50, this.getTranslation(MAX_LENGTH_ERROR, 50)],
           ),
         ];
       case "region":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "state":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "country":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "country_code":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [2, this.getTranslation(MAX_LENGTH_ERROR, 2)],
           ),
           this.createValidator(
             PATTERN_VALIDATOR,
             [/^[A-Z]{2}$/, this.getTranslation(INVALID_COUNTRY_CODE_PATTERN)],
           ),
         ];
         /**
       * Contact
       */
       case "phone": {
         const [pattern, errorMsg, minMax] = PHONE_PATTERN;

         return [
           this.createValidator(
             MIN_LENGTH_VALIDATOR,
             [minMax.min, this.getTranslation(MIN_LENGTH_ERROR, minMax.min)],
           ),
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [minMax.max, this.getTranslation(MAX_LENGTH_ERROR, minMax.max)],
           ),
           this.createValidator(
             PATTERN_VALIDATOR,
             [pattern, this.getTranslation(errorMsg)],
           ),
         ];
       }
       case "mobile": {
         const [pattern, errMsg] = MOBILE_PATTERN;

         return [
           this.createValidator(
             PATTERN_VALIDATOR,
             [pattern, this.getTranslation(errMsg)],
           ),
         ];
       }
       case "email":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
           this.createValidator(
             EMAIL_VALIDATOR,
             [this.getTranslation(INVALID_EMAIL_PATTERN)],
           ),
         ];
         /**
       * General Practitioner
       */
       case "gp_name":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [100, this.getTranslation(MAX_LENGTH_ERROR, 100)],
           ),
         ];
       case "gp_city":
         return [
           this.createValidator(
             MAX_LENGTH_VALIDATOR,
             [50, this.getTranslation(MAX_LENGTH_ERROR, 50)],
           ),
         ];
         /**
       * Privacy
       */
       case "privacy":
         return [
           this.createValidator(
             ONE_OF_VALIDATOR, [[true], this.getTranslation(REQUIRED_ERROR)],
           ),
         ];
         /**
       * Registration
       */
       case "weightImperialSt":
         return [
           this.createValidator(WHEN_VALIDATOR, [
             "bmiUnit", {
               is: (value) => value === "imperial",
               then: Yup.number()
                 .required(this.getTranslation(REQUIRED_ERROR))
                 .min(0, this.getTranslation(GT_OR_EQ_ERROR, 0))
                 .max(47, this.getTranslation(LT_OR_EQ_ERROR, 47)),
             },
           ]),
         ];
       case "weightImperialLbs":
         return [
           this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
             is: (value) => value === "imperial",
             then: Yup.number()
               .required(this.getTranslation(REQUIRED_ERROR))
               .min(0, this.getTranslation(GT_OR_EQ_ERROR, 0))
               .max(660, this.getTranslation(LT_OR_EQ_ERROR, 660)),
           }]),
         ];
       case "heightImperialFeet":
         return [
           this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
             is: (value) => value === "imperial",
             then: Yup.number()
               .required(this.getTranslation(REQUIRED_ERROR))
               .min(1, this.getTranslation(GT_OR_EQ_ERROR, 1))
               .max(8, this.getTranslation(LT_OR_EQ_ERROR, 8)),
           }]),
         ];
       case "heightImperialInch":
         return [
           this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
             is: (value) => value === "imperial",
             then: Yup.number()
               .required(this.getTranslation(REQUIRED_ERROR))
               .min(0, this.getTranslation(GT_OR_EQ_ERROR, 0))
               .max(12, this.getTranslation(LT_OR_EQ_ERROR, 12)),
           }]),
         ];
       case "weightMetric":
         return [
           this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
             is: (value) => value === "metric",
             then: Yup.number()
               .required(this.getTranslation(REQUIRED_ERROR))
               .min(0, this.getTranslation(GT_OR_EQ_ERROR, 0))
               .max(300, this.getTranslation(LT_OR_EQ_ERROR, 300)),
           }]),
         ];
       case "heightMetric":
         return [
           this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
             is: (value) => value === "metric",
             then: Yup.number()
               .required(this.getTranslation(REQUIRED_ERROR))
               .min(50, this.getTranslation(GT_OR_EQ_ERROR, 50))
               .max(250, this.getTranslation(LT_OR_EQ_ERROR, 250)),
           }]),
         ];
       case "socialSecurityNumber":
         return [
           this.createValidator(
             PATTERN_VALIDATOR,
             [/^(\d{3}-\d{2}-\d{4}|\d{9})$/, this.getTranslation(INVALID_SOCIAL_SECURITY_NO)],
           ),
         ];
       default:
         // eslint-disable-next-line no-console
         console.error("No Such validator found");

         return {};
     }
   };

   get = (key, required) => {
     let validations = null;
     const requiredArray = required
       ? [this.createValidator(
         REQUIRED_VALIDATOR,
         [this.getTranslation(REQUIRED_ERROR)],
       )]
       : [];

     if (key) {
       validations = this.getValidations(key, this.intl);
     }

     return validations ? requiredArray.concat(validations) : requiredArray;
   };
}
