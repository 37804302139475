import React, { Component } from "react";

import classNames from "classnames";
import { graphql, StaticQuery } from "gatsby";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import {
  IconClose18,
  IconFamily18,
  IconMenu18,
  IconSearch24,
} from "../../../icons";
import KontentItemUniversalNavigation from "../../../templates/kontentItemUniversalNavigation";
import scrollActiveNavItem from "../../../utils/scrollActiveNavItem";
import TextLightbox from "../../lightbox/textLightbox";
import Logo from "../../logo";
import Search from "../../search/search";
import MobileNavigation from "../mobileNavigation";
import PrimaryNavigation from "../primaryNavigation";
import SecondaryNavigation from "../secondaryNavigation";

import "./navigation.scss";

// Todo: refactor whole navigation
class Navigation extends Component {
  constructor(props) {
    super(props);

    this.burgerNavigation = null;

    this.state = {
      burgerMenuSearching: false,
      burgerNavigationOpen: false,
      hasShadow: false,
      searchOpen: false,
    };

    this.allWebsitesOpen = null;
    this.scrollMenuClasses = null;
    this.scrollMenu = null;

    this.dropdownMenu = null;
    this.windowWidth = 0;
    this.resizeTimeout = null;

    this.navMenu = null;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.windowWidth = window.innerWidth;
      window.addEventListener("resize", this.resize);
    }

    if (this.navMenu) {
      this.navMenu.scrollLeft = scrollActiveNavItem(this.navMenu);
    }
    this.scrollTrigger();
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout);

    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.resize);
    }
  }

  toggleBurgerNavigation = (show = null) => {
    const {
      burgerNavigationOpen,
    } = this.state;

    const state = show !== null ? show : !burgerNavigationOpen;

    this.setState({
      burgerNavigationOpen: state,
      hasShadow: false,
    });

    if (state) {
      document.body.parentNode.classList.add("html-burger-navigation--open");
    } else {
      document.body.parentNode.classList.remove("html-burger-navigation--open");
    }
  };

  toggleSearch = (show = null) => {
    const {
      searchOpen,
    } = this.state;

    const state = show !== null ? show : !searchOpen;

    this.setState({
      searchOpen: state,
    });

    if (state) {
      document.body.parentNode.classList.add("html-search--open");
    } else {
      document.body.parentNode.classList.remove("html-search--open");
    }
  };

  resize = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth;
        this.toggleSearch(false);
        this.toggleBurgerNavigation(false);
        this.allWebsitesOpen.close();

        this.scrollTrigger();
      }
    }, 200);
  };

  scrollTrigger = () => {
    if (!this.scrollMenu) return null;

    const target = this.scrollMenu.firstElementChild;

    const {
      scrollMenuClasses,
    } = this.state;

    const classes = [];

    if (target.scrollLeft > 20) {
      classes.push("menu-scrolling--left");
    }

    if (
      target.scrollWidth > target.clientWidth
      && target.scrollLeft + target.clientWidth !== target.scrollWidth
    ) {
      classes.push("menu-scrolling--right");
    }

    const classesString = classes.join(" ");

    if (scrollMenuClasses !== classesString) {
      this.setState({ scrollMenuClasses: classesString });
    }

    return null;
  };

  render() {
    const {
      burgerNavigationOpen,
      allWebsitesOpen,
      searchOpen,
      burgerMenuSearching,
      scrollMenuClasses,
      hasShadow,
    } = this.state;

    const {
      intl,
    } = this.props;

    return (
      <div className="navigation container--fullwidth">
        <Container fluid>
          <Navbar expand="md" className="top-navigation nav-audience-utilities d-none d-lg-block">
            <Navbar.Collapse>
              <div className="d-none d-md-flex">
                <a href="/" title={intl.formatMessage({ id: "navigation.logo_title" })}>
                  <Logo breakpoint="lg" />
                </a>
              </div>
              <Nav className="d-flex flex-grow-1">
                <div className="ml-auto d-none d-lg-flex">
                  <SecondaryNavigation />
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <div
            className={classNames(
              "main-navigation__container container--fullwidth",
              { "scroll-shadow": hasShadow },
            )}
          >
            <Container fluid className="d-lg-flex">
              <Navbar expand="lg" className="main-navigation">
                <Navbar.Brand className="d-lg-none" href="/" title={intl.formatMessage({ id: "navigation.logo_title" })}>
                  <Logo breakpoint="lg" />
                </Navbar.Brand>
                <button
                  className={classNames("burger-navigation-toggle d-lg-none text-black", { "burger-navigation--open": burgerNavigationOpen })}
                  onClick={() => {
                    this.toggleBurgerNavigation();
                  }}
                  type="button"
                >
                  {process.env.GATSBY_SITE !== "labs" && (
                    <>{burgerNavigationOpen
                      ? <><FormattedMessage id="navigation.menu.close" /></>
                      : <><FormattedMessage id="navigation.menu.menu" /> </>}
                    </>
                  )}
                  <>
                    {burgerNavigationOpen ? <IconClose18 /> : <IconMenu18 />}
                  </>
                </button>
                <Navbar.Collapse
                  className={scrollMenuClasses}
                  ref={(node) => {
                    this.scrollMenu = node;
                  }}
                  onScroll={() => this.scrollTrigger()}
                >
                  <Nav
                    ref={(node) => {
                      this.navMenu = node;
                    }}
                  >
                    <>
                      <PrimaryNavigation />
                      <Nav.Item className="main-navigation__search d-none d-lg-inline">
                        <Nav.Link
                          onClick={() => {
                            this.toggleSearch();
                          }}
                          title={intl.formatMessage({ id: "navigation.menu.search" })}
                        >
                          <IconSearch24 />
                          <span className="d-none d-xl-inline">
                            <FormattedMessage id="navigation.menu.search" />
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="navigation-cta ml-auto d-none d-lg-flex">
                <button
                  className={classNames("all-websites-toggle")}
                  onClick={() => {
                    this.allWebsitesOpen.show();
                  }}
                  type="button"
                >
                  <span className="d-none d-xl-inline">
                    <FormattedMessage id="navigation.dkms_websites" />
                  </span>
                  <IconFamily18 />
                </button>
              </div>
            </Container>
          </div>

          {searchOpen && (
            <div className="search-overlay">
              <Search
                onCancel={() => {
                  this.toggleSearch();
                }}
                desktop
                focus
              />
            </div>
          )}

          {burgerNavigationOpen && (
            <div
              className="burger-navigation"
              ref={(ref) => {
                this.burgerNavigation = ref;
              }}
              onScroll={() => {
                if (!hasShadow && this.burgerNavigation?.scrollTop !== 0) {
                  this.setState({ hasShadow: !hasShadow });
                } else if (this.burgerNavigation?.scrollTop === 0) {
                  this.setState({ hasShadow: !hasShadow });
                }
              }}
            >

              <Search
                onFocus={() => {
                  this.setState({ burgerMenuSearching: true });
                }}
                onBlur={() => {
                  this.setState({ burgerMenuSearching: false });
                }}
              />

              {!burgerMenuSearching && (
                <Nav>
                  <StaticQuery
                    query={graphql`
                      {
                        primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
                          nodes {
                            id
                            elements {
                              elements {
                                value {
                                  id
                                  preferred_language
                                  ... on kontent_item_navigation_item {
                                    id,
                                    elements {
                                      title {
                                        value
                                      }
                                      url_slug {
                                        value
                                      }
                                      external_url {
                                        value
                                      }
                                      elements {
                                        value {
                                          ... on kontent_item_navigation_item {
                                            id
                                            elements {
                                              title {
                                                value
                                              }
                                              url_slug {
                                                value
                                              }
                                              external_url {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    `}
                    render={(data) => {
                      let activeKey = "";
                      data.primaryNavigation.nodes[0].elements.elements.value.forEach(
                        (item, index) => {
                          if (window.location.pathname.includes(item.elements.url_slug.value)) {
                            activeKey = `menu${index}`;
                          }
                        },
                      );

                      return (
                        <Accordion defaultActiveKey={activeKey}>
                          <MobileNavigation />
                          <div className="burger-navigation__footer">
                            <button
                              className={classNames("all-websites-toggle top-navigation__delimiter", { "html-all-websites--open": allWebsitesOpen })}
                              onClick={() => {
                                this.allWebsitesOpen.show();
                              }}
                              type="button"
                            >
                              <IconFamily18 />
                              <span>
                                <FormattedMessage id="navigation.dkms_websites" />
                              </span>
                            </button>
                          </div>
                        </Accordion>
                      );
                    }}
                  />
                </Nav>
              )}

            </div>
          )}
        </Container>

        <TextLightbox
          ref={(modal) => {
            this.allWebsitesOpen = modal;
          }}
          title={intl.formatMessage({ id: "navigation.dkms_websites" })}
        >
          <KontentItemUniversalNavigation />
        </TextLightbox>
      </div>
    );
  }
}

Navigation.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]),
};

Navigation.defaultProps = {
  intl: null,
};

export default injectIntl(Navigation);
