import React from "react";

import { graphql } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import FileDownload from "../components/fileDownload/fileDownload";
import { IconPdf18, IconZip18 } from "../icons";

const KontentItemAsset = ({ data }) => {
  const asset = data;

  if (!asset || !asset.elements) {
    return null;
  }

  let fileIcon = (<IconPdf18 />);

  if (_.find(asset.elements.media_type.value, { codname: "zip_archive" })) {
    fileIcon = (<IconZip18 />);
  }

  let image = null;

  if (asset.elements.image.value.length > 0) {
    image = <KontentItemImage data={asset.elements.image} crop aspectRatio={21 / 9} />;
  }

  return (
    <FileDownload
      title={asset.elements.title.value} // todo: martin language switch
      size={asset.elements.file_size.value}
      href={asset.elements.asset_item.value.length > 0 ? asset.elements.asset_item.value[0].url : ""}
      fileIcon={fileIcon}
      image={image}
    />
  );
};

export const query = graphql`
  fragment KontentItemAsset on kontent_item_asset {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          ...KontentItemImage
        }
      }
      title {
        value
      }
      file_size {
        value
      }
      media_type {
        value {
          codename
        }
      }
      asset_item {
        value {
          url
          name
        }
      }
    }
  }
`;

KontentItemAsset.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemAsset;
