import React from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import DefaultConsent from "./defaultConsent";
import MapTileContentBlocker from "./mapTileContentBlocker";
import {
  getComplianceSettings,
  setComplianceSettings,
  setShowComplianceSettings,
} from "../../features/complianceConsentSlice";

import "./consent.scss";

const Consent = ({ children, type }) => {
  const externalContentEnabled = useSelector((state) => (
    state.complianceConsent.externalContentEnabled
  ));
  const dispatch = useDispatch();

  const defaultConsentType = type !== "static-map";
  const showDefault = defaultConsentType && !externalContentEnabled;
  const showMapBlocker = !defaultConsentType && !externalContentEnabled;

  const enableExternalContent = () => {
    dispatch(
      setComplianceSettings({ consents: { custom_consent: { status: 1 } } }),
    ).then(() => {
      dispatch(
        getComplianceSettings(),
      );
    });
  };

  const openCookieConsent = () => {
    dispatch(
      setShowComplianceSettings(true),
    );
  };

  if (children && externalContentEnabled) {
    return children;
  }

  let consentWall = <></>;

  if (showDefault) {
    consentWall = (
      <DefaultConsent
        openCookieConsent={openCookieConsent}
        enableExternalContent={enableExternalContent}
        type={type}
      />
    );
  } else if (showMapBlocker) {
    consentWall = <MapTileContentBlocker />;
  }

  return consentWall;
};

Consent.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

Consent.defaultProps = {
  type: "",
};

export default Consent;
