import React from "react";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconAdd24,
  IconAddDonor30,
  IconAfrica,
  IconAnchorLeft18,
  IconAnchorRight18,
  IconAnchorRight24,
  IconArticle18,
  IconBacteria18,
  IconBigHome18,
  IconBloodSample30,
  IconBoneMarrowCollection30,
  IconBulletPoints18,
  IconCalendar18,
  IconCalendar24,
  IconCalendarArchive18,
  IconCalendarArchive24,
  IconCar30,
  IconCareers18,
  IconCharity30,
  IconCharityEvent30,
  IconCheck18,
  IconCheck24,
  IconCheckbox18,
  IconCheckboxFilled18,
  IconCheckUp30,
  IconChildren30,
  IconChile,
  IconClearInput18,
  IconClose18,
  IconClose24,
  IconConfirmatoryPing30,
  IconCooperation30,
  IconCooperation48,
  IconDefault18,
  IconDefault24,
  IconDefault30,
  IconDefault48,
  IconDelete18,
  IconDeleteInput18,
  IconDirectory18,
  IconDnaHla30,
  IconDnaHla48,
  IconDollar18,
  IconDonate18,
  IconDonate24,
  IconDonateDe30,
  IconDonateIn30,
  IconDonatePl30,
  IconDonateUk30,
  IconDonateUsCl30,
  IconDonor30,
  IconDonorDrive30,
  IconDonorPatientMeeting30,
  IconDownload18,
  IconDownload24,
  IconDrag24,
  IconDropdownExpand18,
  IconDropdownExpand24,
  IconDropdownMinimize18,
  IconDualToneAssets48,
  IconDualToneAssociations48,
  IconDualToneCharityEvent48,
  IconDualToneCompanies48,
  IconDualToneDoctors48,
  IconDualToneDonateDe48,
  IconDualToneDonateIn48,
  IconDualToneDonatePl48,
  IconDualToneDonateUk48,
  IconDualToneDonateUsCl48,
  IconDualToneDonorDrive48,
  IconDualToneEducators48,
  IconDualToneEvent48,
  IconDualToneEventHosting48,
  IconDualToneFactSheet48,
  IconDualToneInfluencer48,
  IconDualToneParents48,
  IconDualToneRegister48,
  IconDualToneSchools48,
  IconDualToneSportsClubs48,
  IconDualToneStudents48,
  IconDualToneVirtualDrive48,
  IconDualToneVolunteers48,
  IconError18,
  IconEuro18,
  IconEvent30,
  IconEvent48,
  IconEventHosting30,
  IconExpand24,
  IconExternalLink18,
  IconExternalLink24,
  IconFamilySearch30,
  IconFaq30,
  IconFax18,
  IconFilingOrganisation30,
  IconFilter24,
  IconFilterApplied24,
  IconFold18,
  IconFounding30,
  IconFounding48,
  IconFurther18,
  IconFurther24,
  IconGermany,
  IconGlobalDonors30,
  IconGlobalSearch30,
  IconGobalDonors48,
  IconHappyDonor30,
  IconHappyPatient30,
  IconHappyPatient48,
  IconHelp18,
  IconHelp24,
  IconHideHelp24,
  IconHome18,
  IconHouse30,
  IconIdmRequest30,
  IconImportant30,
  IconIndia,
  IconInfluencer30,
  IconInfo24,
  IconJpg18,
  IconLaunch30,
  IconLearnMore18,
  IconList24,
  IconLocalDonors30,
  IconLocalDonors48,
  IconLocation18,
  IconLocation24,
  IconMail18,
  IconMail24,
  IconMap24,
  IconMatch30,
  IconMatch48,
  IconMediaClue30,
  IconMenu18,
  IconMessage24,
  IconMinimize24,
  IconMp418,
  IconNews18,
  IconNews30,
  IconNotification30,
  IconOnlineRegistration30,
  IconPaperWork30,
  IconPath18,
  IconPathBack18,
  IconPatient30,
  IconPdf18,
  IconPhone18,
  IconPhone24,
  IconPlay48,
  IconPoland,
  IconPounds18,
  IconPreparationDli30,
  IconRadio30,
  IconRadioButton18,
  IconRadioButtonFilled18,
  IconRegistration30,
  IconRegistration48,
  IconRegistrationCosts30,
  IconRegistrationCosts48,
  IconRegistrationViaBlood30,
  IconRegistryEntry30,
  IconRegistryEntry48,
  IconRupie18,
  IconSadPatient30,
  IconSearch18,
  IconSearch24,
  IconSearchTeaser18,
  IconSelect24,
  IconSelectEmpty24,
  IconSendingBack30,
  IconSnapscan18,
  IconStemCellCollection30,
  IconStemCellCollection48,
  IconStop30,
  IconSubmit18,
  IconSubmit24,
  IconSuccess24,
  IconSwabbing30,
  IconSwabbing48,
  IconTargetGroupAssociations24,
  IconTargetGroupClub24,
  IconTargetGroupCompanies24,
  IconTargetGroupCompany24,
  IconTargetGroupDoctors24,
  IconTargetGroupEducators24,
  IconTargetGroupParents24,
  IconTargetGroupSchool24,
  IconTargetGroupSchools24,
  IconTargetGroupSportsClubs24,
  IconTargetGroupStudents24,
  IconTargetGroupVolunteers24,
  IconThumbsUp30,
  IconTick30,
  IconTickGreen30,
  IconTime18,
  IconTime24,
  IconTime30,
  IconTopic18,
  IconTransplantation30,
  IconTransplantation48,
  IconUk,
  IconUndo24,
  IconUpdate24,
  IconUpdates30,
  IconUsa,
  IconUserStory30,
  IconVerbalContact30,
  IconVirtualDrive30,
  IconWarning24,
  IconWebForm18,
  IconWebForm24,
  IconWorkUp30,
  IconWrittenContact30,
  IconZapper18,
  IconZip18,
  IconZloty18,
  IconZoom24,
} from "../icons";

/* eslint-disable sort-keys */
const iconHelper = (iconName) => {
  const icons = {
    icon_register: <IconCooperation48 />,
    icon_stem_cell_collection: <IconStemCellCollection48 />,

    // 18
    icon_accordion_expand_18: <IconAccordionExpand18 />,
    icon_accordion_minimize_18: <IconAccordionMinimize18 />,
    icon_anchor_left_18: <IconAnchorLeft18 />,
    icon_anchor_right_18: <IconAnchorRight18 />,
    icon_article_18: <IconArticle18 />,
    icon_bacteria_18: <IconBacteria18 />,
    icon_big_home_18: <IconBigHome18 />,
    icon_bullet_points_18: <IconBulletPoints18 />,
    icon_calendar_18: <IconCalendar18 />,
    icon_calendar_archive_18: <IconCalendarArchive18 />,
    icon_check_18: <IconCheck18 />,
    icon_checkbox_18: <IconCheckbox18 />,
    icon_checkbox_filled_18: <IconCheckboxFilled18 />,
    icon_clear_input_18: <IconClearInput18 />,
    icon_close_18: <IconClose18 />,
    icon_default_18: <IconDefault18 />,
    icon_delete_18: <IconDelete18 />,
    icon_delete_input_18: <IconDeleteInput18 />,
    icon_directory_18: <IconDirectory18 />,
    icon_dollar_18: <IconDollar18 />,
    icon_donate_18: <IconDonate18 />,
    icon_download_18: <IconDownload18 />,
    icon_dropdown_expand_18: <IconDropdownExpand18 />,
    icon_dropdown_minimize_18: <IconDropdownMinimize18 />,
    icon_error_18: <IconError18 />,
    icon_euro_18: <IconEuro18 />,
    icon_external_link_18: <IconExternalLink18 />,
    icon_fax_18: <IconFax18 />,
    icon_fold_18: <IconFold18 />,
    icon_further_18: <IconFurther18 />,
    icon_help_18: <IconHelp18 />,
    icon_home_18: <IconHome18 />,
    icon_jpg_18: <IconJpg18 />,
    icon_learn_more_18: <IconLearnMore18 />,
    icon_location_18: <IconLocation18 />,
    icon_mail_18: <IconMail18 />,
    icon_menu_18: <IconMenu18 />,
    icon_mp_418: <IconMp418 />,
    icon_path_18: <IconPath18 />,
    icon_path_back_18: <IconPathBack18 />,
    icon_pdf_18: <IconPdf18 />,
    icon_phone_18: <IconPhone18 />,
    icon_pounds_18: <IconPounds18 />,
    icon_radio_button_18: <IconRadioButton18 />,
    icon_radio_button_filled_18: <IconRadioButtonFilled18 />,
    icon_rupie_18: <IconRupie18 />,
    icon_search_18: <IconSearch18 />,
    icon_search_teaser_18: <IconSearchTeaser18 />,
    icon_submit_18: <IconSubmit18 />,
    icon_time_18: <IconTime18 />,
    icon_topic_18: <IconTopic18 />,
    icon_web_form_18: <IconWebForm18 />,
    icon_zip_18: <IconZip18 />,
    icon_zloty_18: <IconZloty18 />,
    icon_news_18: <IconNews18 />,
    icon_careers_18: <IconCareers18 />,

    // 24
    icon_add_24: <IconAdd24 />,
    icon_anchor_right_24: <IconAnchorRight24 />,
    icon_calendar_24: <IconCalendar24 />,
    icon_calendar_archive_24: <IconCalendarArchive24 />,
    icon_check_24: <IconCheck24 />,
    icon_close_24: <IconClose24 />,
    icon_default_24: <IconDefault24 />,
    icon_donate_24: <IconDonate24 />,
    icon_download_24: <IconDownload24 />,
    icon_drag_24: <IconDrag24 />,
    icon_dropdown_expand_24: <IconDropdownExpand24 />,
    icon_expand_24: <IconExpand24 />,
    icon_external_link_24: <IconExternalLink24 />,
    icon_filter_24: <IconFilter24 />,
    icon_filter_applied_24: <IconFilterApplied24 />,
    icon_further_24: <IconFurther24 />,
    icon_help_24: <IconHelp24 />,
    icon_hide_help_24: <IconHideHelp24 />,
    icon_info_24: <IconInfo24 />,
    icon_list_24: <IconList24 />,
    icon_location_24: <IconLocation24 />,
    icon_mail_24: <IconMail24 />,
    icon_map_24: <IconMap24 />,
    icon_message_24: <IconMessage24 />,
    icon_minimize_24: <IconMinimize24 />,
    icon_phone_24: <IconPhone24 />,
    icon_search_24: <IconSearch24 />,
    icon_select_24: <IconSelect24 />,
    icon_select_empty_24: <IconSelectEmpty24 />,
    icon_submit_24: <IconSubmit24 />,
    icon_success_24: <IconSuccess24 />,
    icon_target_group_associations_24: <IconTargetGroupAssociations24 />,
    icon_target_group_club_24: <IconTargetGroupClub24 />,
    icon_target_group_companies_24: <IconTargetGroupCompanies24 />,
    icon_target_group_company_24: <IconTargetGroupCompany24 />,
    icon_target_group_doctors_24: <IconTargetGroupDoctors24 />,
    icon_target_group_educators_24: <IconTargetGroupEducators24 />,
    icon_target_group_parents_24: <IconTargetGroupParents24 />,
    icon_target_group_school_24: <IconTargetGroupSchool24 />,
    icon_target_group_schools_24: <IconTargetGroupSchools24 />,
    icon_target_group_sports_clubs_24: <IconTargetGroupSportsClubs24 />,
    icon_target_group_students_24: <IconTargetGroupStudents24 />,
    icon_target_group_volunteers_24: <IconTargetGroupVolunteers24 />,
    icon_time_24: <IconTime24 />,
    icon_undo_24: <IconUndo24 />,
    icon_update_24: <IconUpdate24 />,
    icon_warning_24: <IconWarning24 />,
    icon_web_form_24: <IconWebForm24 />,
    icon_zoom_24: <IconZoom24 />,
    icon_dkms_24: <img width={24} height={24} src="/images/a-logo-dkms-24.png" alt="dkms logo" />,
    icon_cobi_24: <img width={24} height={24} src="/images/a-logo-cobi-24.png" alt="dkms cobi logo" />,
    icon_dkms_life_24: <img width={24} height={24} src="/images/a-logo-dkmslife-24.png" alt="dkms life logo" />,

    // 30
    icon_add_donor_30: <IconAddDonor30 />,
    icon_blood_sample_30: <IconBloodSample30 />,
    icon_bone_marrow_collection_30: <IconBoneMarrowCollection30 />,
    icon_car_30: <IconCar30 />,
    icon_charity_30: <IconCharity30 />,
    icon_charity_event_30: <IconCharityEvent30 />,
    icon_check_up_30: <IconCheckUp30 />,
    icon_children_30: <IconChildren30 />,
    icon_confirmatory_ping_30: <IconConfirmatoryPing30 />,
    icon_cooperation_30: <IconCooperation30 />,
    icon_default_30: <IconDefault30 />,
    icon_dna_hla_30: <IconDnaHla30 />,
    icon_donate_de_30: <IconDonateDe30 />,
    icon_donate_in_30: <IconDonateIn30 />,
    icon_donate_pl_30: <IconDonatePl30 />,
    icon_donate_uk_30: <IconDonateUk30 />,
    icon_donate_us_cl_30: <IconDonateUsCl30 />,
    icon_donate_cl_30: <IconDonateUsCl30 />,
    icon_donate_us_30: <IconDonateUsCl30 />,
    icon_donor_30: <IconDonor30 />,
    icon_donor_drive_30: <IconDonorDrive30 />,
    icon_donor_patient_meeting_30: <IconDonorPatientMeeting30 />,
    icon_event_30: <IconEvent30 />,
    icon_event_hosting_30: <IconEventHosting30 />,
    icon_family_search_30: <IconFamilySearch30 />,
    icon_faq_30: <IconFaq30 />,
    icon_filing_organisation_30: <IconFilingOrganisation30 />,
    icon_founding_30: <IconFounding30 />,
    icon_global_donors_30: <IconGlobalDonors30 />,
    icon_global_search_30: <IconGlobalSearch30 />,
    icon_happy_donor_30: <IconHappyDonor30 />,
    icon_happy_patient_30: <IconHappyPatient30 />,
    icon_house_30: <IconHouse30 />,
    icon_idm_request_30: <IconIdmRequest30 />,
    icon_important_30: <IconImportant30 />,
    icon_influencer_30: <IconInfluencer30 />,
    icon_local_donors_30: <IconLocalDonors30 />,
    icon_match_30: <IconMatch30 />,
    icon_media_clue_30: <IconMediaClue30 />,
    icon_news_30: <IconNews30 />,
    icon_notification_30: <IconNotification30 />,
    icon_online_registration_30: <IconOnlineRegistration30 />,
    icon_paper_work_30: <IconPaperWork30 />,
    icon_patient_30: <IconPatient30 />,
    icon_preparation_dli_30: <IconPreparationDli30 />,
    icon_radio_30: <IconRadio30 />,
    icon_registration_30: <IconRegistration30 />,
    icon_registration_costs_30: <IconRegistrationCosts30 />,
    icon_registration_via_blood_30: <IconRegistrationViaBlood30 />,
    icon_registry_entry_30: <IconRegistryEntry30 />,
    icon_sad_patient_30: <IconSadPatient30 />,
    icon_sending_back_30: <IconSendingBack30 />,
    icon_stem_cell_collection_30: <IconStemCellCollection30 />,
    icon_stop_30: <IconStop30 />,
    icon_swabbing_30: <IconSwabbing30 />,
    icon_thumbs_up_30: <IconThumbsUp30 />,
    icon_tick_30: <IconTick30 />,
    icon_tick_green_30: <IconTickGreen30 />,
    icon_time_30: <IconTime30 />,
    icon_transplantation_30: <IconTransplantation30 />,
    icon_updates_30: <IconUpdates30 />,
    icon_user_story_30: <IconUserStory30 />,
    icon_verbal_contact_30: <IconVerbalContact30 />,
    icon_virtual_drive_30: <IconVirtualDrive30 />,
    icon_work_up_30: <IconWorkUp30 />,
    icon_written_contact_30: <IconWrittenContact30 />,
    icon_launch_30: <IconLaunch30 />,

    // 48
    icon_cooperation_48: <IconCooperation48 />,
    icon_default_48: <IconDefault48 />,
    icon_dna_hla_48: <IconDnaHla48 />,
    icon_donate_dollar_48: <IconDualToneDonateUsCl48 />,
    icon_donate_euro_48: <IconDualToneDonateDe48 />,
    icon_donate_pounds_48: <IconDualToneDonateUk48 />,
    icon_donate_rupie_48: <IconDualToneDonateIn48 />,
    icon_donate_zloty_48: <IconDualToneDonatePl48 />,
    icon_dual_tone_assets_48: <IconDualToneAssets48 />,
    icon_dual_tone_associations_48: <IconDualToneAssociations48 />,
    icon_dual_tone_charity_event_48: <IconDualToneCharityEvent48 />,
    icon_dual_tone_companies_48: <IconDualToneCompanies48 />,
    icon_dual_tone_doctors_48: <IconDualToneDoctors48 />,
    icon_dual_tone_donate_cl_48: <IconDualToneDonateUsCl48 />,
    icon_dual_tone_donate_de_48: <IconDualToneDonateDe48 />,
    icon_dual_tone_donate_in_48: <IconDualToneDonateIn48 />,
    icon_dual_tone_donate_pl_48: <IconDualToneDonatePl48 />,
    icon_dual_tone_donate_uk_48: <IconDualToneDonateUk48 />,
    icon_dual_tone_donate_us_48: <IconDualToneDonateUsCl48 />,
    icon_dual_tone_donate_us_cl_48: <IconDualToneDonateUsCl48 />,
    icon_dual_tone_donor_drive_48: <IconDualToneDonorDrive48 />,
    icon_dual_tone_educators_48: <IconDualToneEducators48 />,
    icon_dual_tone_event_48: <IconDualToneEvent48 />,
    icon_dual_tone_event_hosting_48: <IconDualToneEventHosting48 />,
    icon_dual_tone_fact_sheet_48: <IconDualToneFactSheet48 />,
    icon_dual_tone_parents_48: <IconDualToneParents48 />,
    icon_dual_tone_register_48: <IconDualToneRegister48 />,
    icon_dual_tone_schools_48: <IconDualToneSchools48 />,
    icon_dual_tone_sports_clubs_48: <IconDualToneSportsClubs48 />,
    icon_dual_tone_students_48: <IconDualToneStudents48 />,
    icon_dual_tone_virtual_drive_48: <IconDualToneVirtualDrive48 />,
    icon_dual_tone_volunteers_48: <IconDualToneVolunteers48 />,
    icon_event_48: <IconEvent48 />,
    icon_find_event_48: <IconDualToneEvent48 />,
    icon_founding_48: <IconFounding48 />,
    icon_gobal_donors_48: <IconGobalDonors48 />,
    icon_happy_patient_48: <IconHappyPatient48 />,
    icon_host_event_48: <IconDualToneEventHosting48 />,
    icon_local_donors_48: <IconLocalDonors48 />,
    icon_match_48: <IconMatch48 />,
    icon_play_48: <IconPlay48 />,
    icon_register_48: <IconDualToneRegister48 />,
    icon_registration_48: <IconRegistration48 />,
    icon_registration_costs_48: <IconRegistrationCosts48 />,
    icon_registry_entry_48: <IconRegistryEntry48 />,
    icon_stem_cell_collection_48: <IconStemCellCollection48 />,
    icon_swabbing_48: <IconSwabbing48 />,
    icon_transplantation_48: <IconTransplantation48 />,
    icon_virtual_drive_48: <IconDualToneInfluencer48 />,

    // flags
    dkms_chile: <IconChile />,
    dkms_germany: <IconGermany />,
    dkms_poland: <IconPoland />,
    dkms_us: <IconUsa />,
    dkms_uk: <IconUk />,
    dkms_bmst_india: <IconIndia />,
    dkms_africa: <IconAfrica />,

    // africa payment provider
    zapper: <IconZapper18 />,
    snapscan: <IconSnapscan18 />,
  };

  if (icons[iconName]) {
    return icons[iconName];
  }

  return null;
};

export default iconHelper;
