import React from "react";

import PropTypes from "prop-types";

import "./glossaryItem.scss";

const GlossaryItem = (props) => {
  const {
    abbreviation,
    description,
    term,
    disableDescription,
  } = props;

  return (
    <div className="glossary-item">
      <div className="glossary-item__header">
        <span className="abbreviation">{abbreviation}</span> <span className="term">{term}</span>
      </div>
      {!disableDescription && (
        <div className="glossary-item__body">
          <div>{description}</div>
        </div>
      )}
    </div>
  );
};

GlossaryItem.propTypes = {
  abbreviation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disableDescription: PropTypes.bool,
  term: PropTypes.string.isRequired,
};

GlossaryItem.defaultProps = {
  disableDescription: false,
};

export default GlossaryItem;
