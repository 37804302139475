/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Store from "./src/state/store";

import "./src/styles/style.scss";

const isEnvProdOrDev = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development";

let lastTitle = "";

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (isEnvProdOrDev) {
    const url = location && location.pathname + location.search + location.hash;
    const prevUrl = prevLocation && prevLocation.pathname + prevLocation.search + prevLocation.hash;
    // document.title workaround stolen from:
    // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
    const sendPageView = () => {
      const { title } = document;

      // eslint-disable-next-line no-underscore-dangle
      const _paq = window && window._paq ? window._paq : [];

      if (lastTitle === title) {
        return;
      }

      // eslint-disable-next-line no-unused-expressions
      prevUrl && _paq.push(["setReferrerUrl", prevUrl]);
      _paq.push(["setCustomUrl", url]);
      _paq.push(["setDocumentTitle", title]);
      _paq.push(["trackPageView"]);
      lastTitle = title;
    };
    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(500, 0);
    setTimeout(sendPageView, delay);
  }

  // eslint-disable-next-line consistent-return
  return null;
};

export const wrapRootElement = ({ element }) => {
  const { store, persistor } = Store();

  if (typeof window === "undefined") {
    return <Provider store={store}>{element}</Provider>;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

export const onServiceWorkerUpdateReady = () => {
  if (typeof document !== "undefined" && document.querySelector(".cache-reload")) {
    document.querySelector(".cache-reload").style.display = "block";
  }
};
