import React from "react";

import { graphql } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import DynamicForm from "../../components/dynamicForm/dynamicForm";
import MainLayout from "../../components/mainLayout";
import OfficeList from "../../components/officeList/officeList";
import SEO from "../../components/seo";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemContactOffice from "../kontentItemContactOffice";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import SimpleRichText from "../text/simpleRichText";

const KontentItemFormPage = ({ data }) => {
  const { page, internalLinks } = data;
  let thankYouPageLink = null;

  if (
    page.elements.thank_you_page.value.length > 0
    && page.elements.thank_you_page.value[0].id
  ) {
    const { id } = page.elements.thank_you_page.value[0];
    const links = internalLinks ? internalLinks.nodes : [];
    thankYouPageLink = _.find(links, (o) => o.navigationItemId === id);
  }

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
        isHiddenForRobots={data.page.elements.meta_robots__noindex.value.length > 0}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        description={<SimpleRichText data={page.elements.lead_text} />}
        media={page.elements.image.value.length > 0 ? (
          <KontentAssetsImage
            data={page.elements.image.value[0]}
            height={150}
          />
        ) : null}
      />

      {page.elements.offices.value && page.elements.offices.value.length > 0 && (
        <OfficeList headline={page.elements.headline_offices.value}>
          {page.elements.offices.value.map((office) => (
            <KontentItemContactOffice data={office} key={office.id} />
          ))}
        </OfficeList>
      )}

      <DynamicForm
        thankYouPageLink={thankYouPageLink}
        buttonText={page.elements.button_text.value}
        fields={page.elements.fields.value}
        kontentItemId={page.system.id}
      />

    </MainLayout>
  );
};

export const query = graphql`
query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    internalLinks: allInternalLinks {
      nodes {
        navigationItemId
        systemId
        title
        slug
      }
    }
    page: kontentItemFormPage(id: {eq: $id}) {
      id
      system {
        id
        codename
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        button_text {
          value
        }
        url_pattern {
          value
        }
        thank_you_page {
          value {
            id
          }
        }
        headline_offices {
          value
        }
        offices {
          value {
            ...KontentItemContactOffice
          }
        }
        to {
          value
        }
        fields {
          value {
            internal {
              type
            }
            ...KontentItemInputTypeText
            ...KontentItemInputTypeSingleInput
            ...KontentItemInputTypeCheckbox
            ...KontentItemInputTypeAddress
            ...KontentItemInputTypeEmail
            ...KontentItemInputTypeDropdown
            ...KontentItemInputTypeState
            ...KontentItemInputTypeCountry
            ...KontentItemInputTypeName
            ...KontentItemInputTypeSalutation
            ...KontentItemSectionHeader
          }
        }
        meta_robots__noindex {
          value {
            codename
          }
        }
      }
    }
  }
`;

KontentItemFormPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default KontentItemFormPage;
