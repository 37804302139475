import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import {
  IconConsentManagement18,
  IconFacebookOriginalColored24,
  IconGoogleMaps24,
  IconInstagramOriginalColored24,
  IconTwitterOriginalColored24,
  IconYoutubeOriginalColored24,
} from "../../icons";

import "./consent.scss";

const DefaultConsent = ({ openCookieConsent, enableExternalContent, type }) => {
  const intl = useIntl();

  return (
    <div
      className={`consent-container d-flex justify-content-center align-items-center ${type}`}
    >
      <div className="consent">
        <div className="detail">
          <div className="mb-12 icons">
            <IconYoutubeOriginalColored24 />
            <IconGoogleMaps24 />
            <IconFacebookOriginalColored24 />
            <IconInstagramOriginalColored24 />
            <IconTwitterOriginalColored24 />
          </div>
          <div className="typo-label">{intl.formatMessage({ id: "consent.details" })}</div>
        </div>
        <div className="action">
          <Button
            onClick={(event) => {
              event.stopPropagation(); enableExternalContent();
            }}
            className="enable-external-content-button shadow-none"
            variant="light"
          >
            {intl.formatMessage({ id: "consent.enable_external_content" })}
          </Button>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation(); openCookieConsent();
            }}
            className="icon-button"
          >
            <IconConsentManagement18 className="mr-6" />
            {intl.formatMessage({ id: "consent.management" })}
          </button>
        </div>
      </div>
    </div>
  );
};

DefaultConsent.propTypes = {
  enableExternalContent: PropTypes.func.isRequired,
  openCookieConsent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DefaultConsent;
