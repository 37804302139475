import React from "react";

import { graphql, StaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import _ from "lodash";
import PropTypes from "prop-types";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import OverviewText from "../../components/overviewText/overviewText";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemEyecatcherHighlight from "../kontentItemEyecatcherHighlight";
import KontentItemServicesAndProcessesSection from "../kontentItemServicesAndProcessesSection";

const StaticQueryKontentItemHomeLab = ({ data }) => {
  const intl = useIntl();

  const page = _.find(data.allKontentItemHomeLab.nodes, ["preferred_language", intl.locale]);

  return (
    <MainLayout>

      <SEO
        title={intl.formatMessage({ id: "title_lab" })}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <OverviewText headline={page.elements.headline.value}>
        {page.elements.lead_text.value}
      </OverviewText>

      <KontentItemEyecatcherHighlight data={page.elements.top_highlight} />

      <KontentItemServicesAndProcessesSection
        data={page.elements.services_and_processes_highlights}
      />

      {page.elements.page_sections.value.map((item) => mapKontentItem(item, { isLab: true }))}

    </MainLayout>
  );
};

const KontentItemHomeLab = (props) => (
  <StaticQuery
    query={graphql`
      {
        allKontentItemHomeLab {
          nodes {
            elements {
              metadata__meta_description {
                value
              }
              metadata__twitter_title {
                value
              }
              metadata__twitter_site {
                value
              }
              metadata__twitter_image {
                value {
                  url
                }
              }
              metadata__twitter_description {
                value
              }
              metadata__twitter_creator {
                value
              }
              metadata__og_title {
                value
              }
              metadata__og_image {
                value {
                  url
                }
              }
              metadata__og_description {
                value
              }
              metadata__meta_title {
                value
              }
              hero_headline {
                value
              }
              headline {
                value
              }
              lead_text {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
              top_highlight {
                value {
                  ...KontentItemEyecatcherHighlight
                }
              }
              services_and_processes_highlights {
                value {
                  ...KontentItemServicesAndProcessesSection
                }
              }
              page_sections {
                value {
                  id
                  system {
                    codename
                  }
                  internal {
                    type
                  }
                  ...KontentItemDownloadsSection
                  ...KontentItemNetworkSlider
                  ...KontentItemContactPersonsSection
                  ...KontentItemServicesSection
                  ...KontentItemRepresentativesSection
                  ...KontentItemDownloadsSection
                  ...KontentItemEyecatcherIconSection
                }
              }
            }
            preferred_language
          }
        }
      }
    `}
    render={(data) => <StaticQueryKontentItemHomeLab data={data} {...props} />}
  />
);

StaticQueryKontentItemHomeLab.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHomeLab;
