import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

const FormSectionHeader = ({ headline, description }) => {
  const intl = useIntl();

  return (
    <div className="my-5">
      {headline && (
        <h3 className="section-header">{intl.formatMessage({ id: headline })}</h3>
      )}
      {description && (
        <p className="text-black">{intl.formatMessage({ id: description })}</p>
      )}
    </div>
  );
};

FormSectionHeader.propTypes = {
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default FormSectionHeader;
