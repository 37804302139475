import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconFurther24 } from "../../icons";
import LinkButton from "../button/linkButton";

import "./departmentTileGrid.scss";

const DepartmentTileGrid = ({ children, isLarge, moreUrl }) => {
  const items = React.Children.toArray(children);

  if (items.length === 0) {
    return null;
  }

  let colProps = {
    md: 6,
    xs: 12,
  };

  if (isLarge) {
    colProps = {
      lg: 4,
      md: 6,
      xs: 12,
    };
  }

  return (
    <Row className="department-tile-grid">
      {items.map((item) => (
        <Col {...colProps} key={item.key}>
          {item}
        </Col>
      ))}

      {moreUrl && (
        <Col xs={12} className="department-tile-grid__view-all-link">
          <LinkButton href={moreUrl}>
            <FormattedMessage id="department_section.view_all" />
            <IconFurther24 />
          </LinkButton>
        </Col>
      )}
    </Row>
  );
};

DepartmentTileGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLarge: PropTypes.bool,
  moreUrl: PropTypes.string,
};

DepartmentTileGrid.defaultProps = {
  children: null,
  isLarge: false,
  moreUrl: null,
};

export default DepartmentTileGrid;
