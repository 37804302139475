import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
} from "../../icons";

import "./stateAwareAccordionToggle.scss";

const StateAwareAccordionToggle = (props) => {
  const {
    children,
    className,
    eventKey,
    callback,
    iconMinimize,
    iconMaximize,
  } = props;
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey, () => {
    callback(eventKey);
  });
  const active = currentEventKey === eventKey;

  return (
    <div
      className={classNames("state-aware-accordion-toggle", { "state-aware-accordion-toggle--active": active }, className)}
      onClick={toggleOnClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          toggleOnClick();
          event.stopPropagation();
        }
      }}
      role="button"
      tabIndex="0"
    >
      <div>{children}</div>
      <div className="state-aware-accordion-toggle__icon">{ active ? iconMinimize : iconMaximize }</div>
    </div>
  );
};

StateAwareAccordionToggle.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  iconMaximize: PropTypes.oneOfType([PropTypes.object]),
  iconMinimize: PropTypes.oneOfType([PropTypes.object]),
};

StateAwareAccordionToggle.defaultProps = {
  callback: () => {},
  className: "",
  iconMaximize: <IconAccordionExpand18 />,
  iconMinimize: <IconAccordionMinimize18 />,
};

export default StateAwareAccordionToggle;
