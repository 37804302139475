import React from "react";

import PropTypes from "prop-types";

import LocationGrid from "../location/locationGrid";
import TextSection from "../textSection/textSection";

import "./donorCenterLocationsSection.scss";

const DonorCenterLocationsSection = (props) => {
  const {
    children,
    headline,
    image,
    text,
  } = props;

  return (
    <div className="donor-center-locations-section">
      <TextSection
        headline={headline}
        text={text}
      />
      <div className="donor-center-locations-section__image">
        {image}
      </div>
      <LocationGrid>
        {children}
      </LocationGrid>
    </div>
  );
};

DonorCenterLocationsSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  headline: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  text: PropTypes.string,
};

DonorCenterLocationsSection.defaultProps = {
  headline: null,
  image: null,
  text: null,
};

export default DonorCenterLocationsSection;
