import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Swiper from "react-id-swiper";

import scrollActiveNavItem from "../../utils/scrollActiveNavItem";
import BrowserDetection from "../browserDetection";
import NavigationLink from "../navigation/navigationLink";

import "./subNavigation.scss";

const SubNavigation = ({ items, variant }) => {
  let scrollMenu = null;
  const [scrollMenuClasses, setScrollMenuClasses] = useState(null);
  const [isScrollable, setIsScrollable] = useState(null);

  const isMobile = BrowserDetection.hasTouchScreen();
  const isVariant = variant !== null;

  const params = {
    mousewheel: { forceToAxis: true, invert: true },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    noSwiping: true,
    shouldSwiperUpdate: true,
    slidesPerView: "auto",
    spaceBetween: 24,
  };

  const checkScrollable = () => {
    if (!scrollMenu) return null;

    const target = isMobile
      ? scrollMenu.firstElementChild
      : scrollMenu.firstElementChild.firstElementChild;

    let classes = "";

    if (target.scrollWidth > target.clientWidth) classes = "is-scrollable";

    setIsScrollable(classes);

    return null;
  };

  const scrollTrigger = () => {
    if (!scrollMenu || !isMobile) return null;

    const target = scrollMenu.firstElementChild;

    const classes = [];

    if (target.scrollLeft > 20) {
      classes.push("menu-scrolling--left");
    }

    if (
      target.scrollWidth > target.clientWidth
      && target.scrollLeft + target.clientWidth !== target.scrollWidth
    ) {
      classes.push("menu-scrolling--right");
    }

    const classesString = classes.join(" ");

    if (scrollMenuClasses !== classesString) {
      setScrollMenuClasses(classesString);
    }

    return null;
  };

  useEffect(() => {
    if (variant !== "tabnavigation") scrollTrigger();
    checkScrollable();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => checkScrollable());
    }
  });

  if (isMobile) {
    useEffect(() => {
      if (scrollMenu) {
        const nav = scrollMenu.firstChild;

        nav.scrollLeft = scrollActiveNavItem(nav);

        scrollTrigger();
      }
    }, []);

    return (
      <div className={classNames(
        "subnavigation__container container--fullwidth",
        { [`subnavigation__container--${variant}`]: isVariant },
      )}
      >
        <Container fluid>
          <Navbar
            className={classNames(
              "subnavigation",
              scrollMenuClasses,
              isScrollable,
              {
                "subnavigation--is-mobile": isMobile,
                [`subnavigation--${variant}`]: isVariant,
              },
            )}
            ref={(node) => {
              scrollMenu = node;
            }}
            onScroll={variant !== "tabnavigation"
              ? (() => {
                scrollTrigger();
              })
              : null}
          >
            <Nav>
              <div className="navbar-nav__inner">
                {
                  items.map((item) => (
                    <NavigationLink
                      {...item}
                      href={item.link}
                      key={item.id}
                      className="nav-link"
                    >
                      {item.title}
                    </NavigationLink>
                  ))
                }
              </div>
            </Nav>
          </Navbar>
        </Container>
      </div>
    );
  }

  return (
    <Navbar
      expand={null}
      className={classNames(
        "subnavigation subnavigation__container container--fullwidth",
        scrollMenuClasses,
        isScrollable,
      )}
    >
      <Container fluid>
        <Nav
          ref={(node) => {
            scrollMenu = node;
          }}
        >
          <Swiper {...params}>
            {
              items.map((item) => (
                <NavigationLink
                  href={item.link}
                  key={item.id}
                  className="nav-link"
                >
                  {item.title}
                </NavigationLink>
              ))
            }
          </Swiper>
        </Nav>
      </Container>
    </Navbar>
  );
};

SubNavigation.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  variant: PropTypes.oneOf(["tabnavigation"]),
};

SubNavigation.defaultProps = {
  variant: null,
};

export default SubNavigation;
