import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemSubsidiariesCard from "./kontentItemSubsidiariesCard";
import NetworkSection from "../components/networkSection/networkSection";

const KontentItemNetworkSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <NetworkSection
      headline={section.elements.headline.value}
      text={section.elements.text.value}
    >
      {KontentItemSubsidiariesCard({ data: section.elements.network.value })}
    </NetworkSection>
  );
};

export const query = graphql`
  fragment KontentItemNetworkSection on kontent_item_network_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      network {
        value {
          ...KontentItemSubsidiariesCard
        }
      }
    }
  }
`;

KontentItemNetworkSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNetworkSection;
