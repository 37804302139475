import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import Divider from "../../components/divider/divider";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeader from "../../components/textHeader/textHeader";
import { IconExternalLink24 } from "../../icons";
import mapContentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import InfoBarSnippet from "../utils/infoBarSnippet";

const KontentItemSubsidiary = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.logo.value.length > 0
          ? (
            <KontentAssetsImage
              className="img-fluid"
              data={page.elements.logo.value[0]}
              height={
                page.elements.logo.value[0].height * 3 > page.elements.logo.value[0].width
                  ? 68
                  : 30
              }
            />
          )
          : page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <TextHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        button={
          page.elements.button_url_extern.value !== ""
            ? (
              <IconButton
                href={page.elements.button_url_extern.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {page.elements.button_text.value}<IconExternalLink24 />
              </IconButton>
            )
            : null
        }
      />

      <SidebarLayout
        isRight
        sidebar={(
          <InfoBarSnippet
            infoBarHeadline={page.elements.info_bar__info_bar_headline.value}
            quicklinksTitle={page.elements.info_bar__quicklinks_title.value}
            quicklinkTextSource={page.elements.body.value}
            linksTitle={page.elements.info_bar__links_title.value}
            externalUrl={page.elements.info_bar__external_url}
            contactDataTitle={page.elements.info_bar__contact_data_title.value}
            contactData={page.elements.info_bar__contact_data}
            contactTitle={page.elements.info_bar__contact_title.value}
            contactMail={page.elements.info_bar__contact_mail}
            contactPhone={page.elements.info_bar__contact_phone}
            downloadsTitle={page.elements.info_bar__downloads_title.value}
            download={page.elements.info_bar__download.value}
            glossaryTitle={page.elements.info_bar__glossary_title.value}
            glossaryItems={page.elements.info_bar__glossary_items.value}
          />
        )}
      >
        <RichText data={page.elements.body} isSidebarLayout />

        <div className="d-xl-none">
          <Divider />
        </div>

        {page.elements.page_sections.value.map((item) => mapContentItem(
          item, { isPageSection: true },
        ))}

      </SidebarLayout>
    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemSubsidiary(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        info_bar__info_bar_headline {
          value
        }
        info_bar__quicklinks_title {
          value
        }
        info_bar__links_title {
          value
        }
        info_bar__external_url {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_data_title {
          value
        }
        info_bar__contact_data {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_title {
          value
        }
        info_bar__contact_mail {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_phone {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__downloads_title {
          value
        }
        info_bar__download {
          value {
            ...KontentItemAsset
          }
        }
        info_bar__glossary_title {
          value
        }
        info_bar__glossary_items {
          value {
            ...KontentItemGlossaryItem
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_headline {
          value
        }
        logo {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        button_text {
          value
        }
        button_url_extern {
           value
        }
        page_sections {
          value {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemContactPersonsSection
            ...KontentItemServicesSection
            ...KontentItemRepresentativesSection
            ...KontentItemDownloadsSection
            ...KontentItemEyecatcherIconSection
            ...KontentItemFaqSection
          }
        } 
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemSubsidiary.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSubsidiary;
