import React, { useEffect, useState } from "react";

import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import GlossaryCategory from "../../components/glossaryCategory/glossaryCategory";
import GlossaryItem from "../../components/glossaryItem/glossaryItem";

const GlossaryOverviewWrapper = ({ items, filter }) => {
  const reduceToDictionary = (acc, glossaryItem) => {
    const firstLetter = glossaryItem.abbreviation.value.toUpperCase().charAt(0);

    if (!acc[firstLetter]) {
      acc[firstLetter] = [glossaryItem];
    } else {
      acc[firstLetter].push(glossaryItem);
      acc[firstLetter] = _.orderBy(
        acc[firstLetter],
        (item) => item.abbreviation.value.toUpperCase(),
      );
    }

    return acc;
  };

  const glossaryDictionary = items.map(
    (glossaryItem) => glossaryItem.elements,
  ).reduce(
    reduceToDictionary, {},
  );

  const sortedGlossary = Object.fromEntries(Object.entries(glossaryDictionary).sort());

  const [initialGlossary] = useState(sortedGlossary);
  const [glossary, setGlossary] = useState(sortedGlossary);

  useEffect(() => {
    if (!filter) {
      setGlossary(initialGlossary);

      return;
    }
    const filterReducer = (acc, key) => {
      const lowercasedFilter = filter.toLowerCase();
      const includesFilter = (el) => (
        el.abbreviation.value.toLowerCase().includes(lowercasedFilter)
        || el.description.value.toLowerCase().includes(lowercasedFilter)
        || el.term.value.toLowerCase().includes(lowercasedFilter)
      );

      if (initialGlossary[key].some(includesFilter)) {
        acc[key] = initialGlossary[key].filter(includesFilter);
      }

      return acc;
    };

    const filteredGlossary = Object.keys(initialGlossary).reduce(
      filterReducer,
      {},
    );

    setGlossary(filteredGlossary);
  }, [filter]);

  return (
    <>
      {Object.keys(glossary).map((key, idx, arr) => (
        <div key={`glossary-${key}`} id={`anchor-point-${key}`}>
          <GlossaryCategory
            category={key.toUpperCase()}
          >
            {glossary[key].map((glossaryItem) => (
              <GlossaryItem
                key={glossaryItem.abbreviation.value}
                abbreviation={glossaryItem.abbreviation.value}
                description={glossaryItem.description.value}
                term={glossaryItem.term.value}
              />
            ))}
          </GlossaryCategory>
          <div className={classNames("", {
            "mb-0": idx === arr.length - 1,
            "mb-5": idx !== arr.length - 1,
          })}
          >
            <Divider />
          </div>
        </div>
      ))}
    </>
  );
};

GlossaryOverviewWrapper.propTypes = {
  filter: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

GlossaryOverviewWrapper.defaultProps = {
  filter: null,
};

export default GlossaryOverviewWrapper;
