import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther24 } from "../../icons";
import BackgroundImage from "../backgroundImage/backgroundImage";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigation/navigationLink";

import "./programTile.scss";

const ProgramTile = ({ href, backgroundImageStyle, label }) => (
  <NavigationLink href={href} className="no-style">
    <div className="program-tile">
      {backgroundImageStyle && (
        <BackgroundImage
          className="program-tile__image"
          backgroundImageStyle={backgroundImageStyle}
        />
      )}
      <div className="program-tile__subline">
        <div className="program-tile__subline-label">{label}</div>
        <LinkButton className="text-primary">
          <FormattedMessage id="programtile.learn_more" />
          <IconFurther24 />
        </LinkButton>
      </div>
    </div>
  </NavigationLink>
);

ProgramTile.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]),
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ProgramTile.defaultProps = {
  backgroundImageStyle: null,
};

export default ProgramTile;
