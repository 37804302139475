import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./quote.scss";

const Quote = ({ quote, color }) => (
  <div className={classNames("quote", "text-section__fullwidth", `quote--${color}`)}>
    <h3>{quote}</h3>
  </div>
);

Quote.propTypes = {
  color: PropTypes.oneOf(["red", "yellow", "purple", "blue"]).isRequired,
  quote: PropTypes.string.isRequired,
};

export default Quote;
