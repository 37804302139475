import React, { useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";

import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import IconButton from "../button/iconButton";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./faqAccordion.scss";

const FaqAccordion = (props) => {
  const {
    accordionItems,
    boldTitle,
    expandable,
    variant,
  } = props;

  const [showAll, setShowAll] = useState(false);

  return (
    <Accordion className={classNames("faq-accordion", { [`faq-accordion--${variant}`]: variant })}>
      {accordionItems.map((item, index) => (
        <div
          key={item.id}
          className={classNames("faq-accordion__accordion-item", expandable && !showAll && index > 5 && "d-none")}
        >
          <StateAwareAccordionToggle
            eventKey={item.id}
          >
            {
              boldTitle
                ? <div className="typo-h4">{item.question}</div>
                : <div>{item.question}</div>
            }
          </StateAwareAccordionToggle>
          <Accordion.Collapse eventKey={item.id}>
            <>
              {item.answer}
            </>
          </Accordion.Collapse>
        </div>
      ))}
      {expandable && accordionItems.length > 6 && (
        <div className={classNames("faq-accordion__button", { "d-none": accordionItems.length <= 6 })}>
          <IconButton
            size="sm"
            variant={(variant !== null) ? "light-primary" : "light-faq"}
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll
              ? <><FormattedMessage id="faq.accordion.show_less_results" /> <IconAccordionMinimize18 /></>
              : <><FormattedMessage id="faq.accordion.show_more_results" /> <IconAccordionExpand18 /></>}
          </IconButton>
        </div>
      )}
    </Accordion>
  );
};

FaqAccordion.propTypes = {
  accordionItems: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })).isRequired,
  boldTitle: PropTypes.bool,
  expandable: PropTypes.bool,
  variant: PropTypes.oneOf(["virtual-drive-help"]),
};

FaqAccordion.defaultProps = {
  boldTitle: false,
  expandable: false,
  variant: null,
};

export default FaqAccordion;
