import React from "react";

import PropTypes from "prop-types";

import "./textHeaderSidebar.scss";

const TextHeaderSidebar = (props) => {
  const {
    icon,
    title,
    text,
    image,
  } = props;

  return (
    <div className="text-header-sidebar">
      {icon && (
        <div className="text-header-sidebar__icon">
          {icon}
        </div>
      )}
      <h1 className="text-header-sidebar__title">
        {title}
      </h1>
      {text && (
        <div className="text-header-sidebar__text">
          {text}
        </div>
      )}
      {image && (
        <div className="text-header-sidebar__image">
          {image}
        </div>
      )}
    </div>
  );
};

TextHeaderSidebar.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]),
  image: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TextHeaderSidebar.defaultProps = {
  icon: null,
  image: null,
  text: null,
};

export default TextHeaderSidebar;
