import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./textHeader.scss";

const TextHeader = (props) => {
  const {
    icon,
    headline,
    description,
    button,
    small,
  } = props;

  return (
    <Row className={classNames("text-header", { "text-header--small": small })}>
      {icon && (
        <Col
          xs={12}
          xl={small ? 12 : 4}
          className={classNames("text-header__icon", { "text-xl-right order-xl-last": !small })}
        >
          {icon}
        </Col>
      )}
      <Col xs={12} md={8}>
        <div className="text-header__headline">{headline}</div>
        {description && (<div className="text-header__description">{description}</div>)}
        {button && (
          <div className="text-header__button">{button}</div>
        )}
      </Col>
    </Row>
  );
};

TextHeader.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
  small: PropTypes.bool,
};

TextHeader.defaultProps = {
  button: null,
  description: null,
  icon: null,
  small: false,
};

export default TextHeader;
