import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./articleModule.scss";

const ArticleModule = ({ caption, children, variant }) => {
  if ((children.length) > 1) {
    return (
      <Row className="article-module justify-content-center">
        {React.Children.map(children, (child) => (
          <Col sm={6} className="article-module__image-pair">
            {child}
          </Col>
        ))}
        {caption && (
          <Col lg={8} className="typo-small-label text-center">
            {caption}
          </Col>
        )}
      </Row>
    );
  }

  return (
    <Row
      className={classNames("article-module justify-content-center", variant ? `article-module--${variant}` : "row-xs-only--fullwidth")}
    >
      {variant === "truetosize"
        ? (
          <Col
            md={10}
            lg={8}
            className="article-module__image-container"
          >
            {children}
          </Col>
        )
        : (
          <Col
            xs={12}
            className="article-module__image-container embed-responsive embed-responsive-21by9"
          >
            <div className="embed-responsive-item">
              {children}
            </div>
          </Col>
        )}
      {caption && (
        <Col lg={8} className="typo-small-label text-center">
          {caption}
        </Col>
      )}
    </Row>
  );
};

ArticleModule.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["truetosize"]),
};

ArticleModule.defaultProps = {
  caption: null,
  variant: null,
};

export default ArticleModule;
