import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import DropdownFormField from "../../components/localForms/dropdownFormField/dropdownFormField";
import FormField from "../../components/localForms/formField/formField";
import Tooltip from "../../components/localForms/tooltip/tooltip";

const KontentItemInputTypeCountry = (props) => {
  const {
    data,
    errors,
    touched,
    values,
    meta,
    onBlur,
    onChange,
  } = props;

  const { elements } = data;
  // Setting up options array to contain an "other" option if required
  const options = elements.options_country.value.split("\n");

  return (
    <>
      <Col xs={12} md={6} className="mb-3">
        <Tooltip
          id={elements.id_country.value}
          tooltipMessage={elements.tooltip_country.value}
        >
          <DropdownFormField
            id={elements.id_country.value}
            type="select"
            errors={errors}
            touched={touched}
            values={values}
            meta={meta}
            label={elements.label_country.value}
            required={elements.required.value.includes("id_country")}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Tooltip>
      </Col>
      <Col xs={12} md={6} className="mb-3">
        <Tooltip
          id={elements.id_state.value}
          tooltipMessage={elements.tooltip_state.value}
        >
          <FormField
            id={elements.id_state.value}
            type="text"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_state.value}
            required={elements.required.value.includes("id_state")}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Tooltip>
      </Col>
    </>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeCountry on kontent_item_input_type_country {
    id
    elements {
      id_country {
        value
      }
      id_state {
        value
      }
      label_country {                    
        value
      }
      label_state {
        value
      }
      options_country {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip_country {
        value
      }
      tooltip_state {
        value
      }
    }
  } 
`;

KontentItemInputTypeCountry.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeCountry;
