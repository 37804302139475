import * as Yup from "yup";

import {
  topicOptions,
} from "./generalFormData";
import { cleanupObject } from "../../../utils/formik/objectUtils";
import Validators from "../validations/validators";
import { getOnPreventKeypress, RegexPatterns } from "../validators";

const getForm = (intl) => {
  const validators = new Validators(intl);
  const TOPIC_OPTIONS = topicOptions(intl);

  return [
    {
      props: {
        description: "",
        headline: "contact_header_title",
        id: "contact_header",
        type: "header",
      },
      responsive: {
        md: "12",
        xs: "12",
      },
      tagName: "FormSectionHeader",
      type: "header",
    },
    {
      initialValue: "",
      options: TOPIC_OPTIONS,
      props: {
        id: "topic",
        label: "select_topic",
        name: "topic",
        required: true,
        type: "text",
      },
      responsive: {
        md: "6",
        singleLine: true,
        xs: "12",
      },
      tagName: "SelectInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          ...validators.get("", true),
        ],
      },
    },
    {
      initialValue: "",
      props: {
        id: "firstname",
        label: "firstname",
        maxLength: 40,
        name: "firstname",
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        required: true,
        type: "text",
      },
      responsive: {
        md: "6",
        xs: "12",
      },
      tagName: "TextInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
    },
    {
      initialValue: "",
      props: {
        id: "lastname",
        label: "lastname",
        maxLength: 100,
        name: "lastname",
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        required: true,
        type: "text",
      },
      responsive: {
        md: "6",
        xs: "12",
      },
      tagName: "TextInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
    },
    {
      initialValue: "",
      props: {
        id: "email",
        label: "personal_email",
        name: "email",
        required: true,
        type: "text",
      },
      responsive: {
        md: "6",
        xs: "12",
      },
      tagName: "TextInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
    },
    {
      initialValue: "",
      props: {
        id: "confirmEmail",
        label: "confirm_personal_email",
        name: "confirmEmail",
        required: false,
        type: "text",
      },
      responsive: {
        md: "6",
        xs: "12",
      },
      tagName: "TextInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          {
            params: [[Yup.ref("email"), null], validators.getTranslation("form.error.emails_dont_match")],
            type: "oneOf",
          },
          ...validators.get("email", true),
        ],
      },
    },

    {
      initialValue: "",
      props: {
        id: "comments",
        label: "additional_comments",
        maxLength: 10000,
        name: "comments",
        required: true,
        type: "text",
      },
      responsive: {
        md: "12",
        xs: "12",
      },
      tagName: "TextAreaInput",
      type: "input",
      validation: {
        type: "string",
        validators: [
          ...validators.get("", false),
        ],
      },
    },
    {
      props: {
        description: "dataprotection.text",
        headline: "",
        id: "data_protection_header",
        type: "header",
      },
      responsive: {
        md: "12",
        xs: "12",
      },
      tagName: "FormSectionHeader",
      type: "header",
    },
    {
      initialValue: false,
      props: {
        id: "privacy",
        label: "",
        lg: true,
        name: "privacy",
        required: true,
        type: "checkbox",
      },
      responsive: {
        xs: "12",
      },
      tagName: "CheckboxInput",
      type: "input",
      validation: {
        type: "bool",
        validators: [
          ...validators.get("privacy", true),
        ],
      },
    },
  ];
};

const generatePayload = (values) => {
  const payload = {
    person: {
      firstname: values.firstname,
      lastname: values.lastname,
    },
    private_contact: {
      email: values.email,
    },
  };

  return cleanupObject(payload);
};

export default { generatePayload, getForm };
