import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther18 } from "../../icons";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigation/navigationLink";

import "./subsidiaryTile.scss";

const SubsidiaryTile = (props) => {
  const {
    href,
    headerLogo,
    headerBackgroundImage,
    title,
    text,
    buttonText,
  } = props;

  return (
    <NavigationLink
      href={href}
      className={classNames(
        "subsidiary-tile",
        "no-style",
      )}
    >
      <div className="subsidiary-tile__header">
        {headerBackgroundImage && (
          <div className="subsidiary-tile__header-background" style={headerBackgroundImage} />
        )}
        {headerLogo && (
          <div className="subsidiary-tile__header-logo">{headerLogo}</div>
        )}
      </div>
      <div className="subsidiary-tile__body">
        <div>
          {title && (
            <h4 className="subsidiary-tile__title">{title}</h4>
          )}
          {text && (
            <p className="subsidiary-tile__text">{text}</p>
          )}
        </div>
        <LinkButton>
          {buttonText || <FormattedMessage id="tile.learn_more" />} <IconFurther18 />
        </LinkButton>
      </div>
    </NavigationLink>
  );
};

SubsidiaryTile.propTypes = {
  buttonText: PropTypes.string,
  headerBackgroundImage: PropTypes.oneOfType([PropTypes.object]),
  headerLogo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SubsidiaryTile.defaultProps = {
  buttonText: null,
  headerBackgroundImage: null,
  text: null,
};

export default SubsidiaryTile;
