import React, { Component } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
} from "../../icons";

import "./hubsection.scss";

class Hubsection extends Component {
  numberOfTiles = 7;

  numberOfMoreTiles = 8;

  constructor(props) {
    super(props);

    const {
      children,
    } = this.props;

    if (typeof window !== "undefined" && window.innerWidth >= 992) {
      this.numberOfTiles = 8;
      this.numberOfMoreTiles = 9;
    }

    this.state = {
      numberOfShowTiles: children.length < this.numberOfTiles
        ? children.length
        : this.numberOfTiles,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resize);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.resize);
    }
  }

  resize = () => {
    const { children } = this.props;

    if (typeof window !== "undefined" && window.innerWidth >= 992) {
      this.numberOfTiles = 8;
      this.numberOfMoreTiles = 9;
    } else {
      this.numberOfTiles = 7;
      this.numberOfMoreTiles = 8;
    }
    this.setState({
      numberOfShowTiles: children.length < this.numberOfTiles
        ? children.length : this.numberOfTiles,
    });
  };

  render() {
    const {
      children,
      twoCols,
    } = this.props;

    const { numberOfShowTiles } = this.state;

    return (
      <div className={classNames("hubsection", { "hubsection--two-cols": twoCols })}>
        <Row>
          {
            React.Children.map(children, (child, index) => {
              let colProps = {
                className: null,
                sm: 6,
              };

              if (
                index === numberOfShowTiles - 1
                && (
                  (twoCols && numberOfShowTiles % 2 === 1)
                  || (!twoCols && numberOfShowTiles % 2 === 0)
                )
              ) {
                colProps = {
                  className: "last",
                  sm: 12,
                };
              }

              if (!twoCols) {
                colProps.lg = 4;

                if (index === 0) {
                  colProps.sm = 12;
                  colProps.lg = 8;
                  colProps.className = "first";
                }
              }

              return (
                index < numberOfShowTiles
                && (
                  <Col {...colProps}>
                    {child}
                  </Col>
                )
              );
            })
          }
        </Row>
        {children.length > this.numberOfTiles && (
          !(children.length <= this.numberOfTiles)
          && (numberOfShowTiles < children.length
            ? (
              <Button
                onClick={() => {
                  const addMoreElements = numberOfShowTiles + this.numberOfMoreTiles;
                  const changeNumberOfShowElements = (addMoreElements + 1) > children.length
                    ? children.length
                    : addMoreElements;
                  this.setState({
                    numberOfShowTiles: changeNumberOfShowElements,
                  });
                }}
                variant="outline-registration"
                className="hubsection__pagination"
              >
                <FormattedMessage id="hubsection.show_more" /> <IconAccordionExpand18 />
              </Button>
            )
            : (
              <Button
                onClick={() => {
                  this.setState({
                    numberOfShowTiles: typeof window !== "undefined" && window.innerWidth >= 992 ? 8 : 7,
                  });
                }}
                variant="outline-registration"
                className="hubsection__pagination"
              >
                <FormattedMessage id="hubsection.show_less" /> <IconAccordionMinimize18 />
              </Button>
            ))
        )}
      </div>
    );
  }
}

Hubsection.propTypes = {
  children: PropTypes.node.isRequired,
  twoCols: PropTypes.bool,
};

Hubsection.defaultProps = {
  twoCols: false,
};

export default Hubsection;
