class BrowserDetection {
  static hasTouchScreen() {
    let hasTouchScreen = false;

    if (typeof window !== "undefined") {
      const { navigator } = window;

      if (typeof navigator !== "undefined") {
        if ("maxTouchPoints" in navigator) {
          hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
          hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
          const mQ = window.matchMedia && window.matchMedia("(pointer:coarse)");

          if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
          } else if ("orientation" in window) {
            hasTouchScreen = true; // deprecated, but good fallback
          } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            hasTouchScreen = (
              /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA)
              || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
          }
        }
      }
    }

    return hasTouchScreen;
  }

  static isIE() {
    if (typeof document !== "undefined") {
      return /* @cc_on!@ */ false || !!document.documentMode;
    }

    return false;
  }
}

export default BrowserDetection;
