import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleHeadline from "../../components/articleHeadline/articleHeadline";
import IconButton from "../../components/button/iconButton";
import LinkButton from "../../components/button/linkButton";
import InfoBar from "../../components/infoBar/infoBar";
import InfoSection from "../../components/infoBar/infoSection";
import MainLayout from "../../components/mainLayout";
import PosterImage from "../../components/posterImage/posterImage";
import RelatedStudy from "../../components/relatedStudy/relatedStudy";
import RelatedStudyGrid from "../../components/relatedStudyGrid/relatedStudyGrid";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeader from "../../components/textHeader/textHeader";
import {
  IconAuthor18,
  IconDownload18,
  IconExternalLink18,
  IconFurther18,
  IconTime18,
  IconTopic18,
} from "../../icons";
import { format } from "../../utils/dateHelper";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemJournal from "../kontentItemJournal";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemPoster = ({ data }) => {
  const { page } = data;

  let downloadItem = null;

  if (page.elements.download.value.length > 0
    && page.elements.download.value[0].elements.asset_item.value.length > 0) {
    downloadItem = {
      name: page.elements.download.value[0].elements.asset_item.value[0].name,
      url: page.elements.download.value[0].elements.asset_item.value[0].url,
    };
  }

  return (
    <MainLayout>
      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <TextHeader
        headline={page.elements.title.value}
        button={downloadItem && downloadItem.url
          ? (
            <>
              <IconButton
                target="_blank"
                href={downloadItem.url}
                rel="noopener noreferrer"
              >
                <FormattedMessage id="download_poster" /> <IconDownload18 />
              </IconButton>
              <br />
            </>
          )
          : null}
        small
      />

      <SidebarLayout
        isRight
        sidebar={(
          <InfoBar headline={<FormattedMessage id="publication.details" />}>
            {page.elements.authors.value?.length > 0 && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.authors" />}
                sectionIcon={<IconAuthor18 />}
              >
                {page.elements.authors.value}
              </InfoSection>
            )}

            {page.elements.publishing_date.value?.length > 0 && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.publishing_date" />}
                sectionIcon={<IconTime18 />}
              >
                {format(new Date(page.elements.publishing_date.value))}
              </InfoSection>
            )}

            {page.elements.category__publications_poster.value?.length > 0 && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.category" />}
                sectionIcon={<IconTopic18 />}
              >
                {page.elements.category__publications_poster.value.map((item) => item.name.trim()).join(", ")}
              </InfoSection>
            )}
          </InfoBar>
        )}
      >

        {page.elements.image.value.length > 0 && downloadItem
          ? (
            <PosterImage
              downloadLink={downloadItem.url}
              name={downloadItem.name}
              image={(
                <KontentAssetsImage
                  data={page.elements.image.value[0]}
                  width={830}
                />
              )}
            />
          )
          : null}

        <RichText data={page.elements.description} />

        {page.elements.related_publication?.value.length > 0 && (
          <ArticleHeadline headline={<FormattedMessage id="study.related_publications" />}>
            <RelatedStudyGrid useInSidebarLayout>
              {page.elements.related_publication.value.map((item) => (
                <RelatedStudy
                  key={item.id}
                  headline={(
                    item.elements.journal_name.value.length > 0
                      ? (
                        <KontentItemJournal
                          data={item.elements.journal_name.value[0]}
                          issueNumber={
                            item.elements.journal_issue_number.value.length > 0
                              ? item.elements.journal_issue_number.value
                              : null
                          }
                        />
                      )
                      : null
                  )}
                  headlineText={item.elements.title.value}
                  button={(
                    <>
                      <div>
                        <LinkButton className="btn-light-registration mb-4" href={item.id}>
                          <FormattedMessage id="publication.view_details" />
                          <IconFurther18 />
                        </LinkButton>
                      </div>
                      <div>
                        <LinkButton className="btn-light-registration" href={item.elements.external_url.value}>
                          <FormattedMessage id="publication.go_to" />
                          <IconExternalLink18 />
                        </LinkButton>
                      </div>
                    </>
                  )}
                />
              ))}
            </RelatedStudyGrid>
          </ArticleHeadline>
        )}
      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemPoster(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        publishing_date {
          value
        }
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        title {
          value
        }
        authors {
          value
        }
        publishing_date {
          value
        }
        category__publications_poster {
          value {
            name
          }
        }
        description {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
        download {
          value {
            ...KontentItemAsset
          }
        }
        image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        related_publication {
          value {
            ...PublicationCards
          }
        }
      }
    }
  }
`;

KontentItemPoster.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPoster;
