import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import LinkButton from "../components/button/linkButton";
import NavigationLink from "../components/navigation/navigationLink";
import StudyCard from "../components/studyCard/studyCard";
import { IconFurther18 } from "../icons";

const KontentItemPublicationCards = ({ data, limit }) => {
  const intl = useIntl();

  const studyData = Array.isArray(data) ? data : [data];

  const studyList = [];

  studyData.forEach((tile) => {
    const journalIcon = tile.elements.journal_name.value.length > 0
      ? tile.elements.journal_name.value[0].elements.icon.value
      : null;

    studyList.push(
      <StudyCard
        key={tile.id}
        href={tile.id}
        image={(
          journalIcon !== null && journalIcon.length > 0
            ? <KontentAssetsImage data={journalIcon[0]} width={200} height={200} className="rounded-circle" />
            : null)}
        journal={tile.elements.journal_name.value.length > 0
          && tile.elements.journal_name.value[0].elements.journal.value.length > 0
          ? tile.elements.journal_name.value[0].elements.journal.value[0].name
          : null}
        source={tile.elements.journal_issue_number.value}
        author={tile.elements.authors.value}
        title={tile.elements.title.value}
        date={(
          tile.elements.publishing_date.value
            ? new Date(tile.elements.publishing_date.value)
            : null
        )}
        category={tile.elements.publications.value.length > 0
          ? tile.elements.publications.value[0].name
          : null}
        button={(
          <>
            <LinkButton href={tile.id}>
              {intl.formatMessage({ id: "study_card.views_details" })} <IconFurther18 />
            </LinkButton>
            {tile.elements.external_url.value.length > 0 && (
              <NavigationLink href={tile.elements.external_url.value}>
                {intl.formatMessage({ id: "publication_card.go_to_publication_DOI" })}
              </NavigationLink>
            )}

          </>
        )}
      />,
    );
  });

  if (limit) {
    return React.Children.toArray(studyList.slice(0, limit));
  }

  return React.Children.toArray(studyList);
};

export const query = graphql`
  fragment PublicationCards on kontent_item_publication {
    id
    system {
      codename
    }
    elements {
      authors {
        value
      }
      url_slug {
        value
      }
      external_url {
        value
      }
      journal_issue_number {
        value
      }
      title {
        value
      }
      publishing_date {
        value
      }
      publications {
        value {
          name
          codename
        }
      }
      journal_name {
        value {
          ... on kontent_item_journal {
            id
            elements {
              journal {
                value {
                  name
                }
              }
              icon {
                value {
                  description
                  height
                  size
                  name
                  type
                  url
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemPublicationCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
};

KontentItemPublicationCards.propTypes = {
  limit: null,
};

export default KontentItemPublicationCards;
