import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import LinkButton from "../components/button/linkButton";
import CircularIcon from "../components/circularIcon/circularIcon";
import StudyCard from "../components/studyCard/studyCard";
import {
  IconFurther18,
  IconStudy18,
} from "../icons";

const KontentItemStudyCard = ({ data }) => {
  const intl = useIntl();

  const studyData = Array.isArray(data) ? data : [data];

  const studyList = [];

  studyData.forEach((tile) => {
    studyList.push(
      <StudyCard
        key={tile.id}
        href={tile.id}
        image={(
          <CircularIcon
            image={<IconStudy18 />}
          />
        )}
        source={tile.elements.identifier.value}
        title={tile.elements.title.value}
        type={
          tile.elements.studies_type.value.length > 0
            ? tile.elements.studies_type.value[0].name
            : null
        }
        category={
          tile.elements.studies_type.value.length > 0
            ? tile.elements.studies_category.value[0].codename
            : null
        }
        button={(
          <LinkButton href={tile.id}>
            {intl.formatMessage({ id: "study_card.views_details" })} <IconFurther18 />
          </LinkButton>
        )}
      />,
    );
  });

  return React.Children.toArray(studyList);
};

export const query = graphql`
  fragment StudyCards on kontent_item_study {
    id
    system {
      codename
    }
    elements {
      identifier {
        value
      }
      studies_type {
        value {
          name
        }
      }
      studies_category {
        value {
          codename
        }
      }
      title {
        value
      }
    }
  }
`;

KontentItemStudyCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemStudyCard;
