import React, { forwardRef, useImperativeHandle, useState } from "react";

import classNames from "classnames";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

import { IconClose18 } from "../../icons";

const TextLightbox = forwardRef(({ children, title, variant }, ref) => {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  // Fixme: there should be a better approach
  useImperativeHandle(ref, () => ({
    close: () => {
      setShow(false);
    },
    show: () => {
      setShow(true);
    },
  }));

  return (
    <Modal
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
      className={classNames(
        "text-modal",
        {
          "only-content": !title,
          [`text-modal--${variant}`]: variant,
        },
      )}
    >
      {title && (
        <Modal.Header>
          <div className="flex-fill text-truncate">
            {title}
          </div>
          <button
            type="button"
            className="close text-black"
            data-dismiss="modal"
            aria-label={intl.formatMessage({ id: "button.close.aria_label" })}
            onClick={() => {
              setShow(false);
            }}
          >
            <IconClose18 />
          </button>
        </Modal.Header>
      )}
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
});

TextLightbox.displayName = "TextLightbox";

TextLightbox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["download", "registration-dialog"]),
};

TextLightbox.defaultProps = {
  children: null,
  title: null,
  variant: null,
};

export default TextLightbox;
