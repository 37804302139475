import React, { Component } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {
  IconClose24,
  IconSuccess24,
  IconWarning24,
} from "../../icons";
import { notificationService, notificationType } from "../../services/notification.service";
import history from "../../utils/history";
import LinkButton from "../button/linkButton";

import "./notification.scss";

class Notification extends Component {
  static cssClasses(notification) {
    if (!notification) return "";

    const classes = ["notification"];

    const alertTypeClass = {
      [notificationType.success]: "notification--success",
      [notificationType.error]: "notification--error",
      [notificationType.info]: "notification-info",
      [notificationType.warning]: "notification-warning",
    };

    classes.push(alertTypeClass[notification.type]);

    if (notification.fade) {
      classes.push("fade");
    }

    return classes.join(" ");
  }

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };

    this.subscription = null;
  }

  componentDidMount() {
    const {
      id,
    } = this.props;

    this.subscription = notificationService.onNotification(id)
      .subscribe((notification) => {
        const {
          notifications,
        } = this.state;

        if (!notification.message) {
          const filteredNotifications = notifications.filter(((x) => x.keepAfterRouteChange));
          const tmpNotifications = [];

          filteredNotifications.forEach((x) => {
            const tmpNotification = x;
            delete tmpNotification.keepAfterRouteChange;
            tmpNotifications.push(tmpNotification);
          });

          this.setState({ notifications: tmpNotifications });
        }

        this.setState({ notifications: [...notifications, notification] });

        if (notification.autoClose) {
          if (notification.delay) {
            setTimeout(() => this.removeNotification(notification), notification.delay);
          } else {
            setTimeout(() => this.removeNotification(notification), 3000);
          }
        } else if (notification.delay) {
          setTimeout(() => this.removeNotification(notification), notification.delay);
        }
      });

    this.historyUnlisten = history().listen(() => {
      notificationService.clear(id);
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.historyUnlisten();
  }

  removeNotification(notification) {
    const {
      notifications,
    } = this.state;

    const {
      fade,
    } = this.props;

    if (fade) {
      const notificationWithFade = { ...notification, fade: true };

      this.setState({
        notifications: notifications.map((x) => (x === notification ? notificationWithFade : x)),
      });

      setTimeout(() => {
        this.setState({ notifications: notifications.filter((x) => x !== notification) });
      }, 100);
    } else {
      this.setState({ notifications: notifications.filter((x) => x !== notification) });
    }
  }

  render() {
    const { notifications } = this.state;

    if (!notifications.length) return null;

    return (
      <Container className="notification-container">
        <Row className="align-items-center">
          <Col xl={{ offset: 1, span: 10 }}>
            {notifications.map((notification) => (
              <Row
                key={notification.id}
                className={classNames("no-gutters", Notification.cssClasses(notification))}
              >
                <Col md={6} className="d-flex align-items-center">
                  <div className="notification__icon">
                    {notification.type === notificationType.error && <IconWarning24 />}
                    {notification.type === notificationType.success && <IconSuccess24 />}
                  </div>
                  <div className="notification__text typo-label">
                    {notification.message}
                  </div>
                </Col>
                <Col md={6}>
                  <Row className="notification__buttons">
                    {notification.action && (
                      <Col className="col-auto">{notification.action}</Col>
                    )}
                    <Col className="col-auto">
                      <LinkButton onClick={() => this.removeNotification(notification)}>
                        <FormattedMessage id="notification.dismiss" /> <IconClose24 />
                      </LinkButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

Notification.propTypes = {
  fade: PropTypes.bool,
  id: PropTypes.string,
};

Notification.defaultProps = {
  fade: true,
  id: "default-notification",
};

export default Notification;
