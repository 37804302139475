import React, { useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";

import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import IconButton from "../button/iconButton";

import "./overviewText.scss";

const OverviewText = ({ headline, children, readMoreText }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <Row className="overview-text text">
        <Col sm={12} md={8}>
          <h2>{headline}</h2>
          {children && (
            <p className="overview-text__text">{children}</p>
          )}
          {readMoreText && (
            <>
              <Collapse in={showAll}>
                <div>
                  {readMoreText}
                </div>
              </Collapse>
              <IconButton
                size="sm"
                className="mt-5"
                variant="link"
                onClick={() => {
                  setShowAll(!showAll);
                }}
              >
                {showAll
                  ? <><FormattedMessage id="text.read_less" /> <IconAccordionMinimize18 /></>
                  : <><FormattedMessage id="text.read_more" /> <IconAccordionExpand18 /></>}
              </IconButton>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

OverviewText.propTypes = {
  children: PropTypes.string,
  headline: PropTypes.string.isRequired,
  readMoreText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

OverviewText.defaultProps = {
  children: null,
  readMoreText: null,
};

export default OverviewText;
