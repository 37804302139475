import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import CircularIcon from "./circularIcon";

const CircularIconDepartment = ({ className, ...furtherProps }) => (
  <CircularIcon {...furtherProps} className={classNames(className, "circular-icon--department")} />
);

CircularIconDepartment.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

CircularIconDepartment.defaultProps = {
  className: null,
  image: null,
};

export default CircularIconDepartment;
