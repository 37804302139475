import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import KontentAssetsImage from "./kontentAssetsImage";
import SubsidiaryTile from "../components/subsidiaryTile/subsidiaryTile";

const KontentItemSubsidiariesCard = ({ data }) => {
  const tileData = Array.isArray(data) ? data : [data];

  const tileList = [];

  tileData.forEach((tile) => {
    const image = tile.elements.image.value.length > 0
      ? tile.elements.image.value[0]
      : null;

    const backgroundImage = tile.elements.background_image.value.length > 0
      ? tile.elements.background_image
      : null;

    tileList.push(
      <SubsidiaryTile
        href={
          tile.elements.button_url.value.length > 0
            ? tile.elements.button_url.value[0].id
            : "#"
        }
        headerBackgroundImage={backgroundImage
          ? backgroundImageHelper(backgroundImage, { width: 500 })
          : null}
        headerLogo={image ? (
          <KontentAssetsImage
            data={image}
            height={60}
            width={200}
            fit="contain"
          />
        ) : null}
        title={tile.elements.headline.value}
        text={tile.elements.text.value}
        buttonText={tile.elements.button_text.value}
      />,
    );
  });

  return React.Children.toArray(tileList);
};

export const query = graphql`
  fragment KontentItemSubsidiariesCard on kontent_item_subsidiaries_card {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      background_image {
        value {
          ...KontentItemImage
        }
      }
      headline {
        value
      }
      text {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemSubsidiariesCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSubsidiariesCard;
