import React from "react";

import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";

const DynamicHeadline = (props) => {
  const {
    icon,
    headline,
    heroImage,
    breadcrumb,
    text,
    ...futherProps
  } = props;

  if (heroImage) {
    return (
      <>
        <HeroHeadline
          imageData={heroImage}
          text={text}
          {...futherProps}
        >
          {headline}
        </HeroHeadline>

        {breadcrumb}
      </>
    );
  }

  return (
    <>
      {breadcrumb}
      <ContentHeader
        headline={headline}
        media={icon}
        description={text}
        {...futherProps}
      />

    </>
  );
};

DynamicHeadline.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
};

DynamicHeadline.defaultProps = {
  heroImage: null,
  icon: null,
  text: null,
};

export default DynamicHeadline;
