import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./glossaryCategory.scss";

const GlossaryCategory = ({ children, category }) => (
  <Row className="glossary-category">
    <Col xs={12} className="glossary-category__category">
      {category}
    </Col>
    <Col xs={12}>
      <Row>
        {React.Children.map(children, (item) => <Col md={6} xl={4} key={item.key}>{item}</Col>)}
      </Row>
    </Col>
  </Row>
);

GlossaryCategory.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default GlossaryCategory;
