import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Divider from "../divider/divider";

import "./listElement.scss";

const ListElement = (props) => {
  const {
    name,
    description,
    category,
    actions,
  } = props;

  return (
    <Row className="list-element">
      <Col md={6}>
        <Row>
          <Col xl={4} className="typo-body1">
            {name}
          </Col>
          <Col xl={8} className="list-element__description">
            {description}
          </Col>
        </Row>
      </Col>
      <Col md={{ offset: 2, span: 4 }} xl={{ offset: 0, span: 6 }}>
        <Row>
          <Col xl={6} className="list-element__category">
            {category}
          </Col>
          <Col xl={6} className="list-elements__actions">
            {actions}
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  );
};

ListElement.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ListElement;
