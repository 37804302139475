import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import CircularIconDescription from "../components/circularIcon/circularIconDescription";

const KontentItemJournal = (props) => {
  const {
    data,
    issueNumber,
  } = props;

  const journal = data;
  let description = "";

  if (!journal || !journal.elements) {
    return null;
  }

  if (journal.elements.journal.value.length > 0) {
    description = journal.elements.journal.value[0].name;

    if (issueNumber !== null) {
      description += `, ${issueNumber}`;
    }
  } else if (issueNumber !== null) {
    description = issueNumber;
  }

  return (
    <CircularIconDescription
      description={description}
      image={journal.elements.icon.value.length > 0 ? (
        <KontentAssetsImage
          data={journal.elements.icon.value[0]}
          width={74}
        />
      ) : null}
    />
  );
};

export const query = graphql`
  fragment KontentItemJournal on kontent_item_journal {
    id
    system {
      codename
    }
    elements {
      journal {
        value {
          name
        }
      }
      icon {
        value {
          description
          name
          url
          size
          type
          width
          height
        }
      }
    }
  }
`;

KontentItemJournal.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  issueNumber:
  PropTypes.string,
};

KontentItemJournal.defaultProps = {
  issueNumber: null,
};

export default KontentItemJournal;
