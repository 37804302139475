import React from "react";

import { graphql, StaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import Accordion from "react-bootstrap/Accordion";
import Nav from "react-bootstrap/Nav";

import NavigationLink from "./navigationLink";
import { getCurrentLanguage } from "../../utils/navigation";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

const StaticQueryMobileNavigation = ({ data }) => {
  const primaryNavigation = [];

  data.primaryNavigation.elements.elements.value.forEach((item, index) => {
    primaryNavigation.push((
      <React.Fragment key={item.elements.title.value}>
        <StateAwareAccordionToggle eventKey={`menu${index}`}>
          <span className="typo-title3">
            {item.elements.title.value}
          </span>
        </StateAwareAccordionToggle>
        <Accordion.Collapse eventKey={`menu${index}`} className="burger-navigation__accordion-collapse">
          <>
            <NavigationLink
              href={item.elements.url_slug.value}
              key={item.id}
              className="nav-link d-flex justify-content-between align-items-center"
              partiallyActive={false}
            >
              <FormattedMessage id="navigation.menu.overview" />
            </NavigationLink>
            {item.elements.elements.value.map((link) => {
              if (!link.id) return null;

              return (
                <NavigationLink
                  {...link}
                  href={link.elements.external_url.value || `${item.elements.url_slug.value}/${link.elements.url_slug.value}`}
                  key={link.id}
                  className="nav-link"
                />
              );
            })}
          </>
        </Accordion.Collapse>
      </React.Fragment>
    ));
  });

  if (data.infoNavigation && data.infoNavigation.elements) {
    primaryNavigation.push((
      <React.Fragment key="menuinfonavigation1">
        <StateAwareAccordionToggle eventKey="menuinfonavigation1">
          <Nav.Item className="burger-navigation__item-secondary">
            <FormattedMessage id="navigation.info_for" />
          </Nav.Item>
        </StateAwareAccordionToggle>
        <Accordion.Collapse eventKey="menuinfonavigation1" className="burger-navigation__accordion-collapse">
          <>
            {data.infoNavigation.elements.elements.value.map((link) => {
              if (!link.id) return null;

              return (
                <NavigationLink
                  {...link}
                  href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                  key={link.id}
                  className="nav-link"
                />
              );
            })}
          </>
        </Accordion.Collapse>
      </React.Fragment>
    ));
  }

  primaryNavigation.push((
    <React.Fragment key="menuSecondaryNavigation2">
      {data.secondaryNavigation.elements.elements.value.map((link) => {
        if (!link.id) return null;

        return (
          <NavigationLink
            {...link}
            href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
            key={link.id}
            className="nav-link burger-navigation__nav-direct-link"
          />
        );
      })}
    </React.Fragment>
  ));

  return primaryNavigation;
};

const MobileNavigation = (props) => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
          {
            primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id,
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                          elements {
                            value {
                              ... on kontent_item_navigation_item {
                                id
                                elements {
                                  title {
                                    value
                                  }
                                  url_slug {
                                    value
                                  }
                                  external_url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            secondaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "secondary_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id,
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            infoNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "info_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      typename: __typename
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      render={(data) => {
        const nav = {
          infoNavigation: getCurrentLanguage(data.infoNavigation.nodes, intl.locale),
          primaryNavigation: getCurrentLanguage(data.primaryNavigation.nodes, intl.locale),
          secondaryNavigation: getCurrentLanguage(data.secondaryNavigation.nodes, intl.locale),
        };

        return <StaticQueryMobileNavigation data={nav} {...props} />;
      }}
    />
  );
};

export default MobileNavigation;
