import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./fileDownloadGrid.scss";

const FileDownloadGrid = ({ children, useInSidebarLayout }) => {
  let colProps = {
    md: 6,
    xl: 4,
  };

  if (useInSidebarLayout) {
    colProps = {
      md: 6,
    };
  }

  return (
    <Row className="file-download-grid">
      {React.Children.map(children, (child) => (
        <Col {...colProps}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

FileDownloadGrid.propTypes = {
  children: PropTypes.node.isRequired,
  useInSidebarLayout: PropTypes.bool,
};

FileDownloadGrid.defaultProps = {
  useInSidebarLayout: false,
};

export default FileDownloadGrid;
