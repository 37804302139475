import React from "react";

import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

import "./searchHit.scss";

const searchHit = (props) => {
  const {
    breadcrumb,
    title,
    category,
    description,
    imageBase64,
    imageSrc,
    documentType,
    url,
  } = props;

  const openLink = () => {
    const newWindow = window.open(
      url,
      documentType === "html" ? "_self" : "_blank",
      "noopener,noreferrer",
    );

    newWindow.opener = null;
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className="search-hit"
      onClick={() => {
        openLink();
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          openLink();
          event.stopPropagation();
        }
      }}
    >
      <div className="search-hit__header">
        <div>
          {category && (
            <div className="search-hit__category">{category}</div>
          )}
          <div className="search-hit__title">{title}</div>
          <div className="search-hit__description d-none d-sm-block">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description, { allowedTags: ["em"] }) }} />
          </div>
          <div className="d-none d-sm-block">
            {breadcrumb}
          </div>
        </div>
        <div
          className="search-hit__image d-flex align-self-center"
          style={{ backgroundImage: `url(${imageSrc}), url(data:image/gif;base64,${imageBase64})` }}
        />
      </div>
      <div className="search-hit__description d-sm-none">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description, { allowedTags: ["em"] }) }} />
      </div>
      <div className="d-sm-none">
        {breadcrumb}
      </div>
    </div>
  );
};

searchHit.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.object]),
  category: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  imageBase64: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

searchHit.defaultProps = {
  breadcrumb: null,
  category: null,
};

export default searchHit;
