import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import IconButton from "../components/button/iconButton";
import LinkButton from "../components/button/linkButton";
import CircularIconDepartment from "../components/circularIcon/circularIconDepartment";
import EyecatcherIcon from "../components/eyecatcher/eyecatcherIcon";

const KontentItemEyecatcherIcon = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  let button = null;
  let buttonLink = null;

  if (eyecatcher.elements.button_text.value) {
    if (eyecatcher.elements.button_url.value.length > 0) {
      button = (
        <IconButton
          href={eyecatcher.elements.button_url.value[0].id}
          variant="outline-primary"
          activeClassName={null}
        >
          {eyecatcher.elements.button_text.value}
        </IconButton>
      );
      buttonLink = eyecatcher.elements.button_url.value[0].id;
    } else if (eyecatcher.elements.button_url_extern.value) {
      button = (
        <LinkButton variant="normal" href={eyecatcher.elements.button_url_extern.value}>
          {eyecatcher.elements.button_text.value}
        </LinkButton>
      );
      buttonLink = eyecatcher.elements.button_url_extern.value;
    }
  }

  return (
    <EyecatcherIcon
      title={eyecatcher.elements.headline.value}
      buttonText={eyecatcher.elements.label.value}
      buttonLink={buttonLink}
      icon={
        eyecatcher.elements.icon.value.length > 0
          ? (
            <CircularIconDepartment
              image={(
                <KontentAssetsImage
                  data={eyecatcher.elements.icon.value[0]}
                  height={72}
                  width={72}
                />
              )}
            />
          )
          : null
      }
    >
      {
       button
      }
    </EyecatcherIcon>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherIcon on kontent_item_eyecatcher_icon {
    id
    system {
      codename
    }
    elements {
      icon {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      } 
      headline {
        value
      }
      label {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
         id
        }
      }
      button_url_extern {
        value
      }
    }
  }
`;

KontentItemEyecatcherIcon.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherIcon;
