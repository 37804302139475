import React from "react";

import PropTypes from "prop-types";

import CircularIcon from "./circularIcon";

const CircularIconDescription = ({ className, description, ...furtherProps }) => (
  <div className="circular-icon-description">
    <CircularIcon {...furtherProps} className={className} />
    {description && (<div className="circular-icon__description">{description}</div>)}
  </div>
);

CircularIconDescription.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

CircularIconDescription.defaultProps = {
  className: null,
  description: null,
  image: null,
};

export default CircularIconDescription;
