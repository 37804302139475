import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { navigate } from "gatsby";
import {
  FormattedMessage,
  useIntl,
} from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import {
  generateInitialFormValues,
  getValidationArray,
} from "./dynamicFormUtils";
import { useSubmitIoDynamicFormMutation } from "../../features/io/ioDynamicFormApiSlice";
import {
  IconRetry24,
  IconSubmit18,
  IconSubmit24,
} from "../../icons";
import { notificationService } from "../../services/notification.service";
import { cleanupObject } from "../../utils/formik/objectUtils";
import mapKontentItem from "../../utils/mapKontentItem";
import getUrnParams from "../../utils/urnParamHelper";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import {
  createYupSchema,
} from "../localForms/formBuilder";
import Notification from "../notification/notification";

import "./dynamicForm.scss";

const DynamicForm = (props) => {
  const {
    fields,
    buttonText,
    thankYouPageLink,
    kontentItemId,
  } = props;

  const intl = useIntl();

  const secondaryURN = useSelector((state) => state.tracking.secondaryURN);
  const URN = useSelector((state) => state.tracking.URN);
  const [submitIoDynamicForm, submitIoDynamicFormResult] = useSubmitIoDynamicFormMutation();

  const initialValues = generateInitialFormValues(fields);

  const validationArray = getValidationArray(fields, intl);
  const validationSchema = Yup.object(validationArray.reduce(createYupSchema, {}));

  // passing a meta object with additional meta data,
  // here adding all required fields into an array from the generated validation schema
  const meta = {
    requiredFields: [],
  };

  Object.keys(validationSchema.fields).forEach((key) => {
    // eslint-disable-next-line no-underscore-dangle
    if (validationSchema.fields[key]._exclusive.required) {
      meta.requiredFields.push(key);
    }
  });

  const [formValues, setFormValues] = useState();

  const onSubmit = (values) => {
    setFormValues(values);

    submitIoDynamicForm({
      data: cleanupObject(values),
      kontentItemId,
      urns: getUrnParams(URN, secondaryURN),
    });
  };

  const onSuccess = () => {
    const { slug } = thankYouPageLink;
    navigate(slug);
  };

  const onError = () => {
    notificationService.error(intl.formatMessage({ id: "error.something_went_wrong" }), {
      action: (
        <LinkButton onClick={() => onSubmit(formValues)}>
          <FormattedMessage id="form.error.retry" /> <IconRetry24 />
        </LinkButton>),
      autoClose: false,
    });
  };

  useEffect(() => {
    if (submitIoDynamicFormResult.isSuccess) {
      onSuccess();
    }

    if (submitIoDynamicFormResult.isError) {
      onError();
    }
  }, [submitIoDynamicFormResult]);

  return (
    <Row className="dynamic-form">
      <Col sm={12} xl={8}>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                {
                  fields.filter((e) => e.elements).map(
                    (item) => mapKontentItem(item, {
                      errors,
                      meta,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched,
                      values,
                    }),
                  )
                }
                <Col lg={12}>
                  <Form.Group>
                    <IconButton
                      type="submit"
                      disabled={submitIoDynamicFormResult.isLoading}
                      loading={submitIoDynamicFormResult.isLoading}
                    >
                      {buttonText}
                      <AdaptiveIcon
                        sm={<IconSubmit18 stroke="white" />}
                        lg={<IconSubmit24 stroke="white" />}
                      />
                    </IconButton>
                  </Form.Group>

                  {meta.requiredFields.length > 0 && (
                    <div className="footnote--required">
                      <span className="required-sign" />
                      <FormattedMessage id="form.required" />
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
      <Notification />
    </Row>
  );
};

DynamicForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  fields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  kontentItemId: PropTypes.string.isRequired,
  thankYouPageLink: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DynamicForm;
