import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemContactBox from "./kontentItemContactBox";
import Section from "../components/section/section";

const KontentItemRepresentativesSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  const contactView = [];
  section.elements.contact_boxes.value.forEach((item) => {
    contactView.push((
      <KontentItemContactBox data={item} />
    ));
  });

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      colProps={{ md: 6 }}
    >
      {contactView}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemRepresentativesSection on kontent_item_representatives_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      contact_boxes {
        value {
          ...KontentItemContactBox
        }
      }
    }
  } 
`;

KontentItemRepresentativesSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRepresentativesSection;
