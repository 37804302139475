import React from "react";

import PropTypes from "prop-types";

import { IconLocation24 } from "../../icons";

import "./location.scss";

const Location = ({ headline, text }) => (
  <div className="location">
    <IconLocation24 />
    <div className="location__body">
      <div className="location__headline">
        {headline}
      </div>
      <div className="location__text">
        {text}
      </div>
    </div>
  </div>
);

Location.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Location;
