import React, { useEffect, useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  IconFacebookOriginalColored24,
  IconLink24,
  IconLinkedInOriginalColored24,
  IconTwitterOriginalColored24,
} from "../../icons";

import "./socialMediaShare.scss";

const SocialMediaShare = (props) => {
  const {
    facebook,
    twitter,
    shareUrl,
    shareTitle,
    onlyIcon,
    copyUrl,
    linkedIn,
  } = props;

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (shareUrl !== null) {
      setUrl(shareUrl);
    } else if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  }, [shareUrl]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = (param) => (
    <Tooltip {...param}>
      {tooltipOpen
        ? <FormattedMessage id="socialmedia.link_copied" />
        : <FormattedMessage id="socialmedia.copy_link" />}
    </Tooltip>
  );

  return (
    <div className="social-media-share">
      {!onlyIcon && <FormattedMessage id="socialmedia.share" />}
      {facebook && (
        <a
          href={process.env.GATSBY_FACEBOOK_URL && process.env.GATSBY_FACEBOOK_URL.replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
        >
          <IconFacebookOriginalColored24 />
        </a>
      )}

      {twitter && (
        <a
          href={process.env.GATSBY_TWITTER_URL && process.env.GATSBY_TWITTER_URL.replace("{url}", url).replace("{title}", shareTitle)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
        >
          <IconTwitterOriginalColored24 />
        </a>
      )}

      {linkedIn && (
        <a
          href={process.env.GATSBY_LINKEDIN_URL && process.env.GATSBY_LINKEDIN_URL.replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
        >
          <IconLinkedInOriginalColored24 />
        </a>
      )}

      {copyUrl && !tooltipOpen && (
        <OverlayTrigger
          placement="top"
          delay={{ hide: 400, show: 100 }}
          overlay={toggle}
        >
          <IconLink24 onClick={() => {
            navigator.clipboard.writeText(url);
            setTooltipOpen(!tooltipOpen);
          }}
          />
        </OverlayTrigger>
      )}
      {copyUrl && tooltipOpen && (
        <OverlayTrigger
          placement="top"
          delay={{ hide: 400, show: 100 }}
          overlay={toggle}
          onToggle={(visible) => {
            if (!visible) {
              setTimeout(() => {
                setTooltipOpen(false);
              }, 500);
            }
          }}
        >
          <IconLink24
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

SocialMediaShare.propTypes = {
  copyUrl: PropTypes.bool,
  facebook: PropTypes.bool,
  linkedIn: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  shareTitle: PropTypes.string,
  shareUrl: PropTypes.string,
  twitter: PropTypes.bool,
};

SocialMediaShare.defaultProps = {
  copyUrl: false,
  facebook: true,
  linkedIn: true,
  onlyIcon: false,
  shareTitle: process.env.GATSBY_SITE === "labs" ? "DKMS Life Science Lab" : "Professionals Platform",
  shareUrl: null,
  twitter: true,
};

export default SocialMediaShare;
