import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import KontentItemDonorCenter from "./kontentItemDonorCenter";
import DonorCenterLocationsSection from "../components/donorCenterLocationsSection/donorCenterLocationsSection";

const KontentItemDonorCenterLocationsSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  const image = section.elements.illustration.value.length > 0
    ? section.elements.illustration.value[0]
    : null;

  return (
    <DonorCenterLocationsSection
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      image={image ? (
        <KontentAssetsImage
          data={image}
          height={516}
          className="img-fluid"
        />
      ) : null}
    >
      {KontentItemDonorCenter({ data: section.elements.donor_centers.value })}
    </DonorCenterLocationsSection>
  );
};

export const query = graphql`
  fragment KontentItemDonorCenterLocationsSection on kontent_item_donor_center_locations_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      illustration {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      donor_centers {
        value {
          ...KontentItemDonorCenter
        }
      }
    }
  }
`;

KontentItemDonorCenterLocationsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonorCenterLocationsSection;
