import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LatestNewsAndEventsCards from "./news/latestNewsAndEventsCards";
import CardSlider from "../components/cardSlider/cardSlider";
import Section from "../components/section/section";

const KontentItemNewsEventsSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      isFullWidthChild
    >
      <CardSlider
        moreUrl={
          section.elements.button_url.value.length > 0
            ? section.elements.button_url.value[0].id
            : null
        }
      >
        {LatestNewsAndEventsCards()}
      </CardSlider>
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemNewsEventsSection on kontent_item_news_events_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      categories {
        value {
          codename
          name
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  } 
`;

KontentItemNewsEventsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNewsEventsSection;
