import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const Tag = ({ children, variant }) => (
  <div className={classNames("tag", variant && `tag--${variant}`)}>{children}</div>
);

Tag.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["learn-topic", "event"]),
};

Tag.defaultProps = {
  variant: null,
};

export default Tag;
