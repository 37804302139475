import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./infoBar.scss";

const InfoBar = ({ children, headline }) => (
  <Row className="info-bar">
    <Col xs={12}>
      <h3>{headline}</h3>
    </Col>
    {React.Children.map(children, (child) => (
      <Col md={6} xl={12}>
        {child}
      </Col>
    ))}
  </Row>
);

InfoBar.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.node.isRequired,
};

export default InfoBar;
