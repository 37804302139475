import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  URN: null,
  UTM: null,
  secondaryURN: null,
};

const TrackingSlice = createSlice({
  initialState,
  name: "tracking",
  reducers: {
    setSecondaryURN: (state, { payload }) => {
      state.secondaryURN = payload;
    },
    setTracking: (state, { payload }) => {
      state.URN = payload.URN;
      state.secondaryURN = payload.secondaryURN;
    },
    setURN: (state, { payload }) => {
      state.URN = payload;
    },
    setUTM: (state, { payload }) => {
      state.UTM = payload;
    },
  },
});

export const {
  setSecondaryURN,
  setTracking,
  setURN,
  setUTM,
} = TrackingSlice.actions;

export default TrackingSlice.reducer;
