import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import InfoBar from "../../components/infoBar/infoBar";
import InfoSection from "../../components/infoBar/infoSection";
import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import Tag from "../../components/tag";
import TextHeader from "../../components/textHeader/textHeader";
import { IconCalendar18, IconShare18 } from "../../icons";
import { format } from "../../utils/dateHelper";
import KontentItemImage from "../kontentItemImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemNewsArticle = ({ data }) => {
  const intl = useIntl();

  const headerImage = (data.page.elements.hero_image.value.length > 0
    && data.page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? data.page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout pageInfo={{ pageName: data.page.elements.headline.value }}>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value.length > 0
          ? data.page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value.length > 0
          ? data.page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={data.page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={data.page.id} />

      <BreadcrumbGenerator id={data.page.id} />

      <TextHeader
        headline={data.page.elements.headline.value}
        description={data.page.elements.lead_text.value}
        tag={
        data.page.elements.categories.value.length > 0
          ? (<Tag>{data.page.elements.categories.value[0].name}</Tag>)
          : null
      }
      />

      {data.page.elements.hero_image.value.length > 0 && (
        <KontentItemImage
          data={data.page.elements.hero_image}
          aspectRatio={1920 / 656}
          className="container--fullwidth"
        />
      )}

      <SidebarLayout
        isRight
        sidebar={(
          <InfoBar headline={intl.formatMessage({ id: "article.details" })}>
            <InfoSection
              sectionHeadline={intl.formatMessage({ id: "socialmedia.lastUpdated" })}
              sectionIcon={<IconCalendar18 />}
            >
              {format(new Date(data.page.system.last_modified))}
            </InfoSection>

            <InfoSection
              sectionHeadline={intl.formatMessage({ id: "socialmedia.share" })}
              sectionIcon={<IconShare18 />}
            >
              <SocialMediaShare onlyIcon />
            </InfoSection>
          </InfoBar>
      )}
      >

        <RichText data={data.page.elements.body} isSidebarLayout />

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemNewsArticle(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        hero_image {
          value {
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        lead_text {
          value
        }
        categories {
          value {
            name
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemNewsArticle.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNewsArticle;
