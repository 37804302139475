import React from "react";

import PropTypes from "prop-types";

import IconButton from "./button/iconButton";

const AdaptiveIcon = ({ sm, lg }) => (
  <>
    <div className="d-xs-block d-md-none">{sm}</div>
    <div className="d-none d-md-block">{lg}</div>
  </>
);

AdaptiveIcon.propTypes = {
  lg: PropTypes.oneOfType([PropTypes.object, IconButton]).isRequired,
  sm: PropTypes.oneOfType([PropTypes.object, IconButton]).isRequired,
};

export default AdaptiveIcon;
