export const cleanupObject = (object) => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === "object") {
        cleanupObject(v);
      }

      if (
        (v && typeof v === "object" && !Object.keys(v).length)
        || v === null || v === undefined || v === ""
      ) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete object[k];
        }
      }
    });

  return object;
};

export const trimObjectValues = (values) => {
  Object.keys(values).forEach((k) => {
    // eslint-disable-next-line no-param-reassign
    values[k] = typeof values[k] === "string" ? values[k].trim() : values[k];
  });

  return values;
};
