import React from "react";

import PropTypes from "prop-types";

const EyecatcherContact = ({ title, contactPerson, children }) => (
  <div className="eyecatcher eyecatcher--contact text-section__fullwidth">
    <div className="eyecatcher__title">
      {title && <h3>{title}</h3>}
      {contactPerson}
    </div>
    {children && (
      <div className="eyecatcher__elements-wrapper">
        <div className="eyecatcher__elements">
          {children}
        </div>
      </div>
    )}
  </div>
);

EyecatcherContact.propTypes = {
  children: PropTypes.node.isRequired,
  contactPerson: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string,
};

EyecatcherContact.defaultProps = {
  title: null,
};

export default EyecatcherContact;
