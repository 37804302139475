import React, { Component } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconCalendarArchive24,
  IconFilter24,
  IconSelect24,
  IconSelectEmpty24,
} from "../../icons";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import FilterTag from "../filterTag/filterTag";

import "./filterDropdownType.scss";

class FilterDropdownType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterCriterionsState: [],
      iconState: false,
      showDropdown: false,
    };
    this.filterCriterions = [];
    this.labels = [];

    if (typeof window !== "undefined" && window.innerWidth >= 576) {
      this.numberOfArticle = 12;
    } else {
      this.numberOfArticle = 6;
    }
    this.numberOfShowArticle = this.numberOfArticle;
  }

  componentDidMount() {
    const { children } = this.props;

    children.forEach((child) => this.labels.indexOf(child.props.type) === -1
      && this.labels.push(child.props.type));

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resize);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.resize);
    }
  }

  setFilterCriterion(label) {
    const indexOfRemove = this.filterCriterions.indexOf(label);

    if (indexOfRemove !== -1) {
      this.filterCriterions.splice(indexOfRemove, 1);
    } else {
      this.filterCriterions.push(label);
    }
  }

  resize = () => {
    if (window.innerWidth >= 576) {
      this.numberOfArticle = 12;
    } else {
      this.numberOfArticle = 6;
    }
    this.numberOfShowArticle = this.numberOfArticle;
  };

  applyFilter() {
    const cloneCriterion = cloneDeep(this.filterCriterions);
    this.setState({ filterCriterionsState: cloneCriterion, showDropdown: false });
  }

  filterChildren() {
    const { children } = this.props;
    const { filterCriterionsState } = this.state;
    let articles;

    if (filterCriterionsState.length > 0) {
      articles = children.filter((child) => (
        filterCriterionsState.indexOf(child.props.type) !== -1
      ));
    } else {
      articles = children;
    }

    return articles;
  }

  render() {
    const {
      headline,
      variant,
      viewPastEventsLink,
    } = this.props;

    const {
      filterCriterionsState,
      showDropdown,
      iconState,
    } = this.state;

    const articles = this.filterChildren();

    let colProps;

    switch (variant) {
      case "events":
        colProps = {
          md: 6,
          sm: 12,
        };
        break;
      case "article-teaser":
        colProps = {
          sm: 6,
        };
        break;
      case "assets":
        colProps = {
          lg: 3,
          sm: 6,
        };
        break;
      case "form":
        colProps = {
          xs: 12,
        };
        break;
      default:
        colProps = {
          lg: 4,
          sm: 6,
        };
    }

    return (
      <div
        className={classNames(
          "filter-dropdown-type",
          variant && `filter-dropdown--${variant}`,
        )}
      >
        {headline && (
          <div className="filter-dropdown__headline">
            {headline}
          </div>
        )}
        <div className="d-lg-flex">
          <LinkButton
            className="filter-dropdown__filter-button"
            onClick={() => {
              this.setState({
                showDropdown: !showDropdown,
              });
            }}
          >
            <IconFilter24 /><FormattedMessage id="filter.type" />
          </LinkButton>
          {filterCriterionsState.length > 0 && (
            <div className="d-flex flex-wrap">
              {
                filterCriterionsState.map((tag) => (
                  <FilterTag
                    key={tag.id}
                    onClick={() => {
                      this.setFilterCriterion(tag);
                      this.applyFilter();
                    }}
                    variant={variant === "events" ? variant : "primary"}
                  >
                    {tag}
                  </FilterTag>
                ))
              }
            </div>
          )}
        </div>
        <Row className="position-relative">
          <Col md={6} xl={4} className={classNames("filter-dropdown__menu", { "d-none": !showDropdown })}>
            <div>
              <div className="filter-dropdown__menu-headline">
                <FormattedMessage id="filter.categories_selected" values={{ count: this.filterCriterions.length }} />
              </div>
              <div className="filter-dropdown__label-container">
                {
                  this.labels.map((label) => (
                    <div
                      className={classNames("filter-dropdown__label", (this.filterCriterions.indexOf(label) !== -1) && "active")}
                      key={label.id}
                      onClick={() => {
                        this.setFilterCriterion(label);
                        this.setState({ iconState: !iconState });
                      }}
                      role="presentation"
                    >
                      {label} {
                      (this.filterCriterions.indexOf(label) !== -1)
                        ? <IconSelect24 />
                        : <IconSelectEmpty24 />
                    }
                    </div>
                  ))
                }
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button onClick={() => this.applyFilter()} size="sm" variant="primary">
                  <FormattedMessage id="filter.apply_filter" />
                </Button>
                {
                  this.filterCriterions.length > 0
                    ? (
                      <LinkButton
                        onClick={() => {
                          this.filterCriterions = [];
                          this.setState({ iconState: [] });
                        }}
                        variant="normal"
                      >
                        <FormattedMessage id="filter.reset" />
                      </LinkButton>
                    )
                    : (
                      <LinkButton
                        onClick={() => {
                          this.setState({ showDropdown: false });
                        }}
                        variant="normal"
                      >
                        <FormattedMessage id="filter.cancel" />
                      </LinkButton>
                    )
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row className="filter-dropdown__article-container">
          {variant === "form" && (
            <Col xs={12} className="filter-dropdown__list-columns">
              <Row>
                <Col xl={2} className="typo-label">
                  <FormattedMessage id="listsection.name" />
                </Col>
                <Col xl={4} className="typo-label">
                  <FormattedMessage id="listsection.description" />
                </Col>
                <Col xl={3} className="typo-label">
                  <FormattedMessage id="listsection.category" />
                </Col>
                <Col xl={3} className="typo-label">
                  <FormattedMessage id="listsection.actions" />
                </Col>
              </Row>
            </Col>
          )}
          {
            articles.map((child, index) => (
              (index < this.numberOfShowArticle) && (
                (index === 0 && child.props.highlight)
                  ? (
                    <Col
                      key={child.props.type + index.toString()}
                      xs={12}
                    >
                      {child}
                    </Col>
                  )
                  : (
                    <Col
                      key={child.props.type + index.toString()}
                      {...colProps}
                    >
                      {child}
                    </Col>
                  )
              )
            ))
          }
        </Row>
        {
          (
            !(articles.length <= this.numberOfArticle)
            || (variant === "events" && viewPastEventsLink)
          )
          && (
            <div className="filter-dropdown__button-container">
              {
                !(articles.length <= this.numberOfArticle)
                && (this.numberOfShowArticle < articles.length
                  ? (
                    <IconButton
                      variant="none"
                      className="filter-dropdown__pagination"
                      onClick={() => {
                        this.numberOfShowArticle += this.numberOfArticle;
                        this.setState({ iconState: !iconState });
                      }}
                    >
                      <FormattedMessage id="filter.show_more" /> <IconAccordionExpand18 />
                    </IconButton>
                  )
                  : (
                    <IconButton
                      variant="none"
                      className="filter-dropdown__pagination"
                      onClick={() => {
                        this.resize();
                        this.setState({ iconState: !iconState });
                      }}
                    >
                      <FormattedMessage id="filter.show_less" /> <IconAccordionMinimize18 />
                    </IconButton>
                  ))
              }
              {(variant === "events" && viewPastEventsLink)
              && (
                <LinkButton className="filter-dropdown__view-past-events-button ml-md-auto" href={viewPastEventsLink}>
                  <FormattedMessage id="filter.view_past_events" />
                  <IconCalendarArchive24 />
                </LinkButton>
              )}
            </div>
          )
        }
      </div>
    );
  }
}

FilterDropdownType.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string,
  variant: PropTypes.oneOf(["events", "article-teaser", "assets", "form"]),
  viewPastEventsLink: PropTypes.string,
};

FilterDropdownType.defaultProps = {
  headline: null,
  variant: null,
  viewPastEventsLink: null,
};

export default FilterDropdownType;
