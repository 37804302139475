class DataLayer {
  static currentStep = "undefined";

  static push = (object) => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push(object);
    }
  };

  static pushEvent = (eventName, options = null) => {
    DataLayer.push({
      event: eventName,
      ...options,
    });
  };

  static unloadRegistration = () => {
    DataLayer.pushEvent("pp_registration_step_close", { event_value: DataLayer.currentStep });
  }

  static addListenerUnloadRegistration = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.addEventListener("unload", DataLayer.unloadRegistration, true);
    }
  }

  static removeListenerUnloadRegistration = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.removeEventListener("unload", DataLayer.unloadRegistration, true);
    }
  }
}

export default DataLayer;
