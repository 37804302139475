export { default as StatusCode404 } from "./a-element-error404.svg";
export { default as StatusCode500 } from "./a-element-error500.svg";
export { default as IllustrationDefault } from "./a-illustrationDefault.svg";
export { default as FaqElement } from "./a-element-faq.svg";
export { default as IllustrationAboutDkmsSmall } from "./a-aboutdkms-small.svg";
export { default as SectionIconFaq } from "./a-sectionicon-faq.svg";
export { default as ThankYouMatch } from "./a-element-thankyou-match.svg";
export { default as ContactElement } from "./a-element-contact.svg";
export { default as PastEvents } from "./a-element-pastevents.svg";
export { default as IllustrationBloodCancer } from "./a-bloodcancer-big.svg";
export { default as HeaderEvents } from "./a-headericon-events.svg";
export { default as HeaderDkmsInternational } from "./a-headericon-dkmsinternational.svg";
export { default as HeaderIconStandard } from "./a-headericon-standards-s.svg";
export { default as HeaderIconDkmsServices } from "./a-headericon-dkmsservices-s.svg";
export { default as HeaderIconGlossary } from "./a-headericon-glossary.svg";
