import { graphql, useStaticQuery } from "gatsby";

import NewsCard from "./newsCard";

const LatestNewsCards = () => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemNewsArticle(filter: {elements: {categories: {value: {elemMatch: {codename: {eq: "news"}}}}}}, sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCard
        }
      }
    }
  `);

  const articles = data.allKontentItemNewsArticle.nodes;

  if (data.allKontentItemNewsArticle) {
    return NewsCard({ currentFirst: true, data: articles, variant: "news" });
  }

  return null;
};

export default LatestNewsCards;
