import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SimpleRichText from "./text/simpleRichText";
import Location from "../components/location/location";

const KontentItemDonorCenter = ({ data }) => {
  const centerData = Array.isArray(data) ? data : [data];

  const centerList = [];

  centerData.forEach((center) => {
    centerList.push(
      <Location
        headline={center.elements.country.value}
        text={<SimpleRichText data={center.elements.adress} />}
      />,
    );
  });

  return React.Children.toArray(centerList);
};

export const query = graphql`
  fragment KontentItemDonorCenter on kontent_item_donor_center {
    id
    system {
      codename
    }
    elements {
      country {
        value
      }
      adress {
        value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
      }
    }
  }
`;

KontentItemDonorCenter.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonorCenter;
