import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

const KontentItemSectionHeader = ({ data }) => {
  const { elements } = data;

  return (
    <Col xs={12} className={classNames(elements.headline.value ? "my-5" : "mt-4")}>
      {elements.headline.value && <h2>{elements.headline.value}</h2>}
      {elements.description.value !== "" && (
        <p className="text-grey-dark">{elements.description.value}</p>
      )}
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemSectionHeader on kontent_item_section_header {
    id
    elements {
      headline {
        value
      }
      description {
        value
      }
    }
  }
`;

KontentItemSectionHeader.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSectionHeader;
