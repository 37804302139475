import React from "react";

import _ from "lodash";
import * as yup from "yup";

import ConfigTemplates from "./descriptions/config-templates";
import Templates from "./templates";
import Tooltip from "./tooltip";

export const build = (field, options) => {
  let output = "";
  const TagName = Templates[_.upperFirst(_.camelCase(field.tagName))];

  if (TagName) {
    // Todo: Never reached
    if (!TagName) {
      return <div className="alert alert-warning">## missing element ##</div>;
    }

    try {
      switch (field.tagName) {
        case "RadioInput":
        case "SelectInput":
          output = (
            <TagName
              key={field.id}
              options={field.options}
              {...field.props}
              {...options}
              {...field.style}
            />
          );
          break;
        default:
          output = <TagName key={field.id} {...field.props} {...field.style} {...options} />;
      }
    } catch (e) {
      output = "Error";
    }
  }

  return (
    <Tooltip
      id={field.props.id}
      tooltipMessage={field.tooltipMessage}
    >
      {output}
    </Tooltip>
  );
};

export const createYupSchema = (schema, config) => {
  const { id, validationType, validations = [] } = config;

  if (!yup[validationType]) {
    return schema;
  }

  let validator = yup[validationType]();

  validations.forEach((validation) => {
    const { params, type } = validation;

    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });

  // eslint-disable-next-line no-param-reassign
  schema[id] = validator;

  return schema;
};

export const generateInitialValues = (inputs) => inputs.reduce((obj, field) => {
  if (field.tagName === "RadioButtonSection") {
    field.props.radioButtonLists.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.id] = item.initialValue;
    });
  }

  // eslint-disable-next-line no-param-reassign
  obj[field.props.id] = field.initialValue;

  return obj;
}, {});

export const getTemplate = (key) => ConfigTemplates[key];
