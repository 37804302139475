import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoDynamicFormApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitIoDynamicForm: build.mutation({
      query: ({ data, kontentItemId, urns }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          classification: "Restricted",
          payload: data,
          ...(urns !== null && { meta: urns }),
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/inq/${process.env.GATSBY_IO_URN_DYNAMIC_FORMS}${encodeURIComponent(kontentItemId)}`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitIoDynamicFormMutation,
} = IoDynamicFormApiSlice;
