import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";

import NavigationLink from "./navigationLink";
import { setShowComplianceSettings } from "../../features/complianceConsentSlice";
import { getCurrentLanguage } from "../../utils/navigation";

const BottomFooterNavigation = () => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      bottomFooterNavigation: allKontentItemNavigationItem(
        filter: { system: { codename: { eq: "bottom_footer_navigation" } } }
      ) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                typename: __typename
                preferred_language
                ... on kontent_item_navigation_item {
                  id
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const openCookieConsentBanner = () => {
    dispatch(
      setShowComplianceSettings(true),
    );
  };

  const footerBottomNavigation = [];

  if (data.bottomFooterNavigation) {
    const nav = getCurrentLanguage(data.bottomFooterNavigation.nodes, intl.locale);

    if (nav) {
      nav.elements.elements.value.forEach((item) => {
        footerBottomNavigation.push(
          <React.Fragment key={item.elements.title.value}>
            <NavigationLink {...item} className="typo-body2" />
          </React.Fragment>,
        );
      });

      /**
       * Inserting a button to open up the cookie consent banner
       * from Piwik Pro
       */
      footerBottomNavigation.push(
        <React.Fragment key="consent-management">
          <Button
            type="button"
            className="consent-management-button"
            onClick={openCookieConsentBanner}
            variant="link"
            title={intl.formatMessage({ id: "consent.management" })}
          >
            {intl.formatMessage({ id: "consent.management" })}
          </Button>
        </React.Fragment>,
      );
    }
  }

  return footerBottomNavigation;
};

export default BottomFooterNavigation;
