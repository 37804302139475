import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

import KontentAssetsImage from "./kontentAssetsImage";
import UniversalNavigation from "../components/universalNavigation/universalNavigation";
import useLanguage from "../hooks/useLanguage";
import {
  IconAfrica24,
  IconChile24,
  IconGermany24,
  IconIndia24,
  IconPoland24,
  IconUk24,
  IconUsa24,
} from "../icons";
import {
  COUNTRY_AFRICA_URL,
  COUNTRY_CHILE_URL,
  COUNTRY_GERMANY_URL,
  COUNTRY_INDIA_URL,
  COUNTRY_POLAND_URL,
  COUNTRY_UK_URL,
  COUNTRY_USA_URL,
} from "../utils/constants";
import iconHelper from "../utils/iconHelper";

const KontentItemUniversalNavigation = () => {
  const data = useStaticQuery(graphql`
    {
      universalNavigation: kontentItemUniversalNavigation {
        id
        elements {
          headline {
            value
          }
          text {
            value
          }
          illustration {
            value {
              width
              height
              url
            }
          }
          headline_de {
            value
          }
          text_de {
            value
          }
          headline_es {
            value
          }
          text_es {
            value
          }
          headline_pl {
            value
          }
          text_pl {
            value
          }
        }
      }
      family: allKontentItemUrlDkmsFamily {
        nodes {
          id
          elements {
            url {
              value
            }
            text {
              value
            }
            favicon {
              value {
                codename
            }
          }
        }
      }
    }
  }
  `);

  const intl = useIntl();
  const dkmsFamily = [];
  const { languageCode } = useLanguage();

  const countryCode = process.env.GATSBY_SITE;

  data.family.nodes.forEach((item) => {
    dkmsFamily.push({
      href: item.elements.url.value,
      icon: iconHelper(item.elements.favicon.value[0].codename),
      id: item.id,
      text: item.elements.text.value,
    });
  });

  let headline;
  let text;

  switch (languageCode) {
    case "de":
      headline = data.universalNavigation.elements.headline_de.value;
      text = data.universalNavigation.elements.text_de.value;
      break;
    case "es":
      headline = data.universalNavigation.elements.headline_es.value;
      text = data.universalNavigation.elements.text_es.value;
      break;
    case "pl":
      headline = data.universalNavigation.elements.headline_pl.value;
      text = data.universalNavigation.elements.text_pl.value;
      break;
    default:
      headline = data.universalNavigation.elements.headline.value;
      text = data.universalNavigation.elements.text.value;
  }

  return (
    <UniversalNavigation
      headline={headline}
      donorCenter={[
        {
          href: COUNTRY_GERMANY_URL,
          icon: <IconGermany24 />,
          id: "0",
          selected: countryCode === "de",
          text: `DKMS ${intl.formatMessage({ id: "country.germany" })}`,
        },
        {
          href: COUNTRY_CHILE_URL,
          icon: <IconChile24 />,
          id: "1",
          selected: countryCode === "cl",
          text: `DKMS ${intl.formatMessage({ id: "country.chile" })}`,
        },
        {
          href: COUNTRY_POLAND_URL,
          icon: <IconPoland24 />,
          id: "2",
          selected: countryCode === "pl",
          text: `DKMS ${intl.formatMessage({ id: "country.poland" })}`,
        },
        {
          href: COUNTRY_UK_URL,
          icon: <IconUk24 />,
          id: "3",
          selected: countryCode === "gb",
          text: `DKMS ${intl.formatMessage({ id: "country.uk" })}`,
        },
        {
          href: COUNTRY_USA_URL,
          icon: <IconUsa24 />,
          id: "4",
          selected: countryCode === "us",
          text: `DKMS ${intl.formatMessage({ id: "country.usa" })}`,
        },
        {
          href: COUNTRY_INDIA_URL,
          icon: <IconIndia24 />,
          id: "5",
          selected: countryCode === "in",
          text: `DKMS-BMST ${intl.formatMessage({ id: "country.india" })}`,
        },
        {
          href: COUNTRY_AFRICA_URL,
          icon: <IconAfrica24 />,
          id: "6",
          selected: countryCode === "za",
          text: `DKMS ${intl.formatMessage({ id: "country.africa" })}`,
        },
      ]}
      description={text}
      dkmsFamily={dkmsFamily}
      icon={
        data.universalNavigation.elements.illustration.value.length > 0
          ? (
            <KontentAssetsImage
              height={150}
              maxWidth={271}
              data={data.universalNavigation.elements.illustration.value[0]}
            />
          )
          : null
      }
    />
  );
};

export default KontentItemUniversalNavigation;
