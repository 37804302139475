import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import KontentItemAsset from "./kontentItemAsset";

const KontentItemDownloadGroup = ({ data, isSidebarLayout }) => {
  const downloads = data.value ? data.value[0] : data;

  if (!downloads || !downloads.elements) {
    return null;
  }

  const elements = downloads.elements.downloads.value.length;
  let colProps = {
    md: { offset: 0, span: 6 },
    sm: { offset: 1, span: 10 },
  };

  let colPropsFirst = {
    lg: { offset: 0, span: 4 },
    md: 5,
    sm: { offset: 1, span: 10 },
  };

  if (elements < 3) {
    colPropsFirst = {
      md: 6,
      sm: 12,
    };
  }

  if (isSidebarLayout) {
    colProps = {
      md: 6,
    };
    colPropsFirst = {
      md: 6,
    };
  }

  return (
    <Row className={classNames("download-section text-section__fullwidth", { "download-section--sidebar-layout": isSidebarLayout })}>
      {downloads.elements.downloads.value.map((asset, index) => (
        <Col {...(index % 2 === 0 ? colPropsFirst : colProps)} key={asset.id}>
          <KontentItemAsset data={asset} />
        </Col>
      ))}
    </Row>
  );
};

export const query = graphql`
    fragment KontentItemDownloadGroup on kontent_item_download_group {
    id
    system {
      codename
    }
    elements {
      downloads {
        value {
          ...KontentItemAsset
        }
      }
    }
  }
`;

KontentItemDownloadGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSidebarLayout: PropTypes.bool,
};

KontentItemDownloadGroup.defaultProps = {
  isSidebarLayout: false,
};

export default KontentItemDownloadGroup;
