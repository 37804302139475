import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./sidebarLayout.scss";

const SidebarLayout = ({ children, sidebar, isRight }) => {
  let config = {
    left: sidebar,
    propsColLeft: {
      xl: 3,
      xs: 12,
    },
    propsColRight: {
      xl: { offset: 1, span: 8 },
      xs: 12,
    },
    right: children,
  };

  if (isRight) {
    config = {
      left: children,
      propsColLeft: {
        xl: 8,
        xs: 12,
      },
      propsColRight: {
        xl: { offset: 1, span: 3 },
        xs: 12,
      },
      right: sidebar,
    };
  }

  return (
    <Row className="sidebar-layout">
      <Col {...config.propsColLeft}>
        {config.left}
      </Col>
      <Col {...config.propsColRight}>
        {config.right}
      </Col>
    </Row>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  isRight: PropTypes.bool,
  sidebar: PropTypes.oneOfType([PropTypes.object]),
};

SidebarLayout.defaultProps = {
  isRight: false,
  sidebar: null,
};

export default SidebarLayout;
