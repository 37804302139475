import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import ExternalResourceListElement from "./externalResourceListElement";

const ExternalResourcesCategoryListElements = ({ categories }) => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemExternalResource {
        nodes {
          ...ExternalResourceListElement
        }
      }
    }
  `);

  const filteredData = [];
  data.allKontentItemExternalResource.nodes.forEach((node) => filteredData.push(node));

  _.remove(filteredData, (currentObject) => {
    // Remove object if not in categories
    let remove = true;
    currentObject.elements.resource_type.value.forEach((category) => {
      if (categories.includes(category.codename)) {
        remove = false;
      }
    });

    return remove;
  });

  if (data.allKontentItemExternalResource) {
    return ExternalResourceListElement({ data: filteredData });
  }

  return null;
};

ExternalResourcesCategoryListElements.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]),
};

ExternalResourcesCategoryListElements.defaultProps = {
  categories: [],
};

export default ExternalResourcesCategoryListElements;
