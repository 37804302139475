import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./divider.scss";

const Divider = ({ isFullWidth }) => (
  <div className={classNames("divider", { "container--fullwidth": isFullWidth })}>
    <hr />
  </div>
);

Divider.propTypes = {
  isFullWidth: PropTypes.bool,
};

Divider.defaultProps = {
  isFullWidth: false,
};

export default Divider;
