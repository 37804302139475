import React from "react";

import PropTypes from "prop-types";

import { format } from "../../utils/dateHelper";
import Divider from "../divider/divider";
import NavigationLink from "../navigation/navigationLink";

import "./studyCard.scss";

const StudyCard = (props) => {
  const {
    href,
    image,
    source,
    date,
    title,
    author,
    button,
    journal,
  } = props;

  return (
    <div className="study-card">
      <div className="d-md-flex">
        {image && (
          <div className="study-card__image">
            {image}
          </div>
        )}
        <div className="study-card__content">
          <NavigationLink href={href} className="no-style">
            <div className="study-card__top">
              <div className="typo-label">{journal && `${journal},`} {source}</div>
              {date && <div className="study-card__date">{format(date)}</div>}
            </div>
            <div className="study-card__title">
              {title}
            </div>
            {author && (
              <div className="study-card__author">
                {author}
              </div>
            )}
          </NavigationLink>
          {button && (
            <div className="study-card__button">
              {button}
            </div>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
};

StudyCard.propTypes = {
  author: PropTypes.string,
  button: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  href: PropTypes.string,
  image: PropTypes.node,
  journal: PropTypes.string,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

StudyCard.defaultProps = {
  author: null,
  button: null,
  date: null,
  href: null,
  image: null,
  journal: null,
};

export default StudyCard;
