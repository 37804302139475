import React from "react";

import classNames from "classnames";
import { truncate } from "lodash";
import PropTypes from "prop-types";

import { format } from "../../utils/dateHelper";
import NavigationLink from "../navigation/navigationLink";
import Tag from "../tag";

import "./articleCard.scss";

const ArticleCard = (props) => {
  const {
    category,
    date,
    headline,
    href,
    image,
    excerpt,
    button,
    className,
  } = props;

  const maxChars = 9999;

  const inner = (
    <>
      <div className="article-card__image-container">
        <div
          className={classNames(
            "article-card__image",
            {
              "placeholder-image placeholder-image--article": !image,
            },
          )}
        >
          {image}
        </div>
      </div>
      {category && (
        <div className="d-flex article-card__subline">
          <div className="article-card__category">
            <Tag>{category}</Tag>
          </div>
          {date && (
            <div className="article-card__date typo-body1">
              {format(date)}
            </div>
          )}
        </div>
      )}
      <div className="article-card__headline">
        {headline}
      </div>
      <div className="article-card__excerpt">
        {truncate(excerpt, { length: maxChars })}
      </div>
      {button}
    </>
  );

  const classNameString = classNames(
    "no-style article-card",
    className,
  );

  if (!href) {
    return (
      <div className={classNameString}>
        {inner}
      </div>
    );
  }

  return (
    <NavigationLink href={href} className={classNameString}>
      {inner}
    </NavigationLink>
  );
};

ArticleCard.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  category: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  excerpt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
};

ArticleCard.defaultProps = {
  button: null,
  category: null,
  className: "",
  date: null,
  excerpt: null,
  image: null,
};

export default ArticleCard;
