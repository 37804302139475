/* eslint-disable */
// todo: fix lint
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import { useSelector } from "react-redux";

const NavigationLink = (item) => {
  let external = false;
  let linkChildren = null;
  let href = null;

  const UTM = useSelector((state) => state.tracking.UTM);

  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          navigationItemId
          systemId
          title
          slug
        }
      }
    }
  `);

  if (item.elements) {
    linkChildren = item.elements.title.value;

    if (item.elements.external_url.value) {
      external = /^(http|\/\/)/.test(item.elements.external_url.value);
      href = item.elements.external_url.value;
    } else {
      href = item.id;
    }
  }

  let text = linkChildren || item.children;

  if (item.loading) {
    text = (<Spinner animation="border" variant="dark" />);
  }

  if (item.href) {
    external = /^(http|\/\/|mailto:|tel:)/.test(item.href);
    href = item.href;
  }

  if (!href) {
    const {
      typeOverwrite,
      loading,
      ...itemProps
    } = item;

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={typeOverwrite || "button"} {...itemProps}>
        {text}
      </button>
    );
  }

  if (!external) {
    let title = "";
    const links = data ? data.links.nodes : [];
    const link = _.find(links, (o) => o.navigationItemId === href || o.systemId === href);

    let urlParams = "";

    if (UTM && UTM !== "") {
      urlParams = `?${UTM}`;
    }

    if (link) {
      href = link.slug;
      title = item.title || link.title;
    } else {
      // todo: dont show id, if not found
      href = (/^(?!\/)/.test(href)) ? `/${href}` : href;
      title = item.title || title;
    }

    if (item.target) {
      return (
        <a
          href={`${href}${urlParams}`}
          className={item.className}
          onMouseEnter={item.onMouseEnter}
          onClick={item.onClick}
          target={item.target}
          title={title}
          rel={item.target === "_blank" ? "noopener noreferrer" : null}
        >
          {text}
        </a>
      );
    }

    return (
      <Link
        to={`${href}${urlParams}`}
        className={item.className}
        onMouseEnter={item.onMouseEnter}
        onClick={item.onClick}
        title={title}
        activeClassName={item.activeClassName !== undefined ? item.activeClassName : "active"}
        partiallyActive={typeof item.partiallyActive === "undefined"}
        state={item.state}
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={item.className}
      onMouseEnter={item.onMouseEnter}
      title={item.title}
    >
      {text}
    </a>
  );
};

export default NavigationLink;
