import React from "react";

import PropTypes from "prop-types";

import ContentHeader from "./contentHeader/contentHeader";
import { StatusCode404, StatusCode500 } from "../images";

const StatusCode = ({ number, text, description }) => (
  <ContentHeader
    headline={text}
    description={description}
    media={{
      404: <StatusCode404 />,
      500: <StatusCode500 />,
    }[number]}
  />
);

StatusCode.propTypes = {
  description: PropTypes.string,
  number: PropTypes.oneOf([404, 500]).isRequired,
  text: PropTypes.string.isRequired,
};

StatusCode.defaultProps = {
  description: null,
};

export default StatusCode;
