import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import OverviewText from "../../components/overviewText/overviewText";
import SEO from "../../components/seo";
import KontentItemDepartmentsSection from "../kontentItemDepartmentsSection";
import KontentItemDonorCenterLocationsSection from "../kontentItemDonorCenterLocationsSection";
import KontentItemNetworkSection from "../kontentItemNetworkSection";
import KontentItemPartnersSlider from "../kontentItemPartnersSlider";
import KontentItemStorySlider from "../kontentItemStorySlider";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import SimpleRichText from "../text/simpleRichText";

const KontentItemAboutOverview = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <OverviewText
        headline={page.elements.subheadline.value}
        readMoreText={SimpleRichText({ data: page.elements.read_more_text })}
      >
        {page.elements.lead_text.value}
      </OverviewText>

      <KontentItemStorySlider data={page.elements.story_slider} />

      <KontentItemDonorCenterLocationsSection data={page.elements.donor_center_locations} />

      <Divider isFullWidth />

      <KontentItemDepartmentsSection data={page.elements.departments} />

      <KontentItemNetworkSection data={page.elements.network} />

      <KontentItemPartnersSlider data={page.elements.partners} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemAboutOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        headline {
          value
        }
        subheadline {
          value
        }
        lead_text {
          value
        }
        read_more_text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        story_slider {
          value {
            ...KontentItemStorySlider
          }
        }
        donor_center_locations {
          value {
            ...KontentItemDonorCenterLocationsSection
          }
        }
        departments {
          value {
            ...KontentItemDepartmentsSection
          }
        }
        network {
          value {
            ...KontentItemNetworkSection
          }
        }
        partners {
          value {
            ...KontentItemPartnersSlider
          }
        }
      }
    }
  }
`;

KontentItemAboutOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemAboutOverview;
