import React from "react";

import PropTypes from "prop-types";

import CardSlider from "../cardSlider/cardSlider";
import DepartmentTileGrid from "../departmentTile/departmentTileGrid";
import TextSection from "../textSection/textSection";

import "./departmentSection.scss";

const DepartmentSection = (props) => {
  const {
    children,
    headline,
    text,
    moreUrl,
  } = props;

  return (
    <div className="department-section">
      <TextSection
        headline={headline}
        text={text}
      />
      <div className="d-none d-md-block">
        <DepartmentTileGrid moreUrl={moreUrl} isLarge>
          {children}
        </DepartmentTileGrid>
      </div>
      <div className="d-block d-md-none">
        <CardSlider moreUrl={moreUrl} twoCols>
          {children}
        </CardSlider>
      </div>
    </div>
  );
};

DepartmentSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  headline: PropTypes.string,
  moreUrl: PropTypes.string,
  text: PropTypes.string,
};

DepartmentSection.defaultProps = {
  headline: null,
  moreUrl: null,
  text: null,
};

export default DepartmentSection;
