import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import Breadcrumb from "../components/breadcrumb/breadcrumb";
import MainLayout from "../components/mainLayout";
import SEO from "../components/seo";
import StatusCode from "../components/statusCode";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      errorPage: kontentItemErrorPage(elements: {status_code: {value: {eq: 404}}}) {
        id
        system {
          codename
        }
        elements {
          headline {
            value
          }
          description {
            value
          }
          status_code {
            value
          }
        }
      }
    }
  `);

  return (
    <MainLayout>
      <SEO title="404: Not found" />

      <Breadcrumb items={[{ title: "404" }]} />

      <StatusCode
        number={data.errorPage.elements.status_code.value}
        text={data.errorPage.elements.headline.value}
        description={data.errorPage.elements.description.value}
      />

    </MainLayout>
  );
};

export default NotFoundPage;
