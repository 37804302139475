import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Banner from "./banner";
import BackgroundImage from "../backgroundImage/backgroundImage";
import NavigationLink from "../navigation/navigationLink";

import "./registryBanner.scss";

const RegistryBanner = (props) => {
  const {
    title,
    description,
    buttonText,
    buttonUrl,
    backgroundImageStyle,
  } = props;

  return (
    <Banner
      className="registry-banner"
      mediaObject={(
        <div className="embed-responsive">
          <BackgroundImage className="embed-responsive-item" backgroundImageStyle={backgroundImageStyle} />
        </div>
      )}
    >
      <Row className="registry-banner__inner">
        <Col xs={12} md={7} lg={6}>
          <h2>{title}</h2>
          <div className="registry-banner__description">
            {description}
          </div>
          <NavigationLink
            href={buttonUrl}
            className="btn btn-light-faq"
          >
            {buttonText}
          </NavigationLink>
        </Col>
      </Row>
    </Banner>
  );
};

RegistryBanner.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default RegistryBanner;
