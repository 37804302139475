import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import LinkButton from "../../components/button/linkButton";
import ListElement from "../../components/listSection/listElement";
import { IconDownload18 } from "../../icons";

const FormsListElement = ({ data, limit }) => {
  const formsData = Array.isArray(data) ? data : data.value;

  const formsList = [];

  formsData.forEach((item) => {
    formsList.push(
      <ListElement
        name={item.elements.name.value}
        description={item.elements.description.value}
        category={
          item.elements.resource_type.value.length > 0
            ? item.elements.resource_type.value.map((category) => (
              <>{category.name}<br /></>
            ))
            : null
        }
        actions={
          item.elements.action_download.value.length > 0
            ? item.elements.action_download.value.map((download) => (
              <>
                <LinkButton
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    download.elements.asset_item.value.length > 0
                      ? download.elements.asset_item.value[0].url
                      : ""
                  }
                >
                  <FormattedMessage id="download" /> <IconDownload18 />
                </LinkButton>
                <br />
              </>
            ))
            : null
        }
      />,
    );
  });

  if (limit) {
    return React.Children.toArray(formsList.slice(0, limit));
  }

  return React.Children.toArray(formsList);
};

export const query = graphql`
  fragment FormsListElement on kontent_item_form {
    id
    system {
      codename
    }
    elements {
      name {
        value
      }
      description {
        value
      }
      resource_type {
        value {
          codename
          name
        }
      }
      action_download {
        value {
          ...KontentItemAsset
        }
      }
    }
  }
`;

FormsListElement.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
};

FormsListElement.propTypes = {
  limit: null,
};

export default FormsListElement;
