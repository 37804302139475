import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleHeadline from "../../components/articleHeadline/articleHeadline";
import IconButton from "../../components/button/iconButton";
import LinkButton from "../../components/button/linkButton";
import Divider from "../../components/divider/divider";
import FileDownloadGrid from "../../components/fileDownloadGrid/fileDownloadGrid";
import IconText from "../../components/iconText/iconText";
import InfoBar from "../../components/infoBar/infoBar";
import InfoSection from "../../components/infoBar/infoSection";
import MainLayout from "../../components/mainLayout";
import RelatedStudy from "../../components/relatedStudy/relatedStudy";
import RelatedStudyGrid from "../../components/relatedStudyGrid/relatedStudyGrid";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeader from "../../components/textHeader/textHeader";
import {
  IconAuthor18,
  IconDownload18,
  IconFurther18,
  IconLink24,
  IconStudy18,
  IconTime18,
  IconTopic18,
} from "../../icons";
import { format } from "../../utils/dateHelper";
import KontentItemAsset from "../kontentItemAsset";
import KontentItemJournal from "../kontentItemJournal";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemPublication = ({ data }) => (
  <MainLayout>

    <SEO
      title={data.page.elements.metadata__meta_title.value}
      description={data.page.elements.metadata__meta_description.value}
      twitterTitle={data.page.elements.metadata__twitter_title.value}
      twitterSite={data.page.elements.metadata__twitter_site.value}
      twitterImage={data.page.elements.metadata__twitter_image.value}
      twitterDescription={data.page.elements.metadata__twitter_description.value}
      twitterCreator={data.page.elements.metadata__twitter_creator.value}
      ogTitle={data.page.elements.metadata__og_title.value}
      ogImage={data.page.elements.metadata__og_image.value}
      ogDescription={data.page.elements.metadata__og_title.value}
    />

    <SubNavigationGenerator id={data.page.id} />

    <BreadcrumbGenerator id={data.page.id} />

    <TextHeader
      icon={
        data.page.elements.journal_name.value.length > 0
          ? (
            <KontentItemJournal
              data={data.page.elements.journal_name.value[0]}
              issueNumber={
                data.page.elements.journal_issue_number.value.length > 0
                  ? data.page.elements.journal_issue_number.value
                  : null
              }
            />
          ) : null
      }
      headline={data.page.elements.title.value}
      button={data.page.elements.external_url.value.length > 0
        ? (
          <IconButton
            href={data.page.elements.external_url.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="publication.go_to" /> <IconDownload18 />
          </IconButton>
        )
        : null}
      small
    />

    <SidebarLayout
      isRight
      sidebar={(
        <InfoBar headline={<FormattedMessage id="publication.details" />}>
          {
            data.page.elements.authors.value && data.page.elements.authors.value.length > 0 && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.authors" />}
                sectionIcon={<IconAuthor18 />}
              >
                {data.page.elements.authors.value}
              </InfoSection>
            )
          }

          {
            data.page.elements.publishing_date.value
            && data.page.elements.publishing_date.value.length > 0
            && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.publishing_date" />}
                sectionIcon={<IconTime18 />}
              >
                {format(new Date(data.page.elements.publishing_date.value))}
              </InfoSection>
            )
          }

          {
            data.page.elements.publications.value
            && data.page.elements.publications.value.length > 0
            && (
              <InfoSection
                sectionHeadline={<FormattedMessage id="publication.category" />}
                sectionIcon={<IconTopic18 />}
              >
                {Array.isArray(data.page.elements.publications.value)
                  && data.page.elements.publications.value.map((val) => val.name).join(", ")}
              </InfoSection>
            )
          }

          {data.page.elements.external_url.value.length > 0 && (
            <InfoSection
              sectionHeadline={<FormattedMessage id="publication.doi" />}
              sectionIcon={<IconLink24 />}
            >
              <a
                download
                href={data.page.elements.external_url.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.page.elements.external_url.value}
              </a>
            </InfoSection>
          )}
        </InfoBar>
      )}
    >
      <ArticleHeadline headline={<FormattedMessage id="publication.abstract" />}>
        <RichText data={data.page.elements.body} />
      </ArticleHeadline>

      <div className="d-xl-none">
        <Divider />
      </div>

      {data.page.elements.downloads.value !== null
      && data.page.elements.downloads.value.length > 0
      && (
        <ArticleHeadline headline={<FormattedMessage id="publication.poster" />}>
          <FileDownloadGrid useInSidebarLayout>
            {data.page.elements.downloads.value.map((item) => (
              <KontentItemAsset data={item} key={item.id} />
            ))}
          </FileDownloadGrid>
        </ArticleHeadline>
      )}

      <div className="d-xl-none">
        <Divider />
      </div>
      {data.page.elements.related_studies.value !== null
      && data.page.elements.related_studies.value.length > 0
      && (
        <ArticleHeadline headline={<FormattedMessage id="publication.related_scientific_studies" />}>
          <RelatedStudyGrid useInSidebarLayout>
            {data.page.elements.related_studies.value.map((item) => (
              <RelatedStudy
                key={item.id}
                headline={(
                  <IconText
                    icon={<IconStudy18 />}
                    variant="faq"
                    typo="typo-label"
                    margin="large"
                    text={item.elements.identifier.value}
                  />
                )}
                headlineText={item.elements.title.value}
                button={(
                  <LinkButton className="btn-light-registration" href={item.id}>
                    <FormattedMessage id="publication.view_details" />
                    <IconFurther18 />
                  </LinkButton>
                )}
              />
            ))}
          </RelatedStudyGrid>
        </ArticleHeadline>
      )}
    </SidebarLayout>

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: kontentItemPublication(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        related_studies {
          value {
            system {
              type
            }
            internal {
              type
            }
            ... on kontent_item_study {
              id
              elements {
                identifier {
                  value
                }
                title {
                  value
                }
                url_slug {
                  value
                }
              }
            }
          }
        }
        journal_issue_number {
          value
        }
        journal_name {
          value {
            ...KontentItemJournal
          }
        }
        publications {
          value {
            name
          }
        }
        downloads {
          value {
            internal {
              type
            }
            ...KontentItemAsset
          }
        }
        publishing_date {
          value
        }
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        title {
          value
        }
        external_url {
          value
        }
        authors {
          value
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemPublication.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPublication;
