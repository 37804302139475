// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-articles-kontent-item-factsheet-js": () => import("./../../../src/templates/articles/kontentItemFactsheet.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-factsheet-js" */),
  "component---src-templates-articles-kontent-item-internal-resource-js": () => import("./../../../src/templates/articles/kontentItemInternalResource.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-internal-resource-js" */),
  "component---src-templates-articles-kontent-item-landingpage-js": () => import("./../../../src/templates/articles/kontentItemLandingpage.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-landingpage-js" */),
  "component---src-templates-articles-kontent-item-news-article-js": () => import("./../../../src/templates/articles/kontentItemNewsArticle.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-news-article-js" */),
  "component---src-templates-articles-kontent-item-poster-js": () => import("./../../../src/templates/articles/kontentItemPoster.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-poster-js" */),
  "component---src-templates-articles-kontent-item-publication-js": () => import("./../../../src/templates/articles/kontentItemPublication.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-publication-js" */),
  "component---src-templates-articles-kontent-item-resources-category-overview-js": () => import("./../../../src/templates/articles/kontentItemResourcesCategoryOverview.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-resources-category-overview-js" */),
  "component---src-templates-articles-kontent-item-study-js": () => import("./../../../src/templates/articles/kontentItemStudy.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-study-js" */),
  "component---src-templates-articles-kontent-item-support-program-js": () => import("./../../../src/templates/articles/kontentItemSupportProgram.js" /* webpackChunkName: "component---src-templates-articles-kontent-item-support-program-js" */),
  "component---src-templates-pages-kontent-item-about-overview-js": () => import("./../../../src/templates/pages/kontentItemAboutOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-about-overview-js" */),
  "component---src-templates-pages-kontent-item-department-js": () => import("./../../../src/templates/pages/kontentItemDepartment.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-department-js" */),
  "component---src-templates-pages-kontent-item-department-overview-js": () => import("./../../../src/templates/pages/kontentItemDepartmentOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-department-overview-js" */),
  "component---src-templates-pages-kontent-item-dkms-services-overview-js": () => import("./../../../src/templates/pages/kontentItemDkmsServicesOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-dkms-services-overview-js" */),
  "component---src-templates-pages-kontent-item-facts-figures-js": () => import("./../../../src/templates/pages/kontentItemFactsFigures.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-facts-figures-js" */),
  "component---src-templates-pages-kontent-item-form-page-js": () => import("./../../../src/templates/pages/kontentItemFormPage.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-form-page-js" */),
  "component---src-templates-pages-kontent-item-forms-overview-js": () => import("./../../../src/templates/pages/kontentItemFormsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-forms-overview-js" */),
  "component---src-templates-pages-kontent-item-glossary-js": () => import("./../../../src/templates/pages/kontentItemGlossary.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-glossary-js" */),
  "component---src-templates-pages-kontent-item-grant-award-js": () => import("./../../../src/templates/pages/kontentItemGrantAward.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-grant-award-js" */),
  "component---src-templates-pages-kontent-item-news-overview-js": () => import("./../../../src/templates/pages/kontentItemNewsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-news-overview-js" */),
  "component---src-templates-pages-kontent-item-posters-overview-js": () => import("./../../../src/templates/pages/kontentItemPostersOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-posters-overview-js" */),
  "component---src-templates-pages-kontent-item-publications-overview-js": () => import("./../../../src/templates/pages/kontentItemPublicationsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-publications-overview-js" */),
  "component---src-templates-pages-kontent-item-research-publications-overview-js": () => import("./../../../src/templates/pages/kontentItemResearchPublicationsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-research-publications-overview-js" */),
  "component---src-templates-pages-kontent-item-research-scope-js": () => import("./../../../src/templates/pages/kontentItemResearchScope.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-research-scope-js" */),
  "component---src-templates-pages-kontent-item-resources-overview-js": () => import("./../../../src/templates/pages/kontentItemResourcesOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-resources-overview-js" */),
  "component---src-templates-pages-kontent-item-service-article-js": () => import("./../../../src/templates/pages/kontentItemServiceArticle.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-service-article-js" */),
  "component---src-templates-pages-kontent-item-services-category-overview-js": () => import("./../../../src/templates/pages/kontentItemServicesCategoryOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-services-category-overview-js" */),
  "component---src-templates-pages-kontent-item-services-overview-js": () => import("./../../../src/templates/pages/kontentItemServicesOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-services-overview-js" */),
  "component---src-templates-pages-kontent-item-studies-overview-js": () => import("./../../../src/templates/pages/kontentItemStudiesOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-studies-overview-js" */),
  "component---src-templates-pages-kontent-item-subsidiary-js": () => import("./../../../src/templates/pages/kontentItemSubsidiary.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-subsidiary-js" */),
  "component---src-templates-pages-kontent-item-support-programs-overview-js": () => import("./../../../src/templates/pages/kontentItemSupportProgramsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-support-programs-overview-js" */),
  "component---src-templates-pages-kontent-item-text-page-js": () => import("./../../../src/templates/pages/kontentItemTextPage.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-text-page-js" */),
  "component---src-templates-pages-kontent-item-thank-you-page-js": () => import("./../../../src/templates/pages/kontentItemThankYouPage.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-thank-you-page-js" */),
  "component---src-templates-pages-kontent-item-tools-overview-js": () => import("./../../../src/templates/pages/kontentItemToolsOverview.js" /* webpackChunkName: "component---src-templates-pages-kontent-item-tools-overview-js" */)
}

