import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

import { IconClose18 } from "../../icons";
import Consent from "../consent/consent";

import "./mediaModal.scss";

const MediaLightbox = forwardRef(({ media }, ref) => {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  // Fixme: there should be a better approach
  useImperativeHandle(ref, () => ({
    close: () => {
      setShow(false);
    },
    show: () => {
      setShow(true);
    },
  }));

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="presentation"
    >
      <Modal
        animation
        show={show}
        onHide={() => {
          setShow(false);
        }}
        className="media-modal"
      >
        <Modal.Header>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label={intl.formatMessage({ id: "button.close.aria_label" })}
            onClick={() => {
              setShow(false);
            }}
          >
            <IconClose18 />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Consent type="video">
            <div className="embed-responsive embed-responsive-16by9">
              {media}
            </div>
          </Consent>
        </Modal.Body>
      </Modal>
    </div>
  );
});

MediaLightbox.displayName = "MediaLightbox";

MediaLightbox.propTypes = {
  media: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default MediaLightbox;
