import flow from "lodash/fp/flow";
import join from "lodash/fp/join";
import map from "lodash/fp/map";
import toPairs from "lodash/fp/toPairs";

const backgroundImageHelper = (image, imageProps) => {
  if (!image || !image.value || image.value.length === 0) {
    return null;
  }

  let backgroundPosition = null;

  if (
    image.value[0].elements.set_focal_point__image_transformer
    && image.value[0].elements.set_focal_point__image_transformer.value
  ) {
    const focalPoint = JSON.parse(image.value[0].elements.set_focal_point__image_transformer.value);

    if (focalPoint.length > 0) {
      const kontentFocalPoint = focalPoint[0].transforms.crop.zoom;

      if (kontentFocalPoint) {
        backgroundPosition = {
          backgroundPosition: `${Math.abs(kontentFocalPoint.xFloat * 100)}% ${Math.abs(kontentFocalPoint.yFloat * 100)}%`,
        };
      }
    }
  }

  const imagePropsUrl = {
    auto: "format",
    fm: "pjpg",
    ...imageProps,
  };

  const imagePropsConverted = flow(
    toPairs,
    map((value) => `${value[0]}=${value[1]}`),
    join("&"),
  )(imagePropsUrl);

  return {
    backgroundImage: `url(${image.value[0].elements.image.value[0].url}?${imagePropsConverted})`,
    ...backgroundPosition,
  };
};

export default backgroundImageHelper;
