import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ProgramTile from "../components/programTile/programTile";

const KontentItemHomeTile = (props) => {
  const {
    data,
  } = props;

  const tile = data.value ? data.value[0] : data;

  if (!tile || !tile.elements) {
    return null;
  }

  let background = {};

  if (tile.elements.image.value.length > 0
    && tile.elements.image.value[0].elements.image.value.length > 0) {
    background = { backgroundImage: `url(${tile.elements.image.value[0].elements.image.value[0].url})` };
  }

  return (
    <ProgramTile
      href={tile.elements.button_url.value.length > 0
        ? tile.elements.button_url.value[0].id
        : null}
      label={tile.elements.headline.value}
      backgroundImageStyle={background}
    />
  );
};

export const query = graphql`
  fragment KontentItemHomeTile on kontent_item_home_tile {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemHomeTile.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHomeTile;
