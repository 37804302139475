import React from "react";

import _ from "lodash";

import Templates from "../templates/simpleTemplates";

const mapContentItem = (item) => {
  let output = ("");

  if (!_.isEmpty(item.internal.type)) {
    const TagName = Templates[_.upperFirst(_.camelCase(item.internal.type))];
    let extra = null;

    if (!TagName) return (<div className="alert alert-warning">## missing element ##</div>);

    switch (item.internal.type) {
      case "kontent_item_image":
        extra = {
          enableComponentStyle: true,
        };
        break;
      default:
        extra = null;
    }

    try {
      output = (<TagName data={item} {...extra} />);
    } catch (e) {
      output = ("Error");
    }
  }

  return output;
};

export default mapContentItem;
