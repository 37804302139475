import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./articleHeadline.scss";

const ArticleHeadline = ({ children, headline, variant }) => (
  <div className={classNames("article-headline", { [`article-headline--${variant}`]: variant })}>
    <div className="article-headline__headline">{headline}</div>
    <div className="article-headline__content">{children}</div>
  </div>
);

ArticleHeadline.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["typo-h2"]),
};

ArticleHeadline.defaultProps = {
  variant: null,
};

export default ArticleHeadline;
