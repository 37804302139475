import React from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import NavigationLink from "../navigation/navigationLink";

import "./iconButton.scss";

const IconButton = (props) => {
  const {
    children,
    className,
    iconOnly,
    ...buttonProps
  } = props;

  return (
    <Button
      as={NavigationLink}
      {...buttonProps}
      typeOverwrite={buttonProps.type}
      className={["icon-btn", className, iconOnly ? "icon-only-btn" : ""]}
    >
      {children}
    </Button>
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
};

IconButton.defaultProps = {
  className: null,
  iconOnly: false,
};

export default IconButton;
