import React from "react";

import classNames from "classnames";
import Cleave from "cleave.js/react";
import { Field } from "formik";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";

import "./phoneFormField.scss";

const PhoneFormField = (props) => {
  const {
    id,
    errors,
    touched,
    meta,
    label,
    onBlur,
    onChange,
    validateField,
    maxLength,
    numberInfo,
    placeholder,
  } = props;

  return (
    <Field name={id} validate={validateField}>
      {() => (
        <>
          <Form.Group controlId={id} className={meta.requiredFields.includes(id) ? "required" : undefined}>
            <Cleave
              options={numberInfo}
              className={classNames(
                "form-control",
                { "is-invalid": errors[id] && touched[id] },
              )}
              placeholder={placeholder}
              name={id}
              onChange={onChange}
              onBlur={onBlur}
              maxLength={maxLength}
              inputMode="numeric"
            />
            <Form.Label>
              {label}
            </Form.Label>
            {errors[id] && touched[id] && (
              <Form.Text className="invalid-feedback">
                <IconError18 />{errors[id]}
              </Form.Text>
            )}
          </Form.Group>
        </>
      )}
    </Field>
  );
};

PhoneFormField.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  numberInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateField: PropTypes.func,
};

PhoneFormField.defaultProps = {
  maxLength: Number.MAX_SAFE_INTEGER,
  placeholder: "",
  validateField: () => {},
};

export default PhoneFormField;
