import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FilterDropdownType from "../../components/filterDropownType/filterDropdownType";
import ListSection from "../../components/listSection/listSection";
import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import TextHeader from "../../components/textHeader/textHeader";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import ExternalResourcesCategoryListElements from "../tools/externalResourceCategoryListElements";

const KontentItemResourcesCategoryOverview = ({ data }) => (
  <MainLayout>

    <SEO
      title={data.page.elements.metadata__meta_title.value}
      description={data.page.elements.metadata__meta_description.value}
      twitterTitle={data.page.elements.metadata__twitter_title.value}
      twitterSite={data.page.elements.metadata__twitter_site.value}
      twitterImage={data.page.elements.metadata__twitter_image.value}
      twitterDescription={data.page.elements.metadata__twitter_description.value}
      twitterCreator={data.page.elements.metadata__twitter_creator.value}
      ogTitle={data.page.elements.metadata__og_title.value}
      ogImage={data.page.elements.metadata__og_image.value}
      ogDescription={data.page.elements.metadata__og_title.value}
    />

    <SubNavigationGenerator id={data.page.id} />

    <BreadcrumbGenerator id={data.page.id} />

    <TextHeader
      headline={data.page.elements.headline.value}
      description={data.page.elements.lead_text.value}
    />
    {
      data.page.elements.categories.value.map((item) => item.codename).filter((item) => (item === "results_data" || item === "raw_data")).length > 0
        ? (
          <FilterDropdownType variant="form">
            {ExternalResourcesCategoryListElements({
              categories: data.page.elements.categories.value.map((item) => item.codename),
            })}
          </FilterDropdownType>
        )
        : (
          <ListSection headline="" isFormList>
            <ExternalResourcesCategoryListElements
              categories={data.page.elements.categories.value.map((item) => item.codename)}
            />
          </ListSection>
        )
    }

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: kontentItemResourcesCategoryOverview(id: {eq: $id}) {
      id
      system {
        codename
      }
      elements {
        categories {
          value {
            codename
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
      }
    }
  }
`;

KontentItemResourcesCategoryOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemResourcesCategoryOverview;
