import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./anchorPoints.scss";

const AnchorPoints = ({ children }) => {
  const content = useRef(null);
  const [anchorPoints, setAnchorPoints] = useState(null);

  useEffect(() => {
    const headlines = content.current.getElementsByTagName("h3");

    if (headlines.length > 2) {
      const tmpAnchorPoints = [];

      for (let i = 0; i < headlines.length; i += 1) {
        headlines[i].id = `anchor-point-${i}`;

        tmpAnchorPoints.push(
          <AnchorLink className="anchor-button" href={`#anchor-point-${i}`} key={`ap${i}`}>
            {headlines[i].textContent}
          </AnchorLink>,
        );
      }

      setAnchorPoints(tmpAnchorPoints);
    }
  }, [content]);

  if (!children.props.data) {
    return null;
  }

  return (
    <div className="anchor-points">
      {anchorPoints && (
        <Row className="anchor-points__link-list">
          <Col md={10} lg={7}>
            {anchorPoints}
          </Col>
        </Row>
      )}
      <Row className="anchor-points__segment-list">
        <Col md={10} lg={7} ref={content}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

AnchorPoints.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnchorPoints;
