import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import { useIntl } from "gatsby-plugin-react-intl";
import _ from "lodash";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";
import InputSelect from "../../inputSelect/inputSelect";

import "./dropdownFormField.scss";

const DropdownFormField = (props) => {
  const {
    id,
    label,
    options,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    otherSelected,
    placeholder,
    setFieldValue,
    values,
  } = props;

  const intl = useIntl();

  const reactSelectOptions = options ? options.map((e) => (e && typeof e === "object" ? {
    label: e.label,
    value: e.value,
  } : { label: e, value: e })) : [];

  const getOptionByValue = (items, value) => _.find(items, { value });

  return (
    <Field
      name={id}
    >
      {() => (
        <Form.Group
          className={classNames(
            {
              required: meta.requiredFields.includes(id),
            },
          )}
        >
          <InputSelect
            label={label}
            placeholder={placeholder || intl.formatMessage({ id: "form.please_select" })}
            inputId={id}
            name={id}
            items={reactSelectOptions}
            value={getOptionByValue(reactSelectOptions, values?.[id])}
            onChange={(selectedOption) => {
              onChange({
                target: {
                  id,
                  name: id,
                  type: "",
                  value: selectedOption?.value || "",
                },
              });

              if (typeof otherSelected === "function") {
                otherSelected(selectedOption?.value || "", setFieldValue);
              }
            }}
            onBlur={onBlur}
            isInvalid={(errors[id] && touched[id])}
          />
          <Form.Label>
            {label}
          </Form.Label>
          {errors[id] && touched[id] && (
            <Form.Text className="invalid-feedback d-block">
              <IconError18 />{errors[id]}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </Field>
  );
};

DropdownFormField.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  otherSelected: PropTypes.func,
  placeholder: PropTypes.string,
  setFieldValue: PropTypes.func,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DropdownFormField.defaultProps = {
  meta: null,
  otherSelected: () => {},
  placeholder: undefined,
  setFieldValue: () => {},
  values: null,
};

export default DropdownFormField;
