import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FaqAccordion from "../faqAccordion/faqAccordion";

import "./commonQuestions.scss";

const CommonQuestions = ({ headline, accordionItems, isPageSection }) => {
  let colProps = {
    lg: { offset: 2, span: 8 },
    sm: { offset: 1, span: 10 },
  };

  if (isPageSection) {
    colProps = {
      xs: 12,
    };
  }

  return (
    <div className={classNames("common-questions text-section__fullwidth", { "common-questions-embed": isPageSection })}>
      <Row>
        <Col {...colProps}>
          <h3>{headline}</h3>
          <FaqAccordion accordionItems={accordionItems} boldTitle />
        </Col>
      </Row>
    </div>
  );
};

CommonQuestions.propTypes = {
  accordionItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string.isRequired,
  isPageSection: PropTypes.bool,
};

CommonQuestions.defaultProps = {
  isPageSection: false,
};

export default CommonQuestions;
