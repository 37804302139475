import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TextSection from "../textSection/textSection";

import "./section.scss";

const Section = (props) => {
  const {
    children,
    headline,
    text,
    isFullWidthChild,
    colProps,
    button,
  } = props;

  return (
    <div className="section">
      <TextSection
        headline={headline}
        text={text}
      />
      {
        isFullWidthChild
          ? (<>{children}</>)
          : (
            <Row className="section__list">
              {React.Children.map(children, (child) => (
                <Col {...colProps}>
                  {child}
                </Col>
              ))}
            </Row>
          )
      }
      {button && (<div className="section__button">{button}</div>)}
    </div>
  );
};

Section.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  colProps: PropTypes.oneOfType([PropTypes.object]),
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isFullWidthChild: PropTypes.bool,
  text: PropTypes.string,
};

Section.defaultProps = {
  button: null,
  colProps: {
    md: 6,
    xl: 4,
  },
  headline: null,
  isFullWidthChild: false,
  text: null,
};

export default Section;
