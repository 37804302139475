import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./articleTeaserEmbed.scss";

const ArticleTeaserEmbed = ({ headline, children }) => {
  const showImage = React.Children.toArray(children).length < 3;

  return (
    <div className="article-teaser-embed text-section__fullwidth">
      {headline !== null && <div className="article-teaser-embed__headline">{headline}</div>}
      <Row className={classNames({ "justify-content-center": showImage })}>
        {React.Children.map(React.Children.toArray(children), (child, index) => (
          <Col
            xs={12}
            sm={6}
            md={showImage ? 6 : 4}
            className="article-teaser-embed__item"
            key={`article-teaser-embed${index.toString()}`}
          >
            {showImage
              ? child
              : React.cloneElement(child, { image: null })}
          </Col>
        ))}
      </Row>
    </div>
  );
};

ArticleTeaserEmbed.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
};

ArticleTeaserEmbed.defaultProps = {
  headline: null,
};

export default ArticleTeaserEmbed;
