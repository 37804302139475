import React, { Component } from "react";

import { graphql, StaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import _ from "lodash";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

import NavigationLink from "./navigationLink";
import { getCurrentLanguage } from "../../utils/navigation";
import BrowserDetection from "../browserDetection";

class StaticQueryPrimaryNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: {},
    };
  }

  onMouseOver(key, entered) {
    this.setState((prevState) => {
      const prevHovered = _.mapValues(prevState.hovered, () => false);

      return {
        hovered: {
          ...prevHovered,
          [key]: entered,
        },
      };
    });
  }

  render() {
    const {
      data,
    } = this.props;

    const {
      hovered,
    } = this.state;

    const primaryNavigation = [];

    const isTouchScreen = BrowserDetection.hasTouchScreen();

    data.elements.elements.value.forEach((item) => {
      primaryNavigation.push((
        <React.Fragment key={item.elements.title.value}>
          <Nav.Item
            onMouseEnter={() => {
              if (!isTouchScreen) {
                this.onMouseOver(item.elements.title.value, true);
              }
            }}
            onMouseLeave={() => this.onMouseOver(item.elements.title.value, false)}
          >
            <NavigationLink
              {...item}
              key={item.id}
              className="nav-link"
              onClick={(/* event */) => {
                if (isTouchScreen && window.matchMedia("(min-width:768px)").matches === true) {
                  // event.preventDefault();
                  this.onMouseOver(item.elements.title.value, !hovered[item.elements.title.value]);
                } else {
                  this.onMouseOver(item.elements.title.value, false);
                }
              }}
            />
          </Nav.Item>
        </React.Fragment>
      ));
    });

    return React.Children.toArray(primaryNavigation);
  }
}

const PrimaryNavigation = (props) => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
          {
            primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                          elements {
                            value {
                              ... on kontent_item_navigation_item {
                                id
                                elements {
                                  title {
                                    value
                                  }
                                  url_slug {
                                    value
                                  }
                                  external_url {
                                    value
                                  }
                                  elements {
                                    value {
                                      ... on kontent_item_navigation_item {
                                        id
                                        elements {
                                          title {
                                            value
                                          }
                                          url_slug {
                                            value
                                          }
                                          external_url {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      render={(data) => {
        const nav = getCurrentLanguage(data.primaryNavigation.nodes, intl.locale);

        return <StaticQueryPrimaryNavigation data={nav} {...props} />;
      }}
    />
  );
};

StaticQueryPrimaryNavigation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PrimaryNavigation;
