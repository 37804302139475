import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";

import "./formField.scss";

const FormField = (props) => {
  const {
    id,
    type,
    maxLength,
    errors,
    touched,
    meta,
    label,
    placeholder,
    onBlur,
    onChange,
    validateField,
    onKeyPress,
    values,
  } = props;

  return (
    <Field name={id} validate={validateField}>
      {() => (
        <>
          <Form.Group
            controlId={id}
            className={classNames(
              { required: meta.requiredFields.includes(id) },
            )}
          >
            <Form.Control
              type={type}
              placeholder={placeholder}
              value={values?.[id]}
              maxLength={maxLength}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              onChange={onChange}
              isInvalid={(errors[id] && touched[id])}
            />
            <Form.Label>
              {label}
            </Form.Label>
            {errors[id] && touched[id] && (
              <Form.Text className="invalid-feedback">
                <IconError18 />{errors[id]}
              </Form.Text>
            )}
          </Form.Group>
        </>
      )}
    </Field>
  );
};

FormField.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  validateField: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

FormField.defaultProps = {
  maxLength: Number.MAX_SAFE_INTEGER,
  onKeyPress: () => {},
  placeholder: undefined,
  validateField: () => {},
};

export default FormField;
