import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./contentHeader.scss";

const ContentHeader = (props) => {
  const {
    className,
    headline,
    description,
    media,
    variant,
    button,
  } = props;

  const { width } = useWindowDimensions();

  let tmpMedia = media;

  if (media && variant === "image-embed" && width >= 992) {
    tmpMedia = React.cloneElement(media, { aspectRatio: 3 / 4 });
  }

  return (
    <div
      className={classNames(
        "container--fullwidth content-header",
        className,
        {
          [`content-header--${variant}`]: variant,
          "content-header-with-button": button !== null,
        },
      )}
    >
      <Container fluid>
        <Row className="content-header__content">
          <Col xs={12} sm={6} lg={{ offset: 1, span: 4 }} className="order-lg-last text-lg-right">
            <div className="content-header__media">{tmpMedia}</div>
          </Col>
          <Col xs={12} lg={7}>
            <h1 className="content-header__headline">{headline}</h1>
            <div className="content-header__description">{description}</div>
            {button && (<div className="content-header__button">{button}</div>)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ContentHeader.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  media: PropTypes.oneOfType([PropTypes.object]),
  variant: PropTypes.oneOf(["faq", "thank-you", "events", "video-embed", "image-embed"]),
};

ContentHeader.defaultProps = {
  button: null,
  className: null,
  media: null,
  variant: null,
};

export default ContentHeader;
