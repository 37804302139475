import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./relatedStudyGrid.scss";

const RelatedStudyGrid = ({ children, useInSidebarLayout }) => {
  let colProps = {
    md: 6,
    xl: 4,
  };

  if (useInSidebarLayout) {
    colProps = {
      md: 6,
    };
  }

  if (children.length === 1) {
    colProps = {
      xs: 12,
    };
  }

  return (
    <Row className="related-study-grid">
      {React.Children.map(children, (child) => (
        <Col {...colProps}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

RelatedStudyGrid.propTypes = {
  children: PropTypes.node.isRequired,
  useInSidebarLayout: PropTypes.bool,
};

RelatedStudyGrid.defaultProps = {
  useInSidebarLayout: false,
};

export default RelatedStudyGrid;
