import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import TextareaFormField from "../../components/localForms/textareaFormField/textareaFormField";
import Tooltip from "../../components/localForms/tooltip/tooltip";

const KontentItemInputTypeText = (props) => {
  const {
    data,
    errors,
    touched,
    values,
    meta,
    onChange,
    onBlur,
  } = props;

  const { elements } = data;

  return (
    <Col xs={12}>
      <Tooltip
        id={elements.id.value}
        tooltipMessage={elements.tooltip.value}
      >
        <TextareaFormField
          id={elements.id.value}
          type="text"
          maxLength={elements.max_length.value}
          label={elements.label.value}
          placeholder={elements.placeholder.value}
          errors={errors}
          touched={touched}
          values={values}
          meta={meta}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Tooltip>
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeText on kontent_item_input_type_text {
    id
    elements {
      id {
        value
      }
      label {
        name
        type
        value
      }
      max_length {
        name
        type
        value
      }
      required {
        name
        type
        value {
          name
          codename
        }
      }
      tooltip {
        value
      }
      placeholder {
        value
      }
    }
  } 
`;

KontentItemInputTypeText.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeText;
