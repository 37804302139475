import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FormsCategoryListElements from "./forms/formsCategoryListElements";
import ResourcesSection from "../components/resourcesSection/resourcesSection";

const KontentItemFormsGroup = ({ data }) => {
  const group = data.value ? data.value[0] : data;

  if (!group || !group.elements) {
    return null;
  }

  const categories = [];

  group.elements.categories.value.forEach((category) => {
    categories.push(category.codename);
  });

  return (
    <ResourcesSection
      headline={group.elements.headline.value}
    >
      {FormsCategoryListElements({ categories })}
    </ResourcesSection>
  );
};

export const query = graphql`
  fragment KontentItemFormsGroup on kontent_item_forms_group {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      categories {
        value {
          codename
        }
      }
    }
  }
`;

KontentItemFormsGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFormsGroup;
