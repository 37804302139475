import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import CardSlider from "../cardSlider/cardSlider";

import "./locationGrid.scss";

const LocationGrid = ({ children }) => {
  const items = React.Children.toArray(children);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="location-grid">
      <div className="d-none d-md-block">
        <Row className="location-grid">
          {items.map((item) => (
            <Col md={4} lg={3} key={item.key}>
              {item}
            </Col>
          ))}
        </Row>
      </div>
      <div className="d-block d-md-none">
        <CardSlider twoCols>
          {items}
        </CardSlider>
      </div>
    </div>
  );
};

LocationGrid.propTypes = {
  children: PropTypes.node,
};

LocationGrid.defaultProps = {
  children: null,
};

export default LocationGrid;
