import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ContactPerson from "../components/contactPerson/contactPerson";

const KontentItemContact = ({ data, stack, hideDepartment }) => {
  const contact = data.value ? data.value[0] : data;

  if (!contact || !contact.elements) {
    return null;
  }

  let image = null;

  if (contact.elements.image.value.length > 0) {
    image = (
      <KontentItemImage
        data={contact.elements.image}
        height={300}
        width={300}
        className="rounded-circle"
        title={contact.elements.name.value}
        alt={contact.elements.name.value}
      />
    );
  }

  return (
    <ContactPerson
      name={contact.elements.name.value}
      department={!hideDepartment ? contact.elements.description.value : null}
      image={image}
      phone={contact.elements.phone.value}
      mail={contact.elements.e_mail.value}
      stack={stack}
    />
  );
};

export const query = graphql`
  fragment KontentItemContact on kontent_item_contact {
    id
    system {
      codename
    }
    elements {
      name {
        value
      }
      description {
        value
      }
      e_mail {
        value
      }
      phone {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemContact.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hideDepartment: PropTypes.bool,
  stack: PropTypes.bool,
};

KontentItemContact.defaultProps = {
  hideDepartment: false,
  stack: false,
};

export default KontentItemContact;
