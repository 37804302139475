import { useIntl } from "gatsby-plugin-react-intl";

const useLanguage = (intlOverride = null) => {
  const intl = intlOverride || useIntl();

  return {
    countryCode: intl.locale.substring(3).toLowerCase(),
    defaultCountry: intl.defaultLocale.substring(3).toLowerCase(),
    defaultLanguageCode: intl.defaultLocale.substring(0, 2),
    languageCode: intl.locale.substring(0, 2),
  };
};

export default useLanguage;
