/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";

import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import CacheReload from "./cacheReload/cacheReload";
import CookieBanner from "./consent/cookieBanner";
import Footer from "./footer/footer";
import Header from "./header/header";

import "./mainLayout.scss";

const MainLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <Container fluid>
        <Header />

        <main>{children}</main>

        <Footer />

        <CookieBanner />

        <CacheReload />
      </Container>
    )}
  />
);

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};

export default MainLayout;
