import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemEyecatcherIcon from "./kontentItemEyecatcherIcon";
import Section from "../components/section/section";

const KontentItemEyecatcherIconSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      isFullWidthChild
    >
      {section.elements.icon_eyecatcher.value.length > 0
        ? (<KontentItemEyecatcherIcon data={section.elements.icon_eyecatcher.value[0]} />)
        : null}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherIconSection on kontent_item_eyecatcher_icon_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      icon_eyecatcher {
        value {
          ...KontentItemEyecatcherIcon
        }
      }
    }
  } 
`;

KontentItemEyecatcherIconSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherIconSection;
