import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import getYear from "date-fns/getYear";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setFilterCriterions, setFilterText, setTargetItem } from "../../features/articleSlice";
import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconDeleteInput18,
  IconFilter24,
  IconSearch24,
  IconSelectBlue24,
  IconSelectEmpty24,
} from "../../icons";
import { dateFactory } from "../../utils/dateHelper";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import FilterTag from "../filterTag/filterTag";

import "./publicationSection.scss";

const PublicationSection = ({ children, headline }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [refresh, setRefresh] = useState(false);
  const [showDropdownYear, setShowDropdownYear] = useState(false);
  const [showDropdownCategory, setShowDropdownCategory] = useState(false);
  const [showDropdownJournal, setShowDropdownJournal] = useState(false);

  const targetIndex = useSelector((state) => state.article.targetIndex);

  const targetItem = useSelector((state) => state.article.targetItem);

  const filterCriterionsPublication = useSelector(
    (state) => state.article.filterCriterionsPublication,
  );

  const filterTextPublication = useSelector((state) => state.article.filterTextPublication);

  const filterCriterionsJournal = useMemo(() => [], []);
  const filterCriterionsYear = useMemo(() => [], []);
  const filterCriterionsCategory = useMemo(() => [], []);
  const labelsCategory = useMemo(() => [], []);
  const labelsYear = useMemo(() => [], []);
  const labelsJournal = useMemo(() => [], []);
  const numberOfArticle = 6;
  const number = useMemo(() => ((targetIndex / numberOfArticle) || 1), []);
  const [numberOfShowArticle, setNumberOfShowArticle] = useState(
    numberOfArticle * Math.ceil(number),
  );

  useEffect(() => {
    children.forEach((child) => child.props.category
  && labelsCategory.indexOf(child.props.category) === -1
  && labelsCategory.push(child.props.category));

    children.forEach((child) => {
      if (!child.props.date) {
        return;
      }

      const year = getYear(new Date(child.props.date));

      if (labelsYear.indexOf(year) === -1) {
        labelsYear.push(year);
      }
    });

    labelsYear.sort().reverse();

    children.forEach(
      (child) => child.props.journal
        && labelsJournal.indexOf(child.props.journal) === -1
        && labelsJournal.push(child.props.journal),
    );

    filterCriterionsJournal.splice(0, filterCriterionsJournal.length - 1);
    filterCriterionsCategory.splice(0, filterCriterionsCategory.length - 1);
    filterCriterionsYear.splice(0, filterCriterionsYear.length - 1);

    filterCriterionsPublication.criterionJournal.forEach((item) => {
      filterCriterionsJournal.push(item);
    });

    filterCriterionsPublication.criterionCategory.forEach((item) => {
      filterCriterionsCategory.push(item);
    });

    filterCriterionsPublication.criterionYear.forEach((item) => {
      filterCriterionsYear.push(item);
    });

    if (targetIndex > 0) {
      setTimeout(() => {
        const targetPublication = document.querySelector(`#${targetItem}`);

        if (targetPublication) {
          targetPublication.scrollIntoView({ behavior: "auto", block: "start" });
        } else {
          setNumberOfShowArticle(numberOfArticle);
          dispatch(setTargetItem({ index: null, item: null }));
        }
      }, 500);
    }
  }, []);

  const setFilterCriterion = (label, filterType) => {
    const indexOfRemove = filterType.indexOf(label);

    if (indexOfRemove !== -1) {
      filterType.splice(indexOfRemove, 1);
    } else {
      filterType.push(label);
    }
  };

  const applyFilter = () => {
    dispatch(
      setFilterCriterions({
        key: "publication",
        value: {
          criterionCategory: [...filterCriterionsCategory],
          criterionJournal: [...filterCriterionsJournal],
          criterionYear: [...filterCriterionsYear],
        },
      }),
    );

    setShowDropdownYear(false);
    setShowDropdownJournal(false);
    setShowDropdownCategory(false);
  };

  const filterChildren = () => {
    let articles = children;

    if (filterCriterionsPublication.criterionCategory.length > 0
      || filterCriterionsPublication.criterionYear.length > 0
      || filterCriterionsPublication.criterionJournal.length > 0) {
      if (filterCriterionsPublication.criterionCategory.length > 0) {
        articles = articles.filter((child) => (
          filterCriterionsPublication.criterionCategory.indexOf(child.props.category) !== -1
        ));
      }

      if (filterCriterionsPublication.criterionYear.length > 0) {
        articles = articles.filter((child) => {
          if (!child.props.date) {
            return false;
          }

          return filterCriterionsPublication.criterionYear.indexOf(
            getYear(dateFactory(child.props.date)),
          ) !== -1;
        });
      }

      if (filterCriterionsPublication.criterionJournal.length > 0) {
        articles = articles.filter((child) => (
          filterCriterionsPublication.criterionJournal.indexOf(child.props.journal) !== -1
        ));
      }
    } else {
      articles = children;
    }

    articles = articles.filter((child) => (
      (child.props.author !== null
        && child.props.author.toLowerCase().includes(filterTextPublication.toLowerCase()))
      || (child.props.title !== null
        && child.props.title.toLowerCase().includes(filterTextPublication.toLowerCase()))
      || (child.props.journal !== null
        && child.props.journal.toLowerCase().includes(filterTextPublication.toLowerCase()))
      || (child.props.source !== null
        && child.props.source.toLowerCase().includes(filterTextPublication.toLowerCase()))
    ));

    return articles;
  };

  const articles = filterChildren();

  return (
    <div className="publication-section">
      {headline && (
        <div className="publication-section__headline">
          {headline}
        </div>
      )}

      <InputGroup className="prepend large">
        <Form.Control
          size="lg"
          placeholder={intl.formatMessage({ id: "publication_section.search_for_keywords" })}
          onChange={(event) => {
            dispatch(
              setFilterText({ key: "publication", value: event.target.value }),
            );
          }}
          value={filterTextPublication}
          className="typo-h4"
        />
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch24 />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {filterTextPublication && filterTextPublication !== "" && (
          <button
            type="button"
            className="btn-clean"
            onClick={() => {
              dispatch(
                setFilterText({ key: "publication", value: "" }),
              );
            }}
          >
            <IconDeleteInput18 />
          </button>
        )}
      </InputGroup>

      <div>
        <LinkButton
          className="publication-section__filter-button"
          onClick={() => {
            setShowDropdownCategory(false);
            setShowDropdownJournal(false);
            setShowDropdownYear(!showDropdownYear);
          }}
        >
          <IconFilter24 /><FormattedMessage id="publication_section.year" />
        </LinkButton>
        <LinkButton
          className="publication-section__filter-button"
          onClick={() => {
            setShowDropdownCategory(!showDropdownCategory);
            setShowDropdownJournal(false);
            setShowDropdownYear(false);
          }}
        >
          <IconFilter24 /> <FormattedMessage id="publication_section.category" />
        </LinkButton>
        <LinkButton
          className="publication-section__filter-button"
          onClick={() => {
            setShowDropdownCategory(false);
            setShowDropdownJournal(!showDropdownJournal);
            setShowDropdownYear(false);
          }}
        >
          <IconFilter24 /> <FormattedMessage id="publication_section.journal" />
        </LinkButton>
        <div className="d-flex flex-wrap">
          {filterCriterionsPublication.criterionYear.length > 0 && (
            filterCriterionsPublication.criterionYear.map((tag) => (
              <FilterTag
                key={tag}
                onClick={() => {
                  setFilterCriterion(tag, filterCriterionsYear);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))
          )}

          {filterCriterionsPublication.criterionCategory.length > 0 && (
            filterCriterionsPublication.criterionCategory.map((tag) => (
              <FilterTag
                key={tag}
                onClick={() => {
                  setFilterCriterion(tag, filterCriterionsCategory);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))
          )}

          {filterCriterionsPublication.criterionJournal.length > 0 && (
            filterCriterionsPublication.criterionJournal.map((tag) => (
              <FilterTag
                key={tag}
                onClick={() => {
                  setFilterCriterion(tag, filterCriterionsJournal);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))
          )}
        </div>
      </div>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownYear })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage id="publication_section.types_selected" values={{ count: filterCriterionsYear.length }} />
            </div>
            <div className="publication-section__label-container">
              {labelsYear.map((label) => (
                <div
                  className={classNames("publication-section__label", (filterCriterionsYear.indexOf(label) !== -1) && "active")}
                  key={label}
                  onClick={() => {
                    setFilterCriterion(label, filterCriterionsYear);
                    setRefresh(!refresh);
                  }}
                  role="presentation"
                >
                  {label} {
                      (filterCriterionsYear.indexOf(label) !== -1)
                        ? <IconSelectBlue24 />
                        : <IconSelectEmpty24 />
                    }
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {filterCriterionsYear.length > 0
                ? (
                  <LinkButton
                    onClick={() => {
                      filterCriterionsYear.splice(0, filterCriterionsYear.length - 1);
                      setRefresh(!refresh);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.reset" />
                  </LinkButton>
                )
                : (
                  <LinkButton
                    onClick={() => {
                      setShowDropdownYear(false);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.cancel" />
                  </LinkButton>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownCategory })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage id="publication_section.types_selected" values={{ count: filterCriterionsCategory.length }} />
            </div>
            <div className="publication-section__label-container">
              {labelsCategory.map((label) => (
                <div
                  className={classNames("publication-section__label", (filterCriterionsCategory.indexOf(label) !== -1) && "active")}
                  key={label}
                  onClick={() => {
                    setFilterCriterion(label, filterCriterionsCategory);
                    setRefresh(!refresh);
                  }}
                  role="presentation"
                >
                  {label} {
                  (filterCriterionsCategory.indexOf(label) !== -1)
                    ? <IconSelectBlue24 />
                    : <IconSelectEmpty24 />
                }
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {filterCriterionsCategory.length > 0
                ? (
                  <LinkButton
                    onClick={() => {
                      filterCriterionsCategory.splice(0, filterCriterionsCategory.length - 1);
                      setRefresh(!refresh);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.reset" />
                  </LinkButton>
                )
                : (
                  <LinkButton
                    onClick={() => {
                      setShowDropdownCategory(false);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.cancel" />
                  </LinkButton>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownJournal })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage id="publication_section.types_selected" values={{ count: filterCriterionsJournal.length }} />
            </div>
            <div className="publication-section__label-container">
              {labelsJournal.map((label) => (
                <div
                  className={classNames("publication-section__label", (filterCriterionsJournal.indexOf(label) !== -1) && "active")}
                  key={label}
                  onClick={() => {
                    setFilterCriterion(label, filterCriterionsJournal);
                    setRefresh(!refresh);
                  }}
                  role="presentation"
                >
                  {label} {
                  (filterCriterionsJournal.indexOf(label) !== -1)
                    ? <IconSelectBlue24 />
                    : <IconSelectEmpty24 />
                }
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {filterCriterionsJournal.length > 0
                ? (
                  <LinkButton
                    onClick={() => {
                      filterCriterionsJournal.splice(0, filterCriterionsJournal.length - 1);
                      setRefresh(!refresh);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.reset" />
                  </LinkButton>
                )
                : (
                  <LinkButton
                    onClick={() => {
                      setShowDropdownJournal(false);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="publication_section.cancel" />
                  </LinkButton>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <div className="publication-section__article-container">
        {articles.map((child, index) => {
          if (index < numberOfShowArticle) {
            const mainIndex = children.indexOf(child);

            return (
              <div
                key={child.props.key}
                id={`Publication${mainIndex}`}
                onClick={() => {
                  dispatch(setTargetItem({ index, item: `Publication${mainIndex}` }));
                }}
                role="presentation"
              >
                {child}
              </div>
            );
          }

          return null;
        })}
      </div>
      {articles.length > numberOfArticle && (
        <div className="publication-section__button-container">
          {numberOfShowArticle < articles.length
            ? (
              <IconButton
                variant="none"
                className="publication-section__pagination"
                onClick={() => {
                  setNumberOfShowArticle(numberOfShowArticle + numberOfArticle);
                }}
              >
                <FormattedMessage id="publication_section.show_more" /> <IconAccordionExpand18 />
              </IconButton>
            )
            : (
              <IconButton
                variant="none"
                className="publication-section__pagination"
                onClick={() => {
                  setNumberOfShowArticle(numberOfArticle);
                }}
              >
                <FormattedMessage id="publication_section.show_less" /> <IconAccordionMinimize18 />
              </IconButton>
            )}
        </div>
      )}
    </div>
  );
};

PublicationSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string,
};

PublicationSection.defaultProps = {
  headline: null,
};

export default PublicationSection;
