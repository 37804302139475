import React from "react";

import PropTypes from "prop-types";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

import SimpleRichText from "../../../templates/text/simpleRichText";

const DynamicTooltip = (props) => {
  const {
    tooltipId,
    target,
    show,
    placement,
    tooltipMessage,
  } = props;

  return (
    <Overlay target={target} show={show} placement={placement}>
      <Tooltip id={tooltipId}>
        <SimpleRichText data={{ value: tooltipMessage }} />
      </Tooltip>
    </Overlay>
  );
};

DynamicTooltip.propTypes = {
  placement: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  target: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
      ]),
    }),
  ]).isRequired,
  tooltipId: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
};

export default DynamicTooltip;
