import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./circularIcon.scss";

const CircularIcon = ({ image, className }) => (
  <div className={classNames("circular-icon", className)}>
    <div className="circular-icon__inner">
      {image}
    </div>
  </div>
);

CircularIcon.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

CircularIcon.defaultProps = {
  className: null,
  image: null,
};

export default CircularIcon;
