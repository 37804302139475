import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import PublicationCategoryCards from "./publications/publicationCategoryCards";
import LinkButton from "../components/button/linkButton";
import Section from "../components/section/section";
import { IconFurther24 } from "../icons";

const KontentItemPublicationsSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  const categories = [];

  section.elements.categories.value.forEach((category) => {
    categories.push(category.codename);
  });

  return (
    <Section
      headline={section.elements.headline.value}
      button={section.elements.button_url.value.length > 0
        ? (
          <LinkButton href={section.elements.button_url.value[0].id} className="mt-5">
            <FormattedMessage id="publication.view_all" />
            <IconFurther24 />
          </LinkButton>
        )
        : null}
      isFullWidthChild
    >
      {PublicationCategoryCards({ categories })}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemPublicationsSection on kontent_item_publications_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      categories {
        value {
          codename
          name
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemPublicationsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPublicationsSection;
