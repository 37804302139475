import React from "react";

import PropTypes from "prop-types";

import LinkButton from "../button/linkButton";

import "./sidebarMenu.scss";

const SidebarMenu = ({ items }) => {
  const itemss = items || [
    {
      id: 1,
      item: (<LinkButton>Menu 1</LinkButton>),
      subitems: [
        {
          id: 11,
          item: (<LinkButton>Menu 1.1</LinkButton>),
        },
        {
          id: 12,
          item: (<LinkButton>Menu 1.2</LinkButton>),
        },
      ],
    },
    {
      id: 2,
      item: (<LinkButton>Menu 2</LinkButton>),
      subitems: [
        {
          id: 21,
          item: (<LinkButton>Menu 2.1</LinkButton>),
        },
        {
          id: 22,
          item: (<LinkButton>Menu 2.2</LinkButton>),
        },
      ],
    },
  ];

  return (
    <ul className="sidebar-menu">
      {
        itemss.map((item) => (
          <li key={item.id}>
            {item.item}
            {item.subitems && item.subitems.length > 0 && (
              <ul className="sidebar-menu__level-2">
                {item.subitems.map((subitem) => (
                  <li key={subitem.id}>
                    {subitem.item}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))
      }
    </ul>
  );
};

SidebarMenu.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]),
};

SidebarMenu.defaultProps = {
  items: null,
};

export default SidebarMenu;
