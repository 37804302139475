import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import _ from "lodash";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import NavigationLink from "./navigationLink";
import iconHelper from "../../utils/iconHelper";
import { getCurrentLanguage } from "../../utils/navigation";
import LinkButton from "../button/linkButton";
import FooterSection from "../footer/footerSection";

const FooterNavigation = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      footerNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "footer_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                typename: __typename
                preferred_language
                ... on kontent_item_navigation_section {
                  id,
                  elements {
                    title {
                      value
                    }
                    sub_items {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      infoNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "info_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      typename: __typename
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            secondaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "secondary_navigation"}}}) {
              nodes {
                id
                elements {
                  elements {
                    value {
                      id
                      preferred_language
                      ... on kontent_item_navigation_item {
                        id,
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                          icon {
                            value {
                              codename
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
    }
  `);

  const footerNavigation = [];

  if (data.footerNavigation) {
    let nav = getCurrentLanguage(data.footerNavigation.nodes, intl.locale);

    // todo: change in testsystem
    if (_.isEmpty(nav)) {
      nav = getCurrentLanguage(data.footerNavigation.nodes, "default");
    }

    if (nav) {
      nav.elements.elements.value.forEach((item) => {
        footerNavigation.push((
          <React.Fragment key={item.elements.title.value}>
            <Col lg={3}>
              <FooterSection sectionName={item.elements.title.value}>
                <div className="nav">
                  {item.elements.sub_items.value.map((link) => (
                    <NavigationLink {...link} key={link.id} className="nav-link" />
                  ))}
                </div>
              </FooterSection>
            </Col>
          </React.Fragment>
        ));
      });

      if (nav.elements.elements.value.length < 3) {
        for (let i = nav.elements.elements.value.length; i <= 3; i += 1) {
          footerNavigation.push((
            <Col lg={3} />
          ));
        }
      }
    }

    if (data.secondaryNavigation) {
      footerNavigation.push((
        <React.Fragment key="menuinfonavigation1">
          <Col xs={12} lg={3} className="footer-menu__quicklinks">
            <Row>
              {data.secondaryNavigation
              && data.secondaryNavigation.nodes[0].elements.elements.value.map((link) => {
                if (!link.id || !link.elements.icon) return null;

                return (
                  <Col key={link.elements.title.value} xs={12} className="footer-section burger-navigation">
                    <LinkButton
                      href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                      className="footer-section__header burger-navigation__nav-direct-link footer-menu__info--padding"
                      variant="normal"
                    >
                      {link.elements.icon.value.length > 0
                        && iconHelper(link.elements.icon.value[0].codename)}
                      {link.elements.title.value}
                    </LinkButton>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </React.Fragment>
      ));
    }
  }

  return footerNavigation;
};

export default FooterNavigation;
