export { default as IconAccordionExpand18 } from "./icon/18/a-icon-accordeon-expand-18.svg";
export { default as IconAccordionMinimize18 } from "./icon/18/a-icon-accordeon-minimize-18.svg";
export { default as IconAnchorLeft18 } from "./icon/18/a-icon-anchor-left-18.svg";
export { default as IconAnchorRight18 } from "./icon/18/a-icon-anchor-right-18.svg";
export { default as IconArticle18 } from "./icon/18/a-icon-article-18.svg";
export { default as IconAuthor18 } from "./icon/18/a-icon-author-18.svg";
export { default as IconBacteria18 } from "./icon/18/a-icon-bacteria-18.svg";
export { default as IconBigHome18 } from "./icon/18/a-icon-bighome-18.svg";
export { default as IconBulletPoints18 } from "./icon/18/a-icon-bulletpoint-18.svg";
export { default as IconCalendar18 } from "./icon/18/a-icon-calendar-18.svg";
export { default as IconCalendarArchive18 } from "./icon/18/a-icon-calendararchive-18.svg";
export { default as IconCheck18 } from "./icon/18/a-icon-check-18.svg";
export { default as IconCheckbox18 } from "./icon/18/a-icon-checkbox-18.svg";
export { default as IconCheckboxFilled18 } from "./icon/18/a-icon-checkbox-filled-18.svg";
export { default as IconClearInput18 } from "./icon/18/a-icon-clearinput-18.svg";
export { default as IconClose18 } from "./icon/18/a-icon-close-18.svg";
export { default as IconDefault18 } from "./icon/18/a-icon-default-18.svg";
export { default as IconDelete18 } from "./icon/18/a-icon-delete-18.svg";
export { default as IconDeleteInput18 } from "./icon/18/a-icon-deleteinput-18.svg";
export { default as IconDirectory18 } from "./icon/18/a-icon-directory-18.svg";
export { default as IconDollar18 } from "./icon/18/a-icon-dollar-18.svg";
export { default as IconDonate18 } from "./icon/18/a-icon-donate-18.svg";
export { default as IconDownload18 } from "./icon/18/a-icon-download-18.svg";
export { default as IconDropdownExpand18 } from "./icon/18/a-icon-dropdown-expand-18.svg";
export { default as IconDropdownMinimize18 } from "./icon/18/a-icon-dropdown-minimize-18.svg";
export { default as IconError18 } from "./icon/18/a-icon-error-18.svg";
export { default as IconEuro18 } from "./icon/18/a-icon-euro-18.svg";
export { default as IconExternalLink18 } from "./icon/18/a-icon-externallink-18.svg";
export { default as IconFax18 } from "./icon/18/a-icon-fax-18.svg";
export { default as IconFold18 } from "./icon/18/a-icon-fold-18.svg";
export { default as IconFurther18 } from "./icon/18/a-icon-further-18.svg";
export { default as IconHelp18 } from "./icon/18/a-icon-help-18.svg";
export { default as IconHome18 } from "./icon/18/a-icon-home-18.svg";
export { default as IconJpg18 } from "./icon/18/a-icon-jpg-18.svg";
export { default as IconLearnMore18 } from "./icon/18/a-icon-learnmore-18.svg";
export { default as IconLocation18 } from "./icon/18/a-icon-location-18.svg";
export { default as IconMail18 } from "./icon/18/a-icon-mail-18.svg";
export { default as IconMenu18 } from "./icon/18/a-icon-menu-18.svg";
export { default as IconMp418 } from "./icon/18/a-icon-mp4-18.svg";
export { default as IconPath18 } from "./icon/18/a-icon-path-18.svg";
export { default as IconPathBack18 } from "./icon/18/a-icon-pathback-18.svg";
export { default as IconPdf18 } from "./icon/18/a-icon-pdf-18.svg";
export { default as IconPounds18 } from "./icon/18/a-icon-pounds-18.svg";
export { default as IconPhone18 } from "./icon/18/a-icon-phone-18.svg";
export { default as IconRadioButton18 } from "./icon/18/a-icon-radiobutton-18.svg";
export { default as IconRadioButtonFilled18 } from "./icon/18/a-icon-radiobutton-filled-18.svg";
export { default as IconRupie18 } from "./icon/18/a-icon-rupie-18.svg";
export { default as IconSearch18 } from "./icon/18/a-icon-search-18.svg";
export { default as IconSearchTeaser18 } from "./icon/18/a-icon-searchteaser-18.svg";
export { default as IconSubmit18 } from "./icon/18/a-icon-submit-18.svg";
export { default as IconStudy18 } from "./icon/18/a-icon-study-18.svg";
export { default as IconTime18 } from "./icon/18/a-icon-time-18.svg";
export { default as IconTopic18 } from "./icon/18/a-icon-topic-18.svg";
export { default as IconWebForm18 } from "./icon/18/a-icon-webform-18.svg";
export { default as IconZip18 } from "./icon/18/a-icon-zip-18.svg";
export { default as IconZloty18 } from "./icon/18/a-icon-zloty-18.svg";
export { default as IconMaps18 } from "./icon/18/a-icon-maps-18.svg";
export { default as IconFamily18 } from "./icon/18/a-icon-family-18.svg";
export { default as IconTargetGroups18 } from "./icon/18/a-icon-targetgroups-18.svg";
export { default as IconNews18 } from "./icon/18/a-icon-news-18.svg";
export { default as IconDkmsTool18 } from "./icon/18/a-icon-dkmstools-18.svg";
export { default as IconConsentManagement18 } from "./icon/18/a-icon-consentmanagement-18.svg";
export { default as IconAnchorRightWhite18 } from "./icon/18/a-icon-anchor-right-white-18.svg";
export { default as IconAnchorLeftWhite18 } from "./icon/18/a-icon-anchor-left-white-18.svg";
export { default as IconPrivate18 } from "./icon/18/a-icon-private-18.svg";
export { default as IconStandard18 } from "./icon/18/a-icon-standard-18.svg";
export { default as IconFactSheet18 } from "./icon/18/a-icon-factsheet-18.svg";
export { default as IconDkmsServices18 } from "./icon/18/a-icon-dkmsservices-18.svg";
export { default as IconShare18 } from "./icon/18/a-icon-share-18.svg";
export { default as IconPartnerTool18 } from "./icon/18/a-icon-partnertool-18.svg";
export { default as IconSoftware18 } from "./icon/18/a-icon-software-18.svg";
export { default as IconDataSet18 } from "./icon/18/a-icon-dataset-18.svg";
export { default as IconWebsite18 } from "./icon/18/a-icon-web-18.svg";
export { default as IconZapper18 } from "./icon/18/a-icon-zapper-18.svg";
export { default as IconSnapscan18 } from "./icon/18/a-icon-snapscan-18.svg";
export { default as IconRand18 } from "./icon/18/a-icon-rand-18.svg";
export { default as IconCareers18 } from "./icon/18/a-icon-careers-18.svg";

export { default as IconAdd24 } from "./icon/24/a-icon-add-24.svg";
export { default as IconTextFile24 } from "./icon/24/a-icon-textfile-24.svg";
export { default as IconAnchorRight24 } from "./icon/24/a-icon-anchorright-24.svg";
export { default as IconCalendar24 } from "./icon/24/a-icon-calendar-24.svg";
export { default as IconCalendarArchive24 } from "./icon/24/a-icon-calendararchive-24.svg";
export { default as IconCheck24 } from "./icon/24/a-icon-check-24.svg";
export { default as IconClose24 } from "./icon/24/a-icon-close-24.svg";
export { default as IconDefault24 } from "./icon/24/a-icon-default-24.svg";
export { default as IconDonate24 } from "./icon/24/a-icon-donate-24.svg";
export { default as IconDownload24 } from "./icon/24/a-icon-download-24.svg";
export { default as IconDrag24 } from "./icon/24/a-icon-drag-24.svg";
export { default as IconDropdownExpand24 } from "./icon/24/a-icon-dropdown-expand-24.svg";
export { default as IconExpand24 } from "./icon/24/a-icon-expand-24.svg";
export { default as IconExternalLink24 } from "./icon/24/a-icon-externallink-24.svg";
export { default as IconFilter24 } from "./icon/24/a-icon-filter-24.svg";
export { default as IconFilterApplied24 } from "./icon/24/a-icon-filterapplied-24.svg";
export { default as IconFurther24 } from "./icon/24/a-icon-further-24.svg";
export { default as IconHelp24 } from "./icon/24/a-icon-help-24.svg";
export { default as IconHideHelp24 } from "./icon/24/a-icon-hidehelp-24.svg";
export { default as IconInfo24 } from "./icon/24/a-icon-info-24.svg";
export { default as IconList24 } from "./icon/24/a-icon-list-24.svg";
export { default as IconLocation24 } from "./icon/24/a-icon-location-24.svg";
export { default as IconMail24 } from "./icon/24/a-icon-mail-24.svg";
export { default as IconMap24 } from "./icon/24/a-icon-map-24.svg";
export { default as IconMessage24 } from "./icon/24/a-icon-message-24.svg";
export { default as IconMinimize24 } from "./icon/24/a-icon-minimize-24.svg";
export { default as IconPhone24 } from "./icon/24/a-icon-phone-24.svg";
export { default as IconSearch24 } from "./icon/24/a-icon-search-24.svg";
export { default as IconSelect24 } from "./icon/24/a-icon-select-24.svg";
export { default as IconSelectEmpty24 } from "./icon/24/a-icon-selectempty-24.svg";
export { default as IconSubmit24 } from "./icon/24/a-icon-submit-24.svg";
export { default as IconSuccess24 } from "./icon/24/a-icon-success-24.svg";
export { default as IconTargetGroupAssociations24 } from "./icon/24/a-icon-targetgroup-associations-24.svg";
export { default as IconTargetGroupClub24 } from "./icon/24/a-icon-targetgroup-club-24.svg";
export { default as IconTargetGroupCompanies24 } from "./icon/24/a-icon-targetgroup-companies-24.svg";
export { default as IconTargetGroupCompany24 } from "./icon/24/a-icon-targetgroup-company-24.svg";
export { default as IconTargetGroupDoctors24 } from "./icon/24/a-icon-targetgroup-doctors-24.svg";
export { default as IconTargetGroupEducators24 } from "./icon/24/a-icon-targetgroup-educators-24.svg";
export { default as IconTargetGroupParents24 } from "./icon/24/a-icon-targetgroup-parents-24.svg";
export { default as IconTargetGroupSchool24 } from "./icon/24/a-icon-targetgroup-school-24.svg";
export { default as IconTargetGroupSchools24 } from "./icon/24/a-icon-targetgroup-schools-24.svg";
export { default as IconTargetGroupSportsClubs24 } from "./icon/24/a-icon-targetgroup-sportsclubs-24.svg";
export { default as IconTargetGroupStudents24 } from "./icon/24/a-icon-targetgroup-students-24.svg";
export { default as IconTargetGroupVolunteers24 } from "./icon/24/a-icon-targetgroup-volunteers-24.svg";
export { default as IconTime24 } from "./icon/24/a-icon-time-24.svg";
export { default as IconUndo24 } from "./icon/24/a-icon-undo-24.svg";
export { default as IconUpdate24 } from "./icon/24/a-icon-update-24.svg";
export { default as IconWarning24 } from "./icon/24/a-icon-warning-24.svg";
export { default as IconWebForm24 } from "./icon/24/a-icon-webform-24.svg";
export { default as IconZoom24 } from "./icon/24/a-icon-zoom-24.svg";
export { default as IconFamily24 } from "./icon/24/a-icon-family-24.svg";
export { default as IconLink24 } from "./icon/24/a-icon-link-24.svg";
export { default as IconPdf24 } from "./icon/24/a-icon-pdf-24.svg";
export { default as IconSelectBlue24 } from "./icon/24/a-icon-select-blue-24.svg";
export { default as IconRetry24 } from "./icon/24/a-icon-retry-24.svg";
export { default as IconAccessibility24 } from "./icon/24/a-icon-accessibility-24.svg";
export { default as IconFillForm24 } from "./icon/24/a-icon-fillform-24.svg";
export { default as IconCreditCard24 } from "./icon/24/a-icon-credit-card.svg";

export { default as IconAddDonor30 } from "./icon/30/a-icon-adddonor-30.svg";
export { default as IconBloodSample30 } from "./icon/30/a-icon-bloodsample-30.svg";
export { default as IconBoneMarrowCollection30 } from "./icon/30/a-icon-bonemarrowcollection-30.svg";
export { default as IconCar30 } from "./icon/30/a-icon-car-30.svg";
export { default as IconCharity30 } from "./icon/30/a-icon-charity-30.svg";
export { default as IconCharityEvent30 } from "./icon/30/a-icon-charityevent-30.svg";
export { default as IconCheckUp30 } from "./icon/30/a-icon-checkup-30.svg";
export { default as IconChildren30 } from "./icon/30/a-icon-children-30.svg";
export { default as IconConfirmatoryPing30 } from "./icon/30/a-icon-confirmatorytyping-30.svg";
export { default as IconCooperation30 } from "./icon/30/a-icon-cooperation-30.svg";
export { default as IconDefault30 } from "./icon/30/a-icon-default-30.svg";
export { default as IconDnaHla30 } from "./icon/30/a-icon-dnahla-30.svg";
export { default as IconDonateDe30 } from "./icon/30/a-icon-donate-de-30.svg";
export { default as IconDonateIn30 } from "./icon/30/a-icon-donate-in-30.svg";
export { default as IconDonatePl30 } from "./icon/30/a-icon-donate-pl-30.svg";
export { default as IconDonateUk30 } from "./icon/30/a-icon-donate-uk-30.svg";
export { default as IconDonateUsCl30 } from "./icon/30/a-icon-donate-uscl-30.svg";
export { default as IconDonor30 } from "./icon/30/a-icon-donor-30.svg";
export { default as IconDonorDrive30 } from "./icon/30/a-icon-donordrive-30.svg";
export { default as IconDonorPatientMeeting30 } from "./icon/30/a-icon-donorpatientmeeting-30.svg";
export { default as IconEvent30 } from "./icon/30/a-icon-event-30.svg";
export { default as IconEventHosting30 } from "./icon/30/a-icon-eventhosting-30.svg";
export { default as IconFamilySearch30 } from "./icon/30/a-icon-familysearch-30.svg";
export { default as IconFaq30 } from "./icon/30/a-icon-faq-30.svg";
export { default as IconFilingOrganisation30 } from "./icon/30/a-icon-filingorganisation-30.svg";
export { default as IconFounding30 } from "./icon/30/a-icon-founding-30.svg";
export { default as IconGlobalDonors30 } from "./icon/30/a-icon-globaldonors-30.svg";
export { default as IconGlobalSearch30 } from "./icon/30/a-icon-globalsearch-30.svg";
export { default as IconHappyDonor30 } from "./icon/30/a-icon-happydonor-30.svg";
export { default as IconHappyPatient30 } from "./icon/30/a-icon-happypatient-30.svg";
export { default as IconHouse30 } from "./icon/30/a-icon-house-30.svg";
export { default as IconIdmRequest30 } from "./icon/30/a-icon-idmrequest-30.svg";
export { default as IconImportant30 } from "./icon/30/a-icon-important-30.svg";
export { default as IconInfluencer30 } from "./icon/30/a-icon-influencer-30.svg";
export { default as IconLocalDonors30 } from "./icon/30/a-icon-localdonors-30.svg";
export { default as IconMatch30 } from "./icon/30/a-icon-match-30.svg";
export { default as IconMediaClue30 } from "./icon/30/a-icon-mediaclue-30.svg";
export { default as IconNews30 } from "./icon/30/a-icon-news-30.svg";
export { default as IconNotification30 } from "./icon/30/a-icon-notification-30.svg";
export { default as IconOnlineRegistration30 } from "./icon/30/a-icon-onlineregistration-30.svg";
export { default as IconPaperWork30 } from "./icon/30/a-icon-paperwork-30.svg";
export { default as IconPatient30 } from "./icon/30/a-icon-patient-30.svg";
export { default as IconPreparationDli30 } from "./icon/30/a-icon-preparationdli-30.svg";
export { default as IconRadio30 } from "./icon/30/a-icon-radio-30.svg";
export { default as IconRegistration30 } from "./icon/30/a-icon-registration-30.svg";
export { default as IconRegistrationCosts30 } from "./icon/30/a-icon-registrationcosts-30.svg";
export { default as IconRegistrationViaBlood30 } from "./icon/30/a-icon-registrationviablood-30.svg";
export { default as IconRegistryEntry30 } from "./icon/30/a-icon-registryentry-30.svg";
export { default as IconSadPatient30 } from "./icon/30/a-icon-sadpatient-30.svg";
export { default as IconSendingBack30 } from "./icon/30/a-icon-sendingback-30.svg";
export { default as IconStemCellCollection30 } from "./icon/30/a-icon-stemcellcollection-30.svg";
export { default as IconStop30 } from "./icon/30/a-icon-stop-30.svg";
export { default as IconSwabbing30 } from "./icon/30/a-icon-swabbing-30.svg";
export { default as IconThumbsUp30 } from "./icon/30/a-icon-thumbsup-30.svg";
export { default as IconTick30 } from "./icon/30/a-icon-tick-30.svg";
export { default as IconTime30 } from "./icon/30/a-icon-time-30.svg";
export { default as IconTransplantation30 } from "./icon/30/a-icon-transplantation-30.svg";
export { default as IconUpdates30 } from "./icon/30/a-icon-updates-30.svg";
export { default as IconUserStory30 } from "./icon/30/a-icon-userstory-30.svg";
export { default as IconVerbalContact30 } from "./icon/30/a-icon-verbalcontact-30.svg";
export { default as IconVirtualDrive30 } from "./icon/30/a-icon-virtualdrive-30.svg";
export { default as IconWorkUp30 } from "./icon/30/a-icon-workup-30.svg";
export { default as IconWrittenContact30 } from "./icon/30/a-icon-writtencontact-30.svg";
export { default as IconLaunch30 } from "./icon/30/a-icon-launch-30.svg";
export { default as IconInfo30 } from "./icon/30/a-icon-info-30.svg";
export { default as IconTickGreen30 } from "./icon/30/a-icon-tick-green-30.svg";

export { default as IconCooperation48 } from "./icon/48/a-icon-cooperation-48.svg";
export { default as IconDefault48 } from "./icon/48/a-icon-default-48.svg";
export { default as IconDnaHla48 } from "./icon/48/a-icon-dnahla-48.svg";
export { default as IconDualToneAssets48 } from "./icon/48/a-icon-dualtone-assets-48.svg";
export { default as IconDualToneAssociations48 } from "./icon/48/a-icon-dualtone-associations-48.svg";
export { default as IconDualToneCharityEvent48 } from "./icon/48/a-icon-dualtone-charityevent-48.svg";
export { default as IconDualToneCompanies48 } from "./icon/48/a-icon-dualtone-companies-48.svg";
export { default as IconDualToneDoctors48 } from "./icon/48/a-icon-dualtone-doctors-48.svg";
export { default as IconDualToneDonateDe48 } from "./icon/48/a-icon-dualtone-donate-de-48.svg";
export { default as IconDualToneDonateIn48 } from "./icon/48/a-icon-dualtone-donate-in-48.svg";
export { default as IconDualToneDonatePl48 } from "./icon/48/a-icon-dualtone-donate-pl-48.svg";
export { default as IconDualToneDonateUk48 } from "./icon/48/a-icon-dualtone-donate-uk-48.svg";
export { default as IconDualToneDonateAfr48 } from "./icon/48/a-icon-dualtone-donate-afr-48.svg";
export { default as IconDualToneDonateUsCl48 } from "./icon/48/a-icon-dualtone-donate-uscl-48.svg";
export { default as IconDualToneDonorDrive48 } from "./icon/48/a-icon-dualtone-donordrive-48.svg";
export { default as IconDualToneEducators48 } from "./icon/48/a-icon-dualtone-educators-48.svg";
export { default as IconDualToneEvent48 } from "./icon/48/a-icon-dualtone-event-48.svg";
export { default as IconDualToneEventHosting48 } from "./icon/48/a-icon-dualtone-eventhosting-48.svg";
export { default as IconDualToneFactSheet48 } from "./icon/48/a-icon-dualtone-factsheet-48.svg";
export { default as IconDualToneParents48 } from "./icon/48/a-icon-dualtone-parents-48.svg";
export { default as IconDualTonePublication48 } from "./icon/48/a-icon-dualtone-publication-48.svg";
export { default as IconDualToneRegister48 } from "./icon/48/a-icon-dualtone-register-48.svg";
export { default as IconDualToneSchools48 } from "./icon/48/a-icon-dualtone-schools-48.svg";
export { default as IconDualToneStudy48 } from "./icon/48/a-icon-dualtone-study-48.svg";
export { default as IconDualToneSportsClubs48 } from "./icon/48/a-icon-dualtone-sportsclubs-48.svg";
export { default as IconDualToneStudents48 } from "./icon/48/a-icon-dualtone-students-48.svg";
export { default as IconDualToneVirtualDrive48 } from "./icon/48/a-icon-dualtone-virtualdrive-48.svg";
export { default as IconDualToneVolunteers48 } from "./icon/48/a-icon-dualtone-volunteers-48.svg";
export { default as IconEvent48 } from "./icon/48/a-icon-event-48.svg";
export { default as IconFounding48 } from "./icon/48/a-icon-founding-48.svg";
export { default as IconGobalDonors48 } from "./icon/48/a-icon-gobaldonors-48.svg";
export { default as IconHappyPatient48 } from "./icon/48/a-icon-happypatient-48.svg";
export { default as IconLocalDonors48 } from "./icon/48/a-icon-localdonors-48.svg";
export { default as IconMatch48 } from "./icon/48/a-icon-match-48.svg";
export { default as IconPlay48 } from "./icon/48/a-icon-play-48.svg";
export { default as IconRegistration48 } from "./icon/48/a-icon-registration-48.svg";
export { default as IconRegistrationCosts48 } from "./icon/48/a-icon-registrationcosts-48.svg";
export { default as IconRegistryEntry48 } from "./icon/48/a-icon-registryentry-48.svg";
export { default as IconStemCellCollection48 } from "./icon/48/a-icon-stemcellcollection-48.svg";
export { default as IconSwabbing48 } from "./icon/48/a-icon-swabbing-48.svg";
export { default as IconTransplantation48 } from "./icon/48/a-icon-transplantation-48.svg";
export { default as IconDualtoneNewbadge48 } from "./icon/48/a-icon-dualtone-newbadge-48.svg";
export { default as IconDualToneWebform48 } from "./icon/48/a-icon-dualtone-webform-48.svg";
export { default as IconDualToneFactsFigures48 } from "./icon/48/a-icon-dualtone-factsfigures-48.svg";
export { default as IconDualToneStandards48 } from "./icon/48/a-icon-dualtone-standards-48.svg";
export { default as IconDualToneCollaboration48 } from "./icon/48/a-icon-dualtone-collaboration-48.svg";
export { default as IconDualTonePricing48 } from "./icon/48/a-icon-dualtone-pricing-48.svg";
export { default as IconDualToneDepartments48 } from "./icon/48/a-icon-dualtone-departments-48.svg";
export { default as IconDualToneSupportPrograms48 } from "./icon/48/a-icon-dualtone-supportprograms-48.svg";
export { default as IconDualToneResources48 } from "./icon/48/a-icon-dualtone-resources-48.svg";
export { default as IconDualToneResearchScope48 } from "./icon/48/a-icon-dualtone-researchscope-48.svg";
export { default as IconDualToneResearchFunding48 } from "./icon/48/a-icon-dualtone-researchfunding-48.svg";
export { default as IconDualToneTools48 } from "./icon/48/a-icon-dualtone-tools-48.svg";
export { default as IconDualToneServices48 } from "./icon/48/a-icon-dualtone-services-48.svg";
export { default as IconDualToneGlossary48 } from "./icon/48/a-icon-dualtone-glossary-48.svg";
export { default as IconDualToneDataProtection48 } from "./icon/48/a-icon-dualtone-dataprotection-48.svg";
export { default as IconDualToneEarth48 } from "./icon/48/a-icon-dualtone-earth-48.svg";
export { default as IconDualToneBackgroundEarth48 } from "./icon/48/a-icon-dualtone-backgroundearth-48.svg";
export { default as IconDualToneDonorSuccess48 } from "./icon/48/a-icon-dualtone-donorsuccess-48.svg";
export { default as IconDualToneCooperation48 } from "./icon/48/a-icon-dualtone-cooperation-48.svg";
export { default as IconDualToneContact48 } from "./icon/48/a-icon-dualtone-contact-48.svg";
export { default as IconDualToneFAQ48 } from "./icon/48/a-icon-dualtone-FAQ-48.svg";
export { default as IconDualToneLabManagement48 } from "./icon/48/a-icon-dualtone-labmanagement-48.svg";
export { default as IconDualToneClinicalLab48 } from "./icon/48/a-icon-dualtone-clinicallab-48.svg";
export { default as IconDualToneSearchRequest48 } from "./icon/48/a-icon-dualtone-searchrequest-48.svg";
export { default as IconDualToneBioInformatics48 } from "./icon/48/a-icon-dualtone-bioinformatics-48.svg";
export { default as IconDualToneResearchAndDevelopment48 } from "./icon/48/a-icon-dualtone-researchanddevelopment-48.svg";
export { default as IconDualToneGenotypingAnalysis48 } from "./icon/48/a-icon-dualtone-genotypinganalysis-48.svg";
export { default as IconDualToneItServicesAndSolutions48 } from "./icon/48/a-icon-dualtone-itservicesandsolutions-48.svg";
export { default as IconDualToneCommercialDepartment48 } from "./icon/48/a-icon-dualtone-commercialdepartment-48.svg";
export { default as IconDualToneCorporateQuality48 } from "./icon/48/a-icon-dualtone-corporatequality-48.svg";
export { default as IconDualToneMolecularInfectionDiagnostic48 } from "./icon/48/a-icon-dualtone-molecularinfectiondiagnostic-48.svg";
export { default as IconDualToneScienceAward48 } from "./icon/48/a-icon-dualtone-scienceaward-48.svg";
export { default as IconDualToneScientificPosters48 } from "./icon/48/a-icon-dualtone-scientificposters-48.svg";
export { default as IconDualToneProfile48 } from "./icon/48/a-icon-dualtone-profile-48.svg";
export { default as IconDualTonePostbox48 } from "./icon/48/a-icon-dualtone-postbox-48.svg";
export { default as IconDualToneDocuments48 } from "./icon/48/a-icon-dualtone-documents-48.svg";
export { default as IconDualToneMaterial48 } from "./icon/48/a-icon-dualtone-material-48.svg";
export { default as IconDualToneInfluencer48 } from "./icon/48/a-icon-dualtone-influencer-48.svg";

export { default as IconFacebook18 } from "./logo/social/18/a-logo-facebook-18.svg";
export { default as IconFacebookOriginalColored18 } from "./logo/social/18/a-logo-facebook-original-colored-18.svg";
export { default as IconFacebookWhite18 } from "./logo/social/18/a-logo-facebook-white-18.svg";
export { default as IconInstagram18 } from "./logo/social/18/a-logo-instagram-18.svg";
export { default as IconInstagramOriginalColored18 } from "./logo/social/18/a-logo-instagram-original-colored-18.svg";
export { default as IconInstagramWhite18 } from "./logo/social/18/a-logo-instagram-white-18.svg";
export { default as IconLinkedInOriginalColored18 } from "./logo/social/18/a-logo-linkedin-original-colored-18.svg";
export { default as IconLinkedInWhite18 } from "./logo/social/18/a-logo-linkedin-white-18.svg";
export { default as IconTiktok18 } from "./logo/social/18/a-logo-tiktok-18.svg";
export { default as IconTiktokOriginalColored18 } from "./logo/social/18/a-logo-tiktok-original-colored-18.svg";
export { default as IconTwitter18 } from "./logo/social/18/a-logo-twitter-18.svg";
export { default as IconTwitterOriginalColored18 } from "./logo/social/18/a-logo-twitter-original-colored-18.svg";
export { default as IconTwitterWhite18 } from "./logo/social/18/a-logo-twitter-white-18.svg";
export { default as IconXingOriginalColored18 } from "./logo/social/18/a-logo-xing-original-colored-18.svg";
export { default as IconYoutube18 } from "./logo/social/18/a-logo-youtube-18.svg";
export { default as IconYoutubeOriginalColored18 } from "./logo/social/18/a-logo-youtube-original-colored-18.svg";

export { default as IconFacebook24 } from "./logo/social/24/a-logo-facebook-24.svg";
export { default as IconFacebookOriginalColored24 } from "./logo/social/24/a-logo-facebook-original-colored-24.svg";
export { default as IconFacebookWhite24 } from "./logo/social/24/a-logo-facebook-white-24.svg";
export { default as IconGoogleMaps24 } from "./logo/social/24/a-logo-googlemaps-24.svg";
export { default as IconInstagram24 } from "./logo/social/24/a-logo-instagram-24.svg";
export { default as IconInstagramOriginalColored24 } from "./logo/social/24/a-logo-instagram-original-colored-24.svg";
export { default as IconLinkedIn24 } from "./logo/social/24/a-logo-linkedin-24.svg";
export { default as IconLinkedInOriginalColored24 } from "./logo/social/24/a-logo-linkedin-24.svg";
export { default as IconLinkedInWhite24 } from "./logo/social/24/a-logo-linkedin-white-24.svg";
export { default as IconTiktokOriginalColored24 } from "./logo/social/24/a-logo-tiktok-original-colored-24.svg";
export { default as IconTwitter24 } from "./logo/social/24/a-logo-twitter-24.svg";
export { default as IconTwitterOriginalColored24 } from "./logo/social/24/a-logo-twitter-original-colored-24.svg";
export { default as IconTwitterWhite24 } from "./logo/social/24/a-logo-twitter-white-24.svg";
export { default as IconWhatsappOriginalColored24 } from "./logo/social/24/a-logo-whatsapp-original-colored-24.svg";
export { default as IconXingOriginalColored24 } from "./logo/social/24/a-logo-xing-original-colored-24.svg";
export { default as IconYoutube24 } from "./logo/social/24/a-logo-youtube-24.svg";
export { default as IconYoutubeOriginalColored24 } from "./logo/social/24/a-logo-youtube-original-colored-24.svg";
export { default as IconTikTokOriginalColored24 } from "./logo/social/24/a-logo-tiktok-original-colored-24.svg";

export { default as IconChile } from "./flag/a-flag-chile.svg";
export { default as IconGermany } from "./flag/a-flag-germany.svg";
export { default as IconIndia } from "./flag/a-flag-india.svg";
export { default as IconPoland } from "./flag/a-flag-poland.svg";
export { default as IconUk } from "./flag/a-flag-uk.svg";
export { default as IconUsa } from "./flag/a-flag-usa.svg";
export { default as IconSouthAfrica } from "./flag/a-flag-southafrica.svg";
export { default as IconAfrica } from "./flag/a-flag-africa.svg";
export { default as IconChile24 } from "./flag/a-flag-chile-24.svg";
export { default as IconGermany24 } from "./flag/a-flag-germany-24.svg";
export { default as IconIndia24 } from "./flag/a-flag-india-24.svg";
export { default as IconPoland24 } from "./flag/a-flag-poland-24.svg";
export { default as IconSouthAfrica24 } from "./flag/a-flag-southafrica-24.svg";
export { default as IconUk24 } from "./flag/a-flag-uk-24.svg";
export { default as IconAfrica24 } from "./flag/a-flag-africa-24.svg";
export { default as IconUsa24 } from "./flag/a-flag-usa-24.svg";

export { default as IconLogo } from "./logo/dkms.svg";
export { default as IconLogoBmst } from "./logo/dkms-bmst.svg";

export { default as IconPaymentAmex } from "./logo/payment/a-logo-amex.svg";
export { default as IconPaymentApple } from "./logo/payment/a-logo-apple.svg";
export { default as IconPaymentBlik } from "./logo/payment/a-logo-blik.svg";
export { default as IconPaymentDotPay } from "./logo/payment/a-logo-dotpay.svg";
export { default as IconPaymentGoogle } from "./logo/payment/a-logo-google.svg";
export { default as IconPaymentKlarna } from "./logo/payment/a-logo-klarna.svg";
export { default as IconPaymentMasterCard } from "./logo/payment/a-logo-mastercard.svg";
export { default as IconPaymentPayPal } from "./logo/payment/a-logo-paypal.svg";
export { default as IconPaymentSepa } from "./logo/payment/a-logo-sepa.svg";
export { default as IconPaymentSmartDebit } from "./logo/payment/a-logo-smartdebit.svg";
export { default as IconPaymentVisa } from "./logo/payment/a-logo-visa.svg";
export { default as IconGiftAid } from "./logo/payment/a-logo-giftaid.svg";
