import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import GlossaryItem from "../components/glossaryItem/glossaryItem";

const KontentItemGlossaryItem = ({ data, disableDescription }) => {
  const item = data;

  if (!item || !item.elements) {
    return null;
  }

  return (
    <GlossaryItem
      key={item.id}
      abbreviation={item.elements.abbreviation.value}
      description={item.elements.description.value}
      term={item.elements.term.value}
      disableDescription={disableDescription}
    />
  );
};

export const query = graphql`
  fragment KontentItemGlossaryItem on kontent_item_glossary_item {
    id
    system {
      codename
    }
    elements {
      abbreviation {
        value
      }
      term {
        value
      }
      description {
        value
      }
    }
  }
`;

KontentItemGlossaryItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  disableDescription: PropTypes.bool,
};

KontentItemGlossaryItem.defaultProps = {
  disableDescription: false,
};

export default KontentItemGlossaryItem;
