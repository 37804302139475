import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemTestimonial from "./kontentItemTestimonial";
import PartnerSection from "../components/partnerSection/partnerSection";

const KontentItemPartnersSlider = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <PartnerSection
      headline={section.elements.headline.value}
      text={section.elements.text.value}
    >
      {KontentItemTestimonial({ data: section.elements.milestones.value })}
    </PartnerSection>
  );
};

export const query = graphql`
  fragment KontentItemPartnersSlider on kontent_item_partners_slider {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      milestones {
        value {
          ...KontentItemTestimonial
        }
      }
    }
  }
`;

KontentItemPartnersSlider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPartnersSlider;
