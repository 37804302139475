export const sex = (intl) => [
  { label: intl.formatMessage({ id: "form.sex.male" }), value: "M" },
  { label: intl.formatMessage({ id: "form.sex.female" }), value: "F" },
];

export const topicOptions = (intl) => [
  { label: intl.formatMessage({ id: "support" }), value: "technical" },
  { label: intl.formatMessage({ id: "support" }), value: "theoretical" },
  { label: intl.formatMessage({ id: "support" }), value: "support" },
];
