import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ArticleCard from "../components/articleCard/articleCard";
import LinkButton from "../components/button/linkButton";
import { IconExternalLink24, IconFurther24, IconPdf24 } from "../icons";

const KontentItemLinkCard = ({ data }) => {
  const linkCard = data;

  let button = null;
  let href = linkCard.elements.external_url.value;

  if (linkCard.elements.internal_url.value.length > 0) {
    href = linkCard.elements.internal_url.value[0].id;
  }

  if (href && href !== "") {
    button = (
      <LinkButton href={href}>
        {linkCard.elements.button_text.value}
        {linkCard.elements.external_url.value ? <IconExternalLink24 /> : <IconFurther24 />}
      </LinkButton>
    );
  }

  if (linkCard.elements.download.value.length > 0) {
    const [download] = linkCard.elements.download.value;

    href = download.elements.asset_item.value.length > 0
      ? download.elements.asset_item.value[0].url
      : "";

    button = (
      <LinkButton
        target="_blank"
        rel="noopener noreferrer"
        href={href}
      >
        <IconPdf24 /> {download.elements.title.value}
      </LinkButton>
    );
  }

  return (
    <ArticleCard
      image={
        linkCard.elements.image.value.length > 0
          ? <KontentItemImage data={linkCard.elements.image.value[0]} ratio16by9 />
          : null
      }
      headline={linkCard.elements.headline.value}
      excerpt={linkCard.elements.text.value}
      button={button}
      href={href}
    />
  );
};

export const query = graphql`
  fragment KontentItemLinkCard on kontent_item_link_card {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      button_text {
        value
      }
      external_url {
        value
      }
      internal_url {
        value {
          id
        }
      }
      download {
        value {
          ...KontentItemAsset
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemLinkCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLinkCard;
