import React, { Component } from "react";

import PropTypes from "prop-types";

import MediaLightboxImage from "../lightbox/mediaLightboxImage";

class GalleryImage extends Component {
  constructor() {
    super();
    this.mediaModal = null;
  }

  render() {
    const { image } = this.props;

    return (
      <div>
        <div
          role="presentation"
          onClick={() => {
            this.mediaModal.show();
          }}
        >
          <div className="article-module-gallery__image">
            {image}
          </div>
        </div>
        <MediaLightboxImage
          media={image}
          ref={(mediaModal) => {
            this.mediaModal = mediaModal;
          }}
        />
      </div>
    );
  }
}

GalleryImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default GalleryImage;
