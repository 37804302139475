import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemDepartment from "./kontentItemDepartment";
import DepartmentSection from "../components/departmentSection/departmentSection";

const KontentItemDepartmentsSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <DepartmentSection
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      moreUrl={section.elements.button_url.value.length > 0
        ? section.elements.button_url.value[0].id
        : null}
    >
      {KontentItemDepartment({ data: section.elements.departments.value })}
    </DepartmentSection>
  );
};

export const query = graphql`
  fragment KontentItemDepartmentsSection on kontent_item_departments_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      departments {
        value {
          ...KontentItemDepartment
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemDepartmentsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDepartmentsSection;
