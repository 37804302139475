import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import KontentItemSubsidiariesCard from "./kontentItemSubsidiariesCard";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemNetworkSlider = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <div className="container--fullwidth network-section">
      <Container fluid>
        <CardSlider
          header={section.elements.headline.value}
          moreUrl={section.elements.button_url.value.length > 0
            ? section.elements.button_url.value[0].id
            : null}
          equalCardHeights
        >
          {
            section.elements.subsidiaries.value.map(
              (card) => KontentItemSubsidiariesCard({ data: card }),
            )
          }
        </CardSlider>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment KontentItemNetworkSlider on kontent_item_network_slider {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      button_url {
        value
      }
      subsidiaries {
        value {
          ...KontentItemSubsidiariesCard
        }
      }
    }
  }
`;

KontentItemNetworkSlider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNetworkSlider;
