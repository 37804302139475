import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import LinkButton from "../../components/button/linkButton";
import IconText from "../../components/iconText/iconText";
import ListElement from "../../components/listSection/listElement";
import NavigationLink from "../../components/navigation/navigationLink";
import {
  IconAnchorRight18,
  IconDataSet18,
  IconExternalLink24,
  IconPartnerTool18,
  IconSoftware18,
} from "../../icons";

const ExternalResourceListElement = ({ data, tools }) => {
  const formsData = Array.isArray(data) ? data : data.value;

  const formsList = [];

  const actionLabels = {
    external_ressources: <FormattedMessage id="tools.open_tool" />,
    raw_data: <FormattedMessage id="resources_category_overview.view_data_set" />,
    results_data: <FormattedMessage id="resources_category_overview.view_data_set" />,
    script: <FormattedMessage id="resources_category_overview.view_script" />,
    software: <FormattedMessage id="resources_category_overview.view_software" />,
  };

  const getCategoryIconText = (item, category) => {
    let icon = null;
    let name = null;

    switch (category.codename.toLowerCase()) {
      case "external_ressources":
        icon = <IconPartnerTool18 />;
        name = "External Tool";
        break;
      case "software":
        icon = <IconSoftware18 />;
        break;
      case "script":
        icon = <IconSoftware18 />;
        break;
      case "resource_link":
        icon = <IconPartnerTool18 />;
        break;
      case "results_data":
        icon = <IconDataSet18 />;
        break;
      case "raw_data":
        icon = <IconDataSet18 />;
        break;
      default:
    }

    return <IconText icon={icon} text={name || category.name} />;
  };

  formsData.forEach((item) => {
    let action = null;
    let category = null;
    let categoryIconText = null;

    if (item.elements.resource_type.value.length > 0) {
      category = item.elements.resource_type.value[
        tools
          ? 0
          : item.elements.resource_type.value.length - 1
      ];

      categoryIconText = getCategoryIconText(item, category);

      const translation = actionLabels[category.codename.toLowerCase()];

      if (item.elements.external_url.value) {
        action = (
          <LinkButton
            target="_blank"
            rel="noopener noreferrer"
            href={
              item.elements.external_url.value.length > 0
                ? item.elements.external_url.value
                : ""
            }
          >
            {translation || <FormattedMessage id="resources_category_overview.open_resource" />}<IconExternalLink24 />
          </LinkButton>
        );
      }
    }

    formsList.push(
      <ListElement
        name={item.elements.name.value}
        description={item.elements.description.value}
        type={
          item.elements.resource_type.value.length > 0
          && item.elements.resource_type.value[item.elements.resource_type.value.length - 1].name
        }
        category={(
          <>
            {categoryIconText}
            {!tools && item.elements.related_publication.value.length > 0 && (
              <NavigationLink
                href={item.elements.related_publication.value[0].id}
                className="no-style"
              >
                <FormattedMessage id="resources_category_overview.related_publication" />
                <IconAnchorRight18 />
              </NavigationLink>
            )}
          </>
        )}
        actions={(
          <>
            {action}
            {!tools && item.elements.text_file.value.length > 0
            && item.elements.text_file.value[0].url.length > 0
            && (
              <LinkButton
                target="_blank"
                rel="noopener noreferrer"
                href={item.elements.text_file.value[0].url}
              >
                <FormattedMessage id="resources_category_overview.open_text_file" /> <IconExternalLink24 />
              </LinkButton>
            )}
          </>
        )}
      />,
    );
  });

  return React.Children.toArray(formsList);
};

export const query = graphql`
  fragment ExternalResourceListElement on kontent_item_external_resource {
    id
    system {
      codename
    }
    elements {
      name {
        value
      }
      description {
        value
      }
      resource_type {
        value {
          codename
          name
        }
      }
      text_file {
        type
        value {
          description
          width
          height
          name
          size
          type
          url
        }
      }
      related_publication {
        value {
          id
        }
      }
      external_url {
        value
      }
      text_file {
        value {
          url
        }
      }
    }
  }
`;

ExternalResourceListElement.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tools: PropTypes.bool,
};

ExternalResourceListElement.propTypes = {
  tools: false,
};

export default ExternalResourceListElement;
