import React from "react";

import PropTypes from "prop-types";

import { IconDownload24 } from "../../icons";
import downloadImage from "../../utils/downloadImage";
import IconButton from "../button/iconButton";

import "./posterImage.scss";

const PosterImage = ({ image, downloadLink, name }) => (
  <div className="poster-image">
    <div className="poster-image__image">
      {image}
    </div>
    <div
      className="poster-image__shadow"
      onClick={() => {
        window.open(downloadLink);
      }}
      role="presentation"
    />
    <div className="poster-image__button">
      <IconButton
        onClick={
          () => downloadImage(downloadLink, name)
        }
        iconOnly
        variant="outline-light"
      >
        <IconDownload24 />
      </IconButton>
    </div>
  </div>
);

PosterImage.propTypes = {
  downloadLink: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
};

export default PosterImage;
