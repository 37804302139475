import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const TitleRow = ({ children, description, className }) => (
  <Row className={classNames("title-row", className)}>
    <Col sm={10} md={8} lg={7}>
      <h2>{children}</h2>
    </Col>
    {description && (
      <Col sm={10} md={8} lg={{ offset: 1, span: 4 }}>
        <div className="title-row__description">{description}</div>
      </Col>
    )}
  </Row>
);

TitleRow.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
};

TitleRow.defaultProps = {
  className: null,
  description: null,
};

export default TitleRow;
