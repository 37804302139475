import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemContact from "./kontentItemContact";
import ContactIconButton from "../components/button/contactIconButton";
import EyecatcherContact from "../components/eyecatcher/eyecatcherContact";

const KontentItemEyecatcherContact = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements || eyecatcher.elements.contact.value.length === 0) {
    return null;
  }

  const contact = eyecatcher.elements.contact.value[0];

  if (!contact) {
    return null;
  }

  return (
    <EyecatcherContact
      title={eyecatcher.elements.title.value}
      contactPerson={(<KontentItemContact data={contact} />)}
    >
      <ContactIconButton
        href={
          eyecatcher.elements.button_url.value.length > 0
            ? eyecatcher.elements.button_url.value[0].id
            : eyecatcher.elements.button_url_webform.value
        }
        mailto={eyecatcher.elements.button_mailto.value}
        phone={eyecatcher.elements.button_phone.value}
        contactName={contact.elements.name.value}
        variant="outline-faq"
      >
        {eyecatcher.elements.button_text.value}
      </ContactIconButton>
    </EyecatcherContact>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherContact on kontent_item_eyecatcher_contact {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      button_text {
        value
      }
      button_url_webform {
        value
      }
      button_phone {
        value
      }
      button_mailto {
        value
      }
      button_url {
        value {
          id
        }
      }
      contact {
        value {
          ...KontentItemContact
        }
      }
    }
  }
`;

KontentItemEyecatcherContact.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherContact;
