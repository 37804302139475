import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCategoryLinkCard from "./kontentItemCategoryLinkCard";
import NavigationLink from "../components/navigation/navigationLink";
import Section from "../components/section/section";
import { IconAnchorRight18 } from "../icons";

const KontentItemServicesSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <Section
      headline={
        section.elements.service_category_link.value.length > 0
          ? (
            <NavigationLink
              href={section.elements.service_category_link.value[0].id}
              className="no-style"
            >
              {section.elements.headline.value}
              <IconAnchorRight18 />
            </NavigationLink>
          )
          : section.elements.headline.value
      }
      text={section.elements.text.value}
      colProps={{ md: 6 }}
    >
      {section.elements.services.value.map((service) => (
        <KontentItemCategoryLinkCard data={service} key={service.id} />
      ))}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemServicesSection on kontent_item_services_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      service_category_link {
        value {
          id
        }
      }
      services {
        value {
          ...KontentItemCategoryLinkCard
        }
      }
    }
  } 
`;

KontentItemServicesSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemServicesSection;
