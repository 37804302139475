import React from "react";

import Navigation from "../navigation/main/navigation";

const Header = () => (
  <header className="header">
    <Navigation />
  </header>
);

export default Header;
