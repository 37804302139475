import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DepartmentTileGrid from "../../components/departmentTile/departmentTileGrid";
import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeaderSidebar from "../../components/textHeaderSidebar/textHeaderSidebar";
import DepartmentOverviewTiles from "../departments/departmentOverviewTiles";
import KontentAssetsImage from "../kontentAssetsImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SidebarNavigationGenerator from "../navigation/sidebarNavigationGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemDepartmentOverview = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <SidebarLayout
        sidebar={
          <SidebarNavigationGenerator id={page.id} />
        }
      >
        <BreadcrumbGenerator id={page.id} />

        <TextHeaderSidebar
          icon={
            page.elements.icon.value.length > 0
              ? (
                <KontentAssetsImage
                  data={page.elements.icon.value[0]}
                  height={100}
                  className="img-fluid"
                />
              )
              : null
          }
          title={page.elements.headline.value}
          text={page.elements.lead_text.value}
        />

        <DepartmentTileGrid>
          {DepartmentOverviewTiles({ id: page.id })}
        </DepartmentTileGrid>

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemDepartmentOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        icon {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
      }
    }
  }
`;

KontentItemDepartmentOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDepartmentOverview;
