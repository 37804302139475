import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";

const getSidebarNavigationNode = (id, graphqlData = null) => {
  let data = graphqlData;

  if (!data) {
    data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
      primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                    elements {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                            elements {
                              value {
                                ... on kontent_item_navigation_item {
                                  id
                                  elements {
                                    title {
                                      value
                                    }
                                    url_slug {
                                      value
                                    }
                                    external_url {
                                      value
                                    }
                                    elements {
                                      value {
                                        ... on kontent_item_navigation_item {
                                          id
                                          elements {
                                            title {
                                              value
                                            }
                                            url_slug {
                                              value
                                            }
                                            external_url {
                                              value
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  }

  if (id) {
    const node = _.find(
      data.links.nodes,
      (child) => child.id.includes(id),
    );

    if (node) {
      const slugParts = node.slug.split("/");
      // Remove first empty element
      slugParts.shift();

      if (slugParts.length > 0 && slugParts[0]) {
        const navigationData = data.primaryNavigation.nodes[0].elements.elements.value;

        const mainItem = _.find(data.links.nodes, {
          slug: `/${slugParts[0]}/${slugParts[1]}`,
        });

        if (mainItem) {
          const mainNavigation = _.find(navigationData, _.flow(
            _.property("elements.elements.value"),
            _.partialRight(_.some, { id: mainItem.navigationItemId }),
          ));

          if (!mainNavigation) return null;

          const sidebarLevel = _.find(
            mainNavigation.elements.elements.value,
            { id: mainItem.navigationItemId },
          );

          if (sidebarLevel) {
            return { navId: node.navigationItemId, sidebarLevel };
          }
        }
      }
    }
  }

  return null;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getSidebarNavigationNode,
};
