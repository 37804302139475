import React, { useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";

import {
  getComplianceSettings,
  getComplianceTypes,
  setComplianceSettings,
  setShowComplianceSettings,
} from "../../features/complianceConsentSlice";
import { IconAnchorLeft18, IconSelectBlue24, IconSelectEmpty24 } from "../../icons";
import SimpleRichText from "../../templates/text/simpleRichText";
import DataLayer from "../../utils/dataLayer";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigation/navigationLink";

import "./cookieBanner.scss";

const CookieBanner = ({ openLinksInNewTab }) => {
  const complianceTypes = useSelector((state) => (
    state.complianceConsent.complianceTypes
  ));
  const consents = useSelector((state) => (
    state.complianceConsent.consents
  ));
  const show = useSelector((state) => (
    state.complianceConsent.show
  ));
  const dispatch = useDispatch();

  const data = useStaticQuery(graphql`
    {
      cookie: allKontentItemSiteSettings {
        nodes {
          elements {
            url_legal {
              value {
                id
              }
            }
            url_privacy {
              value {
                id
              }
            }
            text_consent_banner {
              value
            }
            headline_consent_banner {
              value
            }
            headline_consent_edit {
              value
            }
            button_accept_text {
              value
            }
            button_edit_text {
              value
            }
            button_save_text {
              value
            }
            url_legal_text {
              value
            }
            url_privacy_text {
              value
            }
            detailed_settings {
              value {
                ... on kontent_item_consent_types {
                  id
                  elements {
                    consent_type {
                      value {
                        codename
                      }
                    }
                    title {
                      value
                    }
                    description {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (data.cookie.nodes.length === 0) {
    return null;
  }

  const content = data.cookie.nodes[0].elements;

  const [labels, setLabels] = useState([]);
  const [settings, setSettings] = useState({});
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    dispatch(
      getComplianceTypes(),
    );

    dispatch(
      getComplianceSettings(),
    );
  }, []);

  useEffect(() => {
    if (complianceTypes.some((key) => settings[key] && settings[key].status === -1)) {
      dispatch(
        setShowComplianceSettings(true),
      );
    }
  }, [settings]);

  useEffect(() => {
    const { ppms } = window;

    if (ppms) {
      ppms.cm.api("getNewComplianceTypes", (types) => {
        setSettings({
          ...settings,
          ...Object.keys(consents).reduce((acc, key) => {
            acc[key] = { status: consents[key].status };

            return acc;
          }, {}),
          ...types.reduce((acc, key) => ({ ...acc, [key]: { status: -1 } }), {}),
        });
      });
    }
  }, [consents]);

  if (show) {
    DataLayer.pushEvent("stg.consentFormViewMain");
  }

  return (
    <div id="acceptance-cookie-modal" className="cookie-banner-row">
      {show && (
        <div className="cookie-banner">
          {showSettings ? (
            <div className="d-flex align-items-center">
              <LinkButton
                variant="normal"
                className="cookie-banner__back-btn mb-0"
                onClick={() => {
                  setShowSettings(false);
                }}
              >
                <IconAnchorLeft18 />
              </LinkButton>
              <div className="cookie-banner__title">
                <span className="typo-title3">{content.headline_consent_edit.value}</span>
              </div>
            </div>
          ) : (
            <div className="typo-title3">{content.headline_consent_banner.value}</div>
          )}

          {showSettings ? (
            content.detailed_settings.value.map(
              (item) => complianceTypes.includes(
                item.elements.consent_type.value.length > 0
                && item.elements.consent_type.value[0].codename,
              ) && (
                <div className="cookie-banner__filter-listitem" key={item.elements.consent_type.value[0].codename}>
                  <div
                    className="label"
                    onClick={() => {
                      if (settings[item.elements.consent_type.value[0].codename]
                        && settings[item.elements.consent_type.value[0].codename].status <= 0) {
                        setLabels([...labels, item.elements.title.value]);
                        setSettings({
                          ...settings,
                          [item.elements.consent_type.value[0].codename]: { status: 1 },
                        });
                      } else if (settings[item.elements.consent_type.value[0].codename]
                        && settings[item.elements.consent_type.value[0].codename].status === 1) {
                        setLabels([...labels, item.elements.title.value]);
                        setSettings({
                          ...settings,
                          [item.elements.consent_type.value[0].codename]: { status: 0 },
                        });
                      }
                    }}
                    role="presentation"
                  >
                    {item.elements.title.value}
                    {settings[item.elements.consent_type.value[0].codename].status === 0
                    || settings[item.elements.consent_type.value[0].codename].status === -1 ? (
                      <IconSelectEmpty24 />
                      ) : (
                        <IconSelectBlue24 />
                      )}
                  </div>
                  <div className="filter-listitem-description">{item.elements.description.value}</div>
                </div>
              ),
            )
          ) : (
            <div className="cookie-banner__subtext">
              <SimpleRichText data={content.text_consent_banner} />
            </div>
          )}

          <div className="cookie_banner-copyright">
            {content.url_privacy.value.length > 0 && (
              <NavigationLink
                href={content.url_privacy.value[0].id}
                target={openLinksInNewTab ? "_blank" : null}
              >
                {content.url_privacy_text.value}
              </NavigationLink>
            )}
            {content.url_legal.value.length > 0 && (
              <NavigationLink
                href={content.url_legal.value[0].id}
                target={openLinksInNewTab ? "_blank" : null}
              >
                {content.url_legal_text.value}
              </NavigationLink>
            )}
          </div>

          <div className="cookie_banner__buttons">
            <Button
              variant="faq"
              size="sm"
              onClick={() => {
                const tmpSettings = {};
                complianceTypes.forEach((item) => {
                  tmpSettings[item] = { status: 1 };
                });
                dispatch(
                  setComplianceSettings({ consents: tmpSettings }),
                );

                dispatch(
                  getComplianceSettings(),
                );

                dispatch(
                  setShowComplianceSettings(false),
                );

                setShowSettings(false);

                DataLayer.pushEvent("stg.agreeToAllClicked");
              }}
            >
              {content.button_accept_text.value}
            </Button>

            {showSettings ? (
              <IconButton
                onClick={() => {
                  const seenConsents = Object.keys(settings).reduce(
                    (acc, key) => ({
                      ...acc,
                      [key]: { status: settings[key].status === 1 ? 1 : 0 },
                    }),
                    {},
                  );

                  dispatch(
                    setComplianceSettings({ consents: seenConsents }),
                  ).then(() => {
                    dispatch(
                      getComplianceSettings(),
                    );
                  });

                  dispatch(
                    setShowComplianceSettings(false),
                  );

                  setShowSettings(false);

                  DataLayer.pushEvent("stg.saveChoicesClicked");
                }}
                variant="outline-faq"
                size="sm"
              >
                {content.button_save_text.value}
              </IconButton>
            ) : (
              <LinkButton
                onClick={() => {
                  setShowSettings(true);
                }}
              >
                {content.button_edit_text.value}
              </LinkButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CookieBanner.propTypes = {
  openLinksInNewTab: PropTypes.bool,
};

CookieBanner.defaultProps = {
  openLinksInNewTab: false,
};

export default CookieBanner;
