import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import FilterDropdown from "../../components/filterDropown/filterDropdown";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import OverviewText from "../../components/overviewText/overviewText";
import SEO from "../../components/seo";
import TextSection from "../../components/textSection/textSection";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import LatestEventCards from "../news/latestEventCards";
import LatestNewsCards from "../news/latestNewsCards";

const KontentItemNewsOverview = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <TextSection
        headline={page.elements.headline_news.value}
        text={page.elements.description_news.value}
        className="pb-0"
      />

      <FilterDropdown>
        {LatestNewsCards()}
      </FilterDropdown>

      {page.elements.headline_events.value && (
        <>
          <Divider isFullWidth />

          <OverviewText headline={page.elements.headline_events.value}>
            {page.elements.description_events.value}
          </OverviewText>

          <FilterDropdown>
            {LatestEventCards()}
          </FilterDropdown>
        </>
      )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemNewsOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        headline {
          value
        }
        headline_news {
          value
        }
        description_news {
          value
        }
        headline_events {
          value
        }
        description_events {
          value
        }
      }
    }
  }
`;

KontentItemNewsOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNewsOverview;
