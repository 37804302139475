import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import mapContentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import DynamicHeadline from "../utils/dynamicHeadline";

const KontentItemResearchScope = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <DynamicHeadline
        headline={page.elements.hero_headline.value}
        breadcrumb={(<BreadcrumbGenerator id={page.id} />)}
        text={page.elements.lead_text.value}
        icon={
          page.elements.icon.value.length > 0
            ? (
              <KontentAssetsImage
                data={page.elements.icon.value[0]}
                height={150}
              />
            )
            : null
        }
        heroImage={
          page.elements.hero_image.value.length > 0
            ? page.elements.hero_image.value[0]
            : null
        }
      />

      <SidebarLayout
        isRight
      >
        <RichText data={page.elements.body} isSidebarLayout />

        {
          page.elements.eyecatcher_section.value.length > 0
            ? page.elements.eyecatcher_section.value.map((item) => mapContentItem(item))
            : null
        }
      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemResearchScope(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_headline {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        icon {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }  
        eyecatcher_section {
          value {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemContactPersonsSection
            ...KontentItemServicesSection
            ...KontentItemRepresentativesSection
            ...KontentItemDownloadsSection
            ...KontentItemEyecatcherIconSection
          }
        } 
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemResearchScope.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemResearchScope;
