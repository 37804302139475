import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemContactBox from "./kontentItemContactBox";
import KontentItemEyecatcherContact from "./kontentItemEyecatcherContact";
import Section from "../components/section/section";

const KontentItemContactPersonsSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements || section.elements.contacts.value.length === 0) {
    return null;
  }

  let contactView = null;
  let isFullWidthChild = false;

  if (section.elements.contacts.value.length > 1) {
    contactView = [];
    section.elements.contacts.value.forEach((item) => {
      contactView.push((
        <KontentItemContactBox data={item} key={item.id} />
      ));
    });
  } else {
    contactView = (
      <KontentItemEyecatcherContact
        data={
          section.elements.contacts.value.length > 0
            ? section.elements.contacts.value[0]
            : null
        }
      />
    );
    isFullWidthChild = true;
  }

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      isFullWidthChild={isFullWidthChild}
      colProps={isFullWidthChild ? {} : { md: 6 }}
    >
      {contactView}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemContactPersonsSection on kontent_item_contact_persons_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      contacts {
        value {
          ...KontentItemContactBox
        }
      }
    }
  } 
`;

KontentItemContactPersonsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemContactPersonsSection;
