import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import MainLayout from "../../components/mainLayout";
import Section from "../../components/section/section";
import SEO from "../../components/seo";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemCategoryLinkCard from "../kontentItemCategoryLinkCard";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import DynamicHeadline from "../utils/dynamicHeadline";

const KontentItemFactsFigures = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <DynamicHeadline
        headline={page.elements.hero_headline.value}
        breadcrumb={(<BreadcrumbGenerator id={page.id} />)}
        text={page.elements.lead_text.value}
        icon={
          page.elements.icon.value.length > 0
            ? (
              <KontentAssetsImage
                data={page.elements.icon.value[0]}
                height={150}
              />
            )
            : null
        }
        heroImage={
          page.elements.hero_image.value.length > 0
            ? page.elements.hero_image.value[0]
            : null
        }
      />

      {page.elements.headline.value && page.elements.lead_text.value && (<Divider isFullWidth />)}

      <Divider isFullWidth />

      <Section headline={page.elements.headline_factsheets.value}>
        {page.elements.factsheets.value.map((sheet) => (
          KontentItemCategoryLinkCard({ data: sheet })
        ))}
      </Section>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemFactsFigures(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        icon {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }  
        hero_headline {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        headline_factsheets {
          value
        }
        factsheets {
          value {
            ...KontentItemCategoryLinkCard
          }
        }
      }
    }
  }
`;

KontentItemFactsFigures.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFactsFigures;
