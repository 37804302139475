import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemMilestone from "./kontentItemMilestone";
import SimpleRichText from "./text/simpleRichText";
import StorySection from "../components/storySection/storySection";

const KontentItemStorySlider = ({ data }) => {
  const storySlider = data.value ? data.value[0] : data;

  if (!storySlider || !storySlider.elements) {
    return null;
  }

  return (
    <StorySection
      headline={storySlider.elements.headline.value}
      text={storySlider.elements.text.value}
      readMoreText={SimpleRichText({ data: storySlider.elements.read_more_text })}
    >
      {KontentItemMilestone({ data: storySlider.elements.milestones.value })}
    </StorySection>
  );
};

export const query = graphql`
  fragment KontentItemStorySlider on kontent_item_story_slider {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      read_more_text {
        value
      }
      milestones {
        value {
          ...KontentItemMilestone
        }
      }
    }
  }
`;

KontentItemStorySlider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemStorySlider;
