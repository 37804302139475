import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import MainLayout from "../../components/mainLayout";
import Section from "../../components/section/section";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeaderSidebar from "../../components/textHeaderSidebar/textHeaderSidebar";
import KontentItemCategoryLinkCard from "../kontentItemCategoryLinkCard";
import KontentItemImage from "../kontentItemImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SidebarNavigationGenerator from "../navigation/sidebarNavigationGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemServicesCategoryOverview = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <SidebarLayout
        sidebar={(
          <SidebarNavigationGenerator id={page.id} />
        )}
      >
        <BreadcrumbGenerator id={page.id} />

        <TextHeaderSidebar
          title={page.elements.hero_headline.value}
          text={page.elements.lead_text.value}
          image={
            page.elements.hero_image.value.length > 0
              ? (
                <KontentItemImage
                  width={1000}
                  data={page.elements.hero_image.value[0]}
                  className="img-fluid"
                  aspectRatio={21 / 9}
                  crop
                />
              )
              : null
          }
        />

        <Section
          colProps={{ md: 6 }}
        >
          {page.elements.category_services.value.map((service) => (
            KontentItemCategoryLinkCard({ data: service })
          ))}
        </Section>

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemServicesCategoryOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        lead_text {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        category_services {
          value {
            ...KontentItemCategoryLinkCard
          }
        }
      }
    }
  }
`;

KontentItemServicesCategoryOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemServicesCategoryOverview;
