import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther18 } from "../../icons";
import IconButton from "../button/iconButton";
import NavigationLink from "../navigation/navigationLink";

import "./quickLinkTile.scss";

const QuickLinkTile = (props) => {
  const {
    tag,
    headline,
    text,
    url,
    isType,
    image,
    isRect,
  } = props;

  return (
    <NavigationLink
      href={url}
      className={classNames(
        "quick-link-tile no-style",
        { "quick-link-tile--rect": isRect },
      )}
    >
      {image !== null && (
        <div className="quick-link-tile__image-container">
          <div className="quick-link-tile__image">
            {image}
          </div>
        </div>
      )}
      <div className="quick-link-tile__body">
        <div>
          <div className={classNames("quick-link-tile__icon", { "quick-link-tile__icon-text": isType })}>{tag}</div>
          <div className="quick-link-tile__headline">{headline}</div>
          {text && (
            <div className="quick-link-tile__text">{text}</div>
          )}
        </div>
        <div className="quick-link-tile__button">
          <IconButton variant="light-registration">
            <FormattedMessage id="tile.learn_more" /> <IconFurther18 />
          </IconButton>
        </div>
      </div>
    </NavigationLink>
  );
};

QuickLinkTile.propTypes = {
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  isRect: PropTypes.bool,
  isType: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
};

QuickLinkTile.defaultProps = {
  image: null,
  isRect: false,
  isType: false,
  text: null,
};

export default QuickLinkTile;
