import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import NavigationLink from "../navigation/navigationLink";

import "./linkButton.scss";

const LinkButton = (props) => {
  const {
    children,
    className,
    variant,
    ...buttonProps
  } = props;

  return (
    <Button
      variant="link"
      type="button"
      as={NavigationLink}
      className={classNames([
        "link-button",
        (Array.isArray(children) && children[0]
        && (typeof children[0] === "string"
          || (typeof children[0] === "object" && children[0].type !== undefined && children[0].type.displayName === "MemoizedFormattedMessage"))
          ? "link-button--pre-text" : "link-button--post-text"),
        className,
        {
          "link-button--normal typo-label": variant === "normal",
        },
      ])}
      {...buttonProps}
    >
      <span tabIndex="-1">
        {children}
      </span>
    </Button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["button", "normal"]),
};

LinkButton.defaultProps = {
  className: "",
  variant: "button",
};

export default LinkButton;
