import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import DepartmentTile from "../components/departmentTile/departmentTile";

const KontentItemTestimonial = ({ data }) => {
  const testimonialData = Array.isArray(data) ? data : [data];

  const testimonialList = [];

  testimonialData.forEach((item) => {
    testimonialList.push(
      <DepartmentTile
        name={item.elements.name.value}
        text={item.elements.description.value}
        media={item.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              data={item.elements.image.value[0]}
              className="rounded-circle"
              height={200}
              width={200}
            />
          )
          : null}
        href={item.elements.external_url.value !== "" ? item.elements.external_url.value : "#"}
        target="_blank"
        rel="noopener noreferrer"
      />,
    );
  });

  return React.Children.toArray(testimonialList);
};

export const query = graphql`
  fragment KontentItemTestimonial on kontent_item_testimonial {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      name {
        value
      }
      description {
        value
      }
      external_url {
        value
      }
    }
  }
`;

KontentItemTestimonial.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTestimonial;
