const scrollActiveNavItem = (nav) => {
  const activeElement = nav.getElementsByClassName("active").length > 0
    ? nav.getElementsByClassName("active")[0]
    : null;

  if (activeElement) {
    try {
      const left = activeElement.offsetLeft;
      const { width: elementWidth } = activeElement.getBoundingClientRect();
      const { width: navWidth } = nav.getBoundingClientRect();

      return left - (navWidth / 2) + (elementWidth / 2);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
    }
  }

  return 0;
};

export default scrollActiveNavItem;
