import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";

import "./radioButtons.scss";

const RadioButtons = (props) => {
  const {
    id,
    title,
    options,
    errors,
    touched,
    hideLabel,
    isRequired,
  } = props;

  const radioOptions = options ? options.map((e) => (e && typeof e === "object" ? { label: e.label, value: e.value } : { label: e, value: e })) : [];

  return (
    <>
      {title && !hideLabel && (
        <p className={classNames("typo-label", { "required-sign": isRequired })}>
          {title}
        </p>
      )}
      <Form.Group
        className="radio-form w-100"
      >
        {radioOptions.map((option) => (
          <Form.Label key={option.value} className="radio-form__label">
            <Field type="radio" name={id} value={option.value} label={option.label} className="mr-9" />
            {option.label}
          </Form.Label>
        ))}
        {errors[id] && touched[id] && (
          <Form.Text className="invalid-feedback">
            <IconError18 />{ errors[id] }
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};

RadioButtons.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hideLabel: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

RadioButtons.defaultProps = {
  title: null,
};

export default RadioButtons;
