import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./anchorLinkBar.scss";

const AnchorLinkBar = ({ active, items }) => (
  <div className="anchor-link-bar">
    {items.map((item) => (
      <AnchorLink
        key={`ap${item}`}
        className={classNames(
          "anchor-button mr-5 mb-4",
          {
            "active-anchor": active.includes(item),
            "disabled-anchor": !active.includes(item),
          },
        )}
        href={`#anchor-point-${item}`}
      >
        {item}
      </AnchorLink>
    ))}
  </div>
);

AnchorLinkBar.propTypes = {
  active: PropTypes.oneOfType([PropTypes.array]),
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

AnchorLinkBar.defaultProps = {
  active: [],
};

export default AnchorLinkBar;
