import React, { memo } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const Logo = ({ country }) => {
  const siteCode = process.env.GATSBY_SITE;
  const logoLanguage = country || siteCode;

  const data = useStaticQuery(graphql`
    {
      logo: allFile(filter: {ext: {eq: ".png"}, name: {regex: "/a-logo-dkms-.*-l/"}}) {
        nodes {
          normalLogos: childImageSharp {
            gatsbyImageData(height: 29, quality: 100, layout: FIXED)
          }
          wideLogos: childImageSharp {
            gatsbyImageData(height: 19, quality: 100, layout: FIXED)
          }
          name
        }
      } 
    }
  `);

  let image = null;

  data.logo.nodes.map((node) => {
    if (node.name === `a-logo-dkms-${logoLanguage}-l`) {
      image = logoLanguage === "labs" ? node.wideLogos : node.normalLogos;
    }

    return undefined;
  });

  if (image) {
    return (
      <GatsbyImage
        image={getImage(image)}
        className={logoLanguage === "labs" ? "logo-wide" : "logo"}
        alt="DKMS"
        objectFit="cover"
      />
    );
  }

  return null;
};

Logo.propTypes = {
  country: PropTypes.string,
};

Logo.defaultProps = {
  country: null,
};

export default memo(Logo);
