import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import LinkButton from "../components/button/linkButton";
import ScientificPoster from "../components/scientificPoster/scientificPoster";
import {
  IconDownload18,
  IconFurther18,
} from "../icons";

const KontentItemScientificPosterCards = ({ data, limit }) => {
  const intl = useIntl();

  const studyData = Array.isArray(data) ? data : [data];

  const studyList = [];

  studyData.forEach((tile) => {
    studyList.push(
      <ScientificPoster
        image={tile.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              data={tile.elements.image.value[0]}
              width={165}
              height={219}
            />
          )
          : null}
        href={tile.id}
        name={tile.elements.conference.value.length > 0
          ? tile.elements.conference.value[0].name
          : null}
        author={tile.elements.authors.value}
        title={tile.elements.title.value}
        date={(
          tile.elements.publishing_date.value
            ? new Date(tile.elements.publishing_date.value)
            : null
        )}
        conference={tile.elements.conference.value.length > 0
          ? tile.elements.conference.value[0].name
          : null}
        categories={tile.elements.category__publications_poster.value.map((item) => item.name)}
        button={(
          <>
            <LinkButton href={tile.id}>
              {intl.formatMessage({ id: "study_card.views_details" })} <IconFurther18 />
            </LinkButton>

            {tile.elements.download.value.length > 0
              ? tile.elements.download.value.map((download) => (
                <>
                  <LinkButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      download.elements.asset_item.value.length > 0
                        ? download.elements.asset_item.value[0].url
                        : ""
                    }
                  >
                    <FormattedMessage id="download" /> <IconDownload18 />
                  </LinkButton>
                  <br />
                </>
              ))
              : null}
          </>
        )}
      />,
    );
  });

  if (limit) {
    return React.Children.toArray(studyList.slice(0, limit));
  }

  return React.Children.toArray(studyList);
};

export const query = graphql`
  fragment ScientificPosterCards on kontent_item_poster {
    id
    system {
      codename
    }
    elements {
      authors {
        value
      }
      title {
        value
      }
      publishing_date {
        value
      }
      conference {
        value {
          codename
          name
        }
      }
      image {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      category__publications_poster {
        value {
          name
        }
      }
      download {
        value {
          ...KontentItemAsset
        }
      }
    }
  }
`;

KontentItemScientificPosterCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
};

KontentItemScientificPosterCards.propTypes = {
  limit: null,
};

export default KontentItemScientificPosterCards;
