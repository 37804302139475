import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import FormsListElement from "./formsListElement";

const FormsCategoryListElements = ({ categories }) => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemForm {
        nodes {
          ...FormsListElement
        }
      }
    }
  `);

  const filteredData = [];
  data.allKontentItemForm.nodes.forEach((node) => filteredData.push(node));

  _.remove(filteredData, (currentObject) => {
    // Remove object if not in categories
    let remove = true;
    currentObject.elements.resource_type.value.forEach((category) => {
      if (categories.includes(category.codename)) {
        remove = false;
      }
    });

    return remove;
  });

  if (data.allKontentItemForm) {
    return FormsListElement({ data: filteredData });
  }

  return null;
};

FormsCategoryListElements.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]),
};

FormsCategoryListElements.defaultProps = {
  categories: [],
};

export default FormsCategoryListElements;
