import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import CircularIconDepartment from "../../components/circularIcon/circularIconDepartment";
import DepartmentTile from "../../components/departmentTile/departmentTile";
import KontentAssetsImage from "../kontentAssetsImage";
import { getSidebarNavigationNode } from "../utils/navigationHelper";

const DepartmentOverviewTiles = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
      primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                    elements {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                            elements {
                              value {
                                ... on kontent_item_navigation_item {
                                  id
                                  elements {
                                    title {
                                      value
                                    }
                                    url_slug {
                                      value
                                    }
                                    external_url {
                                      value
                                    }
                                    elements {
                                      value {
                                        ... on kontent_item_department {
                                          id
                                          elements {
                                           icon {
                                              value {
                                                description
                                                height
                                                size
                                                name
                                                type
                                                url
                                                width
                                              }
                                            }
                                            lead_text {
                                              value
                                            }
                                            headline {
                                              value
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { sidebarLevel } = getSidebarNavigationNode(id, data);

  const items = [];

  sidebarLevel.elements.elements.value.forEach((item) => {
    if (!item.id) {
      return null;
    }

    item.elements.elements.value.forEach((departmentItem) => {
      // eslint-disable-next-line no-underscore-dangle
      if (!departmentItem.id && departmentItem.__typename !== "kontent_item_department") {
        return null;
      }

      items.push(DepartmentTile(
        {
          href: item.id,
          key: item.id,
          media: departmentItem.elements.icon.value.length > 0
            ? (
              <CircularIconDepartment
                image={(
                  <KontentAssetsImage
                    data={departmentItem.elements.icon.value[0]}
                    height={30}
                    width={30}
                  />
                )}
              />
            )
            : null,
          name: item.elements.title.value,
          text: departmentItem.elements.lead_text.value,
        },
      ));

      return null;
    });

    return null;
  });

  return items;
};

DepartmentOverviewTiles.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DepartmentOverviewTiles;
