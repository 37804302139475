import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import QuickLinkTile from "../components/quickLinkTile/quickLinkTile";

const KontentItemServiceCard = ({ data }) => {
  const card = data.value ? data.value[0] : data;

  if (!card || !card.elements) {
    return null;
  }

  return (
    <QuickLinkTile
      headline={card.elements.headline.value}
      url={card.elements.internal_url.value.length > 0
        ? card.elements.internal_url.value[0].id
        : "#"}
      text={card.elements.text.value}
      tag={card.elements.icon.value.length > 0
        ? (
          <KontentAssetsImage
            data={card.elements.icon.value[0]}
            height={48}
            className="img-fluid"
          />
        )
        : null}
      isRect
    />
  );
};

export const query = graphql`
  fragment KontentItemServiceCard on kontent_item_service_card {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      internal_url {
        value {
          id
        }
      }
      icon {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
    }
  }
`;

KontentItemServiceCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemServiceCard;
