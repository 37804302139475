import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import StoryTile from "../components/storyTile/storyTile";

const KontentItemMilestone = ({ data }) => {
  const milestoneData = Array.isArray(data) ? data : [data];

  const newsList = [];

  milestoneData.forEach((milestone) => {
    newsList.push(
      <StoryTile
        number={milestone.elements.number.value}
        title={milestone.elements.headline.value}
        text={milestone.elements.text.value}
      />,
    );
  });

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment KontentItemMilestone on kontent_item_milestone {
    id
    system {
      codename
    }
    elements {
      number {
        value
      }
      headline {
        value
      }
      text {
        value
      }
    }
  }
`;

KontentItemMilestone.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMilestone;
