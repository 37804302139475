import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useEventListener from "../../hooks/haiku/useEventListener";

import "./banner.scss";

const Banner = (props) => {
  const {
    className,
    mediaObject,
    children,
    mediaObjectTop,
  } = props;

  const [offset, setOffset] = useState(0);
  const [parallax, setParallax] = useState(false);

  const parallaxElement = useRef(null);

  const speed = 2;
  const distance = 60;

  const calculateParallax = () => {
    if (parallax && parallaxElement.current !== null) {
      const parallaxHeight = parallaxElement.current.offsetHeight;
      const positionTop = parallaxElement.current.getBoundingClientRect().top;
      const positionBottom = positionTop + parallaxHeight;

      if (positionBottom > 0 && typeof window !== "undefined" && positionTop < window.innerHeight) {
        const factor = (positionTop + parallaxHeight) / (window.innerHeight + parallaxHeight);

        return (distance * (1 - factor) - distance / 2) * speed;
      }

      return offset;
    }

    return 0;
  };

  const scroll = () => {
    setOffset(calculateParallax());
  };

  const resize = () => {
    if (typeof window !== "undefined") {
      setParallax(window.innerWidth >= 768);
    }

    setOffset(calculateParallax());
  };

  useEventListener("resize", resize);
  useEventListener("scroll", scroll);

  useEffect(() => {
    resize();
  }, []);

  return (
    <div className={classNames("banner", className)}>
      <Row className="row-xs-only--fullwidth row-sm-only--fullwidth">
        <Col
          md={12}
          lg={10}
          className="col-overlap-left-md-11 col-overlap-left-lg-10 banner__container"
        >
          <div className="container-fluid p-md-0">
            {children}
          </div>
        </Col>

        <Col
          md={{ offset: 7, span: 5 }}
          style={{ bottom: offset }}
          className={classNames("banner__media-container", { "order-first order-md-last": mediaObjectTop })}
        >
          <div ref={parallaxElement}>
            {mediaObject}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  mediaObject: PropTypes.node.isRequired,
  mediaObjectTop: PropTypes.bool,
};

Banner.defaultProps = {
  mediaObjectTop: true,
};

export default Banner;
