import React from "react";

import { graphql } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import NavigationLink from "../components/navigation/navigationLink";

const KontentItemCtaButton = ({ data }) => {
  const button = data;

  if (!button || !button.elements) {
    return null;
  }

  return (
    <NavigationLink
      className={`btn btn-${_.kebabCase(button.elements.cta_theme.value[0].codename)}`}
      href={
        button.elements.button_url.value.length > 0
          ? button.elements.button_url.value[0].id
          : button.elements.button_url_ext.value
      }
      activeClassName={null}
    >
      {button.elements.button_text.value}
    </NavigationLink>
  );
};

export const query = graphql`
  fragment KontentItemCtaButton on kontent_item_cta_button {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      cta_theme {
        value {
          codename
        }
      }
      button_url {
          value {
              id
          }
      }
      button_url_ext {
        value
      }
    }
  }
`;

KontentItemCtaButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCtaButton;
