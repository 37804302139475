import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import anchorButtons from "./anchorButtons";
import IconText from "../../components/iconText/iconText";
import InfoBar from "../../components/infoBar/infoBar";
import InfoSection from "../../components/infoBar/infoSection";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import { IconPdf18 } from "../../icons";
import KontentItemGlossaryItem from "../kontentItemGlossaryItem";
import SimpleRichText from "../text/simpleRichText";

const InfoBarSnippet = (props) => {
  const {
    infoBarHeadline,
    quicklinksTitle,
    quicklinkTextSource,
    linksTitle,
    externalUrl,
    contactDataTitle,
    contactData,
    contactTitle,
    contactMail,
    contactPhone,
    downloadsTitle,
    download,
    glossaryTitle,
    glossaryItems,
    enableSocialMedia,
  } = props;

  return (
    <InfoBar headline={infoBarHeadline}>
      {
        anchorButtons(
          quicklinkTextSource,
          quicklinksTitle,
        )
      }

      {linksTitle && externalUrl.value !== "" && (
        <InfoSection sectionHeadline={linksTitle}>
          <SimpleRichText data={externalUrl} />
        </InfoSection>
      )}

      {contactDataTitle && contactData.value !== "" && (
        <InfoSection sectionHeadline={contactDataTitle}>
          <SimpleRichText data={contactData} />
        </InfoSection>
      )}

      {contactTitle && (contactMail.value !== "" || contactPhone.value !== "") && (
        <InfoSection sectionHeadline={contactTitle}>
          <SimpleRichText data={contactMail} />
          <SimpleRichText data={contactPhone} />
        </InfoSection>
      )}

      {download && download.length > 0 && (
        <InfoSection sectionHeadline={downloadsTitle} className="info-section__downloads">
          {download.map((item) => {
            if (!item.id) {
              return null;
            }

            const link = item.elements.asset_item.value.length > 0 ? item.elements.asset_item.value[0].url : "#";

            return (
              <a
                key={item.id}
                download
                href={link}
                className="no-style"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconText
                  icon={<IconPdf18 />}
                  variant="primary"
                  text={item.elements.title.value}
                />
              </a>
            );
          })}
        </InfoSection>
      )}

      {glossaryTitle && glossaryItems.length > 0 && (
        <InfoSection sectionHeadline={glossaryTitle}>
          {glossaryItems.map((item) => (
            <KontentItemGlossaryItem data={item} key={item.id} disableDescription />
          ))}
        </InfoSection>
      )}

      {enableSocialMedia && (
        <InfoSection sectionHeadline={(<FormattedMessage id="socialmedia.share" />)}>
          <SocialMediaShare onlyIcon />
        </InfoSection>
      )}

    </InfoBar>
  );
};

InfoBarSnippet.propTypes = {
  contactData: PropTypes.oneOfType([PropTypes.object]),
  contactDataTitle: PropTypes.string,
  contactMail: PropTypes.oneOfType([PropTypes.object]),
  contactPhone: PropTypes.oneOfType([PropTypes.object]),
  contactTitle: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadsTitle: PropTypes.string,
  enableSocialMedia: PropTypes.bool,
  externalUrl: PropTypes.oneOfType([PropTypes.object]),
  glossaryItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  glossaryTitle: PropTypes.string,
  infoBarHeadline: PropTypes.string,
  linksTitle: PropTypes.string,
  quicklinkTextSource: PropTypes.string,
  quicklinksTitle: PropTypes.string,
};

InfoBarSnippet.defaultProps = {
  contactData: null,
  contactDataTitle: null,
  contactMail: null,
  contactPhone: null,
  contactTitle: null,
  download: null,
  downloadsTitle: null,
  enableSocialMedia: false,
  externalUrl: null,
  glossaryItems: null,
  glossaryTitle: null,
  infoBarHeadline: null,
  linksTitle: null,
  quicklinkTextSource: null,
  quicklinksTitle: null,
};

export default InfoBarSnippet;
