import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleModule from "../components/articleModule/articleModule";
import EmbedMediaPlayer from "../components/embedMediaPlayer/embedMediaPlayer";

const KontentItemVideoEmbed = (props) => {
  const {
    data,
    variant,
    ltr,
    noLength,
    previewImageSizeByParent,
  } = props;

  const video = data.value ? data.value[0] : data;

  if (!video || !video.elements) {
    return null;
  }

  let extraClasses = "";
  let child = (
    <EmbedMediaPlayer
      videoUrl={video.elements.url.value}
      previewImageUrl={`${video.elements.image.value[0].url}?w=400&h=400`}
      title={video.elements.title.value}
      length={noLength ? null : video.elements.duration.value}
      variant={variant}
      ltr={ltr}
    />
  );

  if (video.elements.video_component_style.value.length > 0 && !previewImageSizeByParent) {
    const { codename } = video.elements.video_component_style.value[0];

    if (codename === "panorama_21_9") {
      extraClasses = "text-section__fullwidth";
      child = (
        <div className="mx-sm-4">
          <ArticleModule caption={null}>
            {React.cloneElement(child,
              {
                previewImageUrl: `${video.elements.image.value[0].url}?w=1920&h=${(1920 / 21) * 9}`,
                variant: null,
              })}
          </ArticleModule>
        </div>
      );
    } else if (codename === "block_image_right" || codename === "block_image_left") {
      child = (
        <div className="embed-responsive-item">
          {React.cloneElement(child,
            {
              previewImageUrl: `${video.elements.image.value[0].url}?w=500&h=500`,
              variant: null,
            })}
        </div>
      );
      extraClasses = classNames("p-0 col-12 col-md-4 embed-responsive embed-responsive-1by1",
        { "float-right mt-0 ml-5": codename === "block_image_right" },
        { "float-left mt-0 mr-5": codename === "block_image_left" });
    }
  }

  return <div className={classNames(extraClasses, "video-container")}>{child}</div>;
};

export const query = graphql`
  fragment KontentItemVideoEmbed on kontent_item_video_embed {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      url {
        value
      }
      duration {
        value
      }
      image {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      video_component_style {
        value {
          name
          codename
        }
      }
    }
  } 
`;

KontentItemVideoEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ltr: PropTypes.bool,
  noLength: PropTypes.bool,
  previewImageSizeByParent: PropTypes.bool,
  variant: PropTypes.string,
};

KontentItemVideoEmbed.defaultProps = {
  ltr: false,
  noLength: false,
  previewImageSizeByParent: false,
  variant: "media-gallery",
};

export default KontentItemVideoEmbed;
