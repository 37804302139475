import React from "react";

import PropTypes from "prop-types";

import "./contactBox.scss";

const ContactBox = ({ title, text, contactPerson }) => (
  <div className="contact-box">
    {(title || text) && (
      <div className="contact-box__header">
        {title && <div className="typo-title3">{title}</div>}
        {text && <p>{text}</p>}
      </div>
    )}
    {contactPerson}
  </div>
);

ContactBox.propTypes = {
  contactPerson: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContactBox;
