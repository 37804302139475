import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import IconText from "../components/iconText/iconText";
import QuickLinkTile from "../components/quickLinkTile/quickLinkTile";
import { IconDkmsServices18 } from "../icons";

const KontentItemCategoryLinkCard = ({ data }) => {
  const card = data.value ? data.value[0] : data;

  if (!card || !card.elements) {
    return null;
  }

  return (
    <QuickLinkTile
      tag={(
        <IconText
          icon={<IconDkmsServices18 />}
          variant="grey-dark"
          text={card.elements.publications.value.length > 0
            ? card.elements.publications.value[0].name
            : ""}
        />
      )}
      headline={card.elements.headline.value}
      url={card.elements.button_url.value.length > 0
        ? card.elements.button_url.value[0].id
        : "#"}
      text={card.elements.text.value}
      key={card.id}
      image={(
        card.elements.image.value.length > 0
          ? <KontentItemImage data={card.elements.image} aspectRatio={21 / 9} />
          : null
      )}
      isType
    />
  );
};

export const query = graphql`
  fragment KontentItemCategoryLinkCard on kontent_item_category_link_card {
    id
    system {
      codename
    }
    elements {
      publications {
        value {
          name
          codename
        }
      }
      headline {
        value
      }
      text {
        value
      }
      button_url {
        value {
          id
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemCategoryLinkCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCategoryLinkCard;
