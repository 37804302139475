import { graphql, useStaticQuery } from "gatsby";

import NewsCard from "./newsCard";

const LatestEventCards = () => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemNewsArticle(filter: {elements: {categories: {value: {elemMatch: {codename: {eq: "event"}}}}}}, sort: {fields: elements___publishdate___value, order: ASC}) {
        nodes {
          ...NewsCard
        }
      }
    }
  `);

  const articles = data.allKontentItemNewsArticle.nodes.slice(0, 10);

  if (data.allKontentItemNewsArticle) {
    return NewsCard({
      currentFirst: true, data: articles, limit: 10, variant: "events",
    });
  }

  return null;
};

export default LatestEventCards;
