import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import OverviewText from "../../components/overviewText/overviewText";
import Section from "../../components/section/section";
import SEO from "../../components/seo";
import KontentItemPublicationsSection from "../kontentItemPublicationsSection";
import KontentItemServiceCard from "../kontentItemServiceCard";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemResearchPublicationsOverview = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <OverviewText headline={page.elements.headline.value}>
        {page.elements.lead_text.value}
      </OverviewText>

      <Divider isFullWidth />

      <Section
        headline={page.elements.headline_section_1.value}
      >
        {page.elements.cards.value.map((service) => (
          <KontentItemServiceCard data={service} key={service.id} />
        ))}
      </Section>

      <KontentItemPublicationsSection data={page.elements.publications} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemResearchPublicationsOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_headline {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        headline_section_1 {
          value
        }
        cards {
          value {
            ...KontentItemServiceCard
          }
        }
        publications {
          value {
            ...KontentItemPublicationsSection
          }
        }
      }
    }
  }
`;

KontentItemResearchPublicationsOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemResearchPublicationsOverview;
