import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemAsset from "./kontentItemAsset";
import Section from "../components/section/section";

const KontentItemDownloadsSection = ({ data, isLab }) => {
  const downloadSection = data.value ? data.value[0] : data;

  if (!downloadSection || !downloadSection.elements) {
    return null;
  }

  let colProps = {
    md: 6,
  };

  if (isLab) {
    colProps = {
      lg: 4,
      md: 6,
    };
  }

  return (
    <Section
      headline={downloadSection.elements.headline.value}
      text={downloadSection.elements.text.value}
      colProps={colProps}
    >
      {downloadSection.elements.downloads.value.map((download) => (
        <KontentItemAsset data={download} key={download.id} />
      ))}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemDownloadsSection on kontent_item_downloads_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      downloads {
        value {
          ...KontentItemAsset
        }
      }
    }
  } 
`;

KontentItemDownloadsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isLab: PropTypes.bool,
};

KontentItemDownloadsSection.defaultProps = {
  isLab: false,
};

export default KontentItemDownloadsSection;
