import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleHeadline from "../../components/articleHeadline/articleHeadline";
import LinkButton from "../../components/button/linkButton";
import Divider from "../../components/divider/divider";
import FileDownloadGrid from "../../components/fileDownloadGrid/fileDownloadGrid";
import IconText from "../../components/iconText/iconText";
import InfoBar from "../../components/infoBar/infoBar";
import InfoSection from "../../components/infoBar/infoSection";
import MainLayout from "../../components/mainLayout";
import RelatedStudy from "../../components/relatedStudy/relatedStudy";
import RelatedStudyGrid from "../../components/relatedStudyGrid/relatedStudyGrid";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeader from "../../components/textHeader/textHeader";
import {
  IconAuthor18,
  IconExternalLink18,
  IconFurther18,
  IconMail18,
  IconStudy18,
  IconTopic18,
} from "../../icons";
import KontentItemAsset from "../kontentItemAsset";
import KontentItemJournal from "../kontentItemJournal";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import SimpleRichText from "../text/simpleRichText";

const KontentItemStudy = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <TextHeader
        small
        headline={page.elements.title.value}
        icon={(
          <IconText
            icon={<IconStudy18 className="identifier" />}
            variant="primary"
            text={(
              <div className="typo-label">
                <span>{page.elements.identifier.value}</span>
                {page.elements.studies_category.value.length > 0 && (
                  <span className="ml-4 text-grey-dark">{page.elements.studies_category.value[0].name}</span>
                )}
              </div>
            )}
          />
        )}
      />

      <SidebarLayout
        isRight
        sidebar={(
          <InfoBar headline={<FormattedMessage id="publication.details" />}>
            {page.elements.contact_person.value.length > 0 && (
              <InfoSection
                sectionIcon={<IconAuthor18 />}
                sectionHeadline={(<FormattedMessage id="study.contact_person" />)}
              >
                {page.elements.contact_person.value}
              </InfoSection>
            )}

            {page.elements.contact_e_mail.value.length > 0 && (
              <InfoSection
                sectionIcon={<IconMail18 />}
                sectionHeadline={(<FormattedMessage id="study.contact_email" />)}
              >
                <SimpleRichText data={page.elements.contact_e_mail} />
              </InfoSection>
            )}

            {page.elements.studies_type.value.length > 0 && (
              <InfoSection
                sectionIcon={<IconTopic18 />}
                sectionHeadline={(<FormattedMessage id="resourcessection.type" />)}
              >
                {page.elements.studies_type.value[0].name}
              </InfoSection>
            )}
          </InfoBar>
        )}
      >

        <RichText data={page.elements.body} isSidebarLayout />

        <div className="d-xl-none">
          <Divider />
        </div>

        {page.elements.downloads.value
        && page.elements.downloads.value.length > 0 && (
          <ArticleHeadline headline={<FormattedMessage id="publication.downloads" />}>
            <FileDownloadGrid useInSidebarLayout>
              {page.elements.downloads.value.map((item) => (
                <KontentItemAsset data={item} key={item.id} />
              ))}
            </FileDownloadGrid>
          </ArticleHeadline>
        )}

        {data.page.elements.related_publication.value.length > 0 && (<Divider />)}

        {page.elements.related_publication
        && page.elements.related_publication.value.length > 0
        && (
          <ArticleHeadline headline={<FormattedMessage id="study.related_publications" />}>
            <RelatedStudyGrid useInSidebarLayout>
              {page.elements.related_publication.value.map((item) => (
                <RelatedStudy
                  key={item.id}
                  headline={(
                    item.elements.journal_name.value.length > 0
                      ? (
                        <KontentItemJournal
                          data={item.elements.journal_name.value[0]}
                          issueNumber={
                            item.elements.journal_issue_number.value.length > 0
                              ? item.elements.journal_issue_number.value
                              : null
                          }
                        />
                      )
                      : null
                  )}
                  headlineText={item.elements.title.value}
                  button={(
                    <>
                      <div>
                        <LinkButton className="btn-light-registration mb-4" href={item.id}>
                          <FormattedMessage id="publication.view_details" />
                          <IconFurther18 />
                        </LinkButton>
                      </div>
                      <div>
                        <LinkButton className="btn-light-registration" href={item.elements.external_url.value}>
                          <FormattedMessage id="publication.go_to" />
                          <IconExternalLink18 />
                        </LinkButton>
                      </div>
                    </>
                  )}
                />
              ))}
            </RelatedStudyGrid>
          </ArticleHeadline>
        )}

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemStudy(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        title {
          value
        }
        identifier {
          value
        }
        contact_person {
          value
        }
        contact_e_mail {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        downloads {
          value {
            internal {
              type
            }
            ...KontentItemAsset
          }
        }
        studies_type {
          value {
            name
            codename
          }
        }
        studies_category {
          value {
            name
            codename
          }
        }
        related_publication {
          value {
            internal {
              type
            }
            ... on kontent_item_publication {
              id
              elements {
                title {
                  value
                }
                external_url {
                  value
                }
                journal_name {
                  value {
                    internal {
                      type
                    }
                    ... on kontent_item_journal {
                      id
                      elements {
                        icon {
                          value {
                            url
                            height
                            width
                            description
                          }
                        }
                        journal {
                          value {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                journal_issue_number {
                  value
                }
                url_slug {
                  value
                }
              }
            }
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemStudy.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemStudy;
