import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemHomeTile from "./kontentItemHomeTile";
import CardSlider from "../components/cardSlider/cardSlider";
import Hubsection from "../components/hubsection/hubsection";
import Section from "../components/section/section";
import useWindowDimensions from "../hooks/useWindowDimensions";

const KontentItemHubSectionHome = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }
  const { width } = useWindowDimensions();

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      colProps={{ xs: 12 }}
    >
      {width >= 768
        ? (
          <Hubsection twoCols>
            {section.elements.section_tiles.value.map(
              (item) => <KontentItemHomeTile key={item.id} data={item} />,
            )}
          </Hubsection>
        )
        : (
          <CardSlider>
            {section.elements.section_tiles.value.map(
              (item) => <KontentItemHomeTile key={item.id} data={item} />,
            )}
          </CardSlider>
        )}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemHubSectionHome on kontent_item_hub_section_home {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      section_tiles {
        value {
          ...KontentItemHomeTile
        }
      }
    }
  }
`;

KontentItemHubSectionHome.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHubSectionHome;
