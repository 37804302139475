import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./eyecatcher.scss";

const Eyecatcher = (props) => {
  const {
    title,
    children,
    variant,
    text,
  } = props;

  return (
    <div className="text-section__fullwidth">
      <div className={classNames("eyecatcher", variant && `eyecatcher--${variant}`)}>
        <div className="eyecatcher__title">
          <h3>{title}</h3>
          {text && (<div className="eyecatcher__text">{text}</div>)}
        </div>
        {children && (
          <div className="eyecatcher__elements-wrapper">
            <div className="eyecatcher__elements">
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Eyecatcher.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

Eyecatcher.defaultProps = {
  children: null,
  text: null,
  variant: null,
};

export default Eyecatcher;
