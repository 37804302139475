import React from "react";

import PropTypes from "prop-types";

import { format } from "../../utils/dateHelper";
import Divider from "../divider/divider";
import NavigationLink from "../navigation/navigationLink";

import "./scientificPoster.scss";

const ScientificPoster = (props) => {
  const {
    href,
    image,
    date,
    title,
    author,
    button,
    name,
  } = props;

  return (
    <div className="scientific-poster">
      <div className="d-md-flex">
        {image !== null && (
          <NavigationLink href={href} className="scientific-poster__image no-style">
            {image}
          </NavigationLink>
        )}
        <div className="scientific-poster__content">
          <NavigationLink href={href} className="no-style">
            <div className="scientific-poster__top">
              <div className="typo-label">{name !== null && `${name},`}</div>
              {date !== null && <div className="scientific-poster__date">{format(date)}</div>}
            </div>
            <div className="scientific-poster__title">
              {title}
            </div>
            {author !== null && (
              <div className="scientific-poster__author">
                {author}
              </div>
            )}
          </NavigationLink>
          {button !== null && (
            <div className="scientific-poster__button">
              {button}
            </div>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
};

ScientificPoster.propTypes = {
  author: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.object]),
  date: PropTypes.instanceOf(Date),
  href: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ScientificPoster.defaultProps = {
  author: null,
  button: null,
  date: null,
  href: null,
  image: null,
  name: null,
};

export default ScientificPoster;
