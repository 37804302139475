import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import useEventListener from "../../hooks/haiku/useEventListener";
import { IconPlay48 } from "../../icons";
import MediaLightbox from "../lightbox/mediaLightbox";

import "./embedMediaPlayer.scss";

const EmbedMediaPlayer = (props) => {
  const {
    videoUrl,
    previewImageUrl,
    title,
    length,
    variant,
    ltr,
  } = props;

  const externalContentEnabled = useSelector((state) => (
    state.complianceConsent.externalContentEnabled
  ));

  const [bigPadding, setBigPadding] = useState(false);
  const [titleMaxHeight, setTitleMaxHeight] = useState(null);

  const player = useRef();
  const mediaModal = useRef();

  const resize = () => {
    const padding = player.current.getBoundingClientRect().width > 421;
    const heightOfComponents = 124;
    const videoTitleHeight = Math.floor((
      player.current.getBoundingClientRect().height - heightOfComponents
    ) / 30) * 30;
    const maxHeight = `${videoTitleHeight <= 0 ? 30 : videoTitleHeight}px`;

    setBigPadding(padding);
    setTitleMaxHeight(maxHeight);
  };

  useEffect(() => {
    resize();
  }, []);

  useEventListener("resize", resize);

  let embedVideoUrl = videoUrl;

  if (embedVideoUrl.indexOf("&") !== -1) {
    embedVideoUrl = embedVideoUrl.substring(0, embedVideoUrl.indexOf("&"));
  }

  embedVideoUrl = embedVideoUrl
    .replace("youtube.com", "youtube-nocookie.com")
    .replace("watch?v=", "embed/")
    .replace("vimeo.com", "player.vimeo.com/video");

  if (embedVideoUrl.indexOf("vimeo.com") !== -1) {
    embedVideoUrl += "?title=0&byline=0&portrait=0";
  }

  if (embedVideoUrl.indexOf("youtu.be") !== -1) {
    embedVideoUrl = `https://www.youtube-nocookie.com/embed/${embedVideoUrl.substring(embedVideoUrl.lastIndexOf("/") + 1)}`;
  }

  return (
    <div
      className={classNames("embed-responsive", "embed-media-player", variant && `embed-media-player--${variant}`, { "embed-media-player--ltr": ltr })}
      onClick={() => mediaModal.current.show()}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          mediaModal.current.show();
          event.stopPropagation();
        }
      }}
      role="presentation"
      ref={player}
    >
      <div
        className="embed-responsive-item embed-media-player__image"
        style={{ backgroundImage: `url(${previewImageUrl})` }}
      >
        <div
          className={classNames("embed-media-player__linear-gradient", { "embed-media-player__linear-gradient--dark": title })}
        />
        <div
          className={classNames("embed-media-player__content-info", { "embed-media-player__content-info--big-padding": bigPadding })}
        >
          <div className="embed-media-player__icon"><IconPlay48 /></div>
          {title && (
            <div
              className="embed-media-player__title typo-title3"
              style={variant === "learn-module"
                ? { maxHeight: "9rem", whiteSpace: "normal" }
                : { maxHeight: titleMaxHeight }}
            >
              {title}
            </div>
          )}
          {length && (
            <div className="embed-media-player__length typo-small-label">
              {length}
            </div>
          )}
        </div>
        <MediaLightbox
          media={(
            <iframe
              title="video"
              className="embed-responsive-item border-0"
              src={`${embedVideoUrl}?rel=0&autoplay=${externalContentEnabled ? 1 : 0}`}
              sandbox
              allow="autoplay; fullscreen;"
            />
          )}
          ref={mediaModal}
        />
      </div>
    </div>
  );
};

EmbedMediaPlayer.propTypes = {
  length: PropTypes.string,
  ltr: PropTypes.bool,
  previewImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["banner", "media-gallery", "keyfact-area", "learn-module", "16by9", "4by3"]),
  videoUrl: PropTypes.string.isRequired,
};

EmbedMediaPlayer.defaultProps = {
  length: null,
  ltr: false,
  title: null,
  variant: null,
};

export default EmbedMediaPlayer;
