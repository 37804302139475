import React from "react";

import AnchorLink from "react-anchor-link-smooth-scroll";

import InfoSection from "../../components/infoBar/infoSection";

const anchorButtons = (text, headline = "") => {
  if (!text) {
    return null;
  }

  const titles = text.match(/<h3>.*<\/h3>/g);
  const output = [];

  if (titles && titles.length > 2) {
    titles.forEach((item, index) => {
      output.push((
        <AnchorLink
          className="anchor-button"
          href={`#anchor-point-${index + 1}`}
          key={`#anchor-point-${index + 1}`}
        >
          {index + 1}. {item.replace(/<[^>]*>?/gm, "")}
        </AnchorLink>
      ));
    });

    return (
      <InfoSection sectionHeadline={headline}>
        {output}
      </InfoSection>
    );
  }

  return null;
};

export default anchorButtons;
