const Templates = {};

Templates.TextInput = require("./formField/formField").default;
Templates.CheckboxInput = require("./checkbox/checkbox").default;
Templates.RadioInput = require("./radioButtons/radioButtons").default;
Templates.SelectInput = require("./dropdownFormField/dropdownFormField").default;
Templates.PhoneInput = require("./phoneFormField/phoneFormField").default;
Templates.TextAreaInput = require("./textareaFormField/textareaFormField").default;
Templates.FormSectionHeader = require("./sectionHeader/sectionHeader").default;

export default Templates;
