import React from "react";

import PropTypes from "prop-types";

import CardSlider from "../cardSlider/cardSlider";
import TextSection from "../textSection/textSection";

import "./partnerSection.scss";

const PartnerSection = ({ children, headline, text }) => (
  <div className="partner-section">
    <TextSection
      headline={headline}
      text={text}
    />
    <CardSlider>
      {children}
    </CardSlider>
  </div>
);

PartnerSection.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
  text: PropTypes.string,
};

PartnerSection.defaultProps = {
  headline: null,
  text: null,
};

export default PartnerSection;
