import React from "react";

import KontentItemHome from "../templates/pages/kontentItemHome";
import KontentItemHomeLab from "../templates/pages/kontentItemHomeLab";

const IndexPage = () => {
  const site = process.env.GATSBY_SITE;

  if (site === "labs") {
    return <KontentItemHomeLab />;
  }

  return <KontentItemHome />;
};

export default IndexPage;
