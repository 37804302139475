const getUrnParams = (urn, secondaryUrn) => {
  if (
    (!urn || urn.trim() === "")
    && (!secondaryUrn || secondaryUrn.trim() === "")
  ) {
    return null;
  }

  const refs = [];

  if (urn) {
    refs.push(urn.trim());
  }

  if (secondaryUrn) {
    refs.concat(secondaryUrn.split("\n").map((item) => item.trim()));
  }

  return { refs };
};

export default getUrnParams;
