import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemContact from "./kontentItemContact";
import ContactBox from "../components/contactBox/contactBox";

const KontentItemContactBox = ({ data }) => {
  const contactFaq = data.value ? data.value[0] : data;

  if (!contactFaq || !contactFaq.elements) {
    return null;
  }

  return (
    <ContactBox
      title={contactFaq.elements.title.value}
      text={contactFaq.elements.text.value}
      contactPerson={(
        <KontentItemContact data={contactFaq.elements.contact} />
      )}
    />
  );
};

export const query = graphql`
  fragment KontentItemContactBox on kontent_item_contact_box {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      text {
        value
      }
      contact {
        value {
          ...KontentItemContact
        }
      }
      button_text {
        value
      }
      button_url_webform {
        value
      }
      button_phone {
        value
      }
      button_mailto {
        value
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemContactBox.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemContactBox;
