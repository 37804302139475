import React, { useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconExpand24, IconMinimize24 } from "../../icons";
import IconButton from "../button/iconButton";

import "./resourcesSection.scss";

const ResourcesSection = ({ children, headline }) => {
  let resources = [];

  const [numberOfShownArticle, setNumberOfShownArticle] = useState(6);

  if (typeof children !== "undefined") {
    resources = Array.isArray(children) ? children : [children];
  }

  return (
    <Row className="resources-section">
      <Col md={8}>
        <div className="resources-section__headline">
          {headline} {`(${resources.length})`}
        </div>
      </Col>
      <Col xs={12} className="d-none d-xl-block">
        <Row className="resources-section__list-columns">
          <Col xl={2} className="typo-label">
            <FormattedMessage id="resourcessection.name" />
          </Col>
          <Col xl={4} className="typo-label">
            <FormattedMessage id="resourcessection.description" />
          </Col>
          <Col xl={3} className="typo-label">
            <FormattedMessage id="resourcessection.type" />
          </Col>
          <Col xl={3} className="typo-label">
            <FormattedMessage id="resourcessection.actions" />
          </Col>
        </Row>
      </Col>
      {
        React.Children.map(resources.length > numberOfShownArticle
          ? resources.slice(0, numberOfShownArticle)
          : resources, (child, index) => (
            <Col xs={12} className={index > 0 && "mt-5"}>
              {child}
            </Col>
        ))
      }
      {React.Children.count(resources) > 6
      && (React.Children.count(resources) > numberOfShownArticle
        ? (
          <div className="resources-section__load-more-button">
            <IconButton
              variant="outline-faq"
              onClick={() => {
                setNumberOfShownArticle(numberOfShownArticle + 3);
              }}
            >
              <FormattedMessage id="forms.load_more" /> <IconExpand24 />
            </IconButton>
          </div>
        )
        : (
          <div className="resources-section__load-more-button">
            <IconButton
              variant="outline-faq"
              onClick={() => {
                setNumberOfShownArticle(6);
              }}
            >
              <FormattedMessage id="forms.show_less" /> <IconMinimize24 />
            </IconButton>
          </div>
        ))}
    </Row>
  );
};

ResourcesSection.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default ResourcesSection;
