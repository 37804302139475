import React, { memo } from "react";

import { ImageElement } from "@kentico/gatsby-kontent-components";
import PropTypes from "prop-types";

const KontentAssetsImage = (props) => {
  const {
    aspectRatio,
    data,
    height,
    width,
    fit,
    className,
  } = props;

  if (!data || !data.url) {
    return null;
  }

  const ratio = aspectRatio || data.height / data.width;
  let extendedProps = {};

  let generatedHeight = null;
  let generatedWidth = null;

  if (!height && width && !aspectRatio) {
    generatedHeight = (width * data.height) / data.width;
  }

  if (height && !width && !aspectRatio) {
    generatedWidth = (height * data.width) / data.height;
  }

  if (height && width && fit === "contain") {
    extendedProps = {
      objectFit: "contain",
      objectPosition: "0% 50%",
    };
  }

  return (
    <ImageElement
      aspectRatio={ratio}
      image={data}
      alt={data.description}
      height={height || generatedHeight || width * ratio}
      width={width || generatedWidth || height * ratio}
      layout="constrained"
      className={className}
      options={{
        fit: fit || "crop",
        lossless: true,
      }}
      {...extendedProps}
    />
  );
};

KontentAssetsImage.propTypes = {
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  fit: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

KontentAssetsImage.defaultProps = {
  aspectRatio: null,
  className: null,
  fit: null,
  height: 0,
  width: 0,
};

export default memo(KontentAssetsImage);
