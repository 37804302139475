import React from "react";

import PropTypes from "prop-types";

import LinkButton from "../../components/button/linkButton";
import SidebarMenu from "../../components/sidebarMenu/sidebarMenu";
import { getSidebarNavigationNode } from "../utils/navigationHelper";

const SidebarNavigationGenerator = ({ id }) => {
  if (id) {
    const items = [];

    const { sidebarLevel, navId } = getSidebarNavigationNode(id);

    if (sidebarLevel) {
      sidebarLevel.elements.elements.value.forEach((item) => {
        if (!item.id) {
          return null;
        }

        const subitems = [];

        if (item.elements.elements && item.elements.elements.value.length > 0) {
          item.elements.elements.value.forEach((subitem) => {
            if (!subitem.id) {
              return null;
            }

            subitems.push(
              {
                id: subitem.id,
                item: (
                  <LinkButton href={subitem.id}>{subitem.elements.title.value}</LinkButton>
                ),
              },
            );

            return null;
          });
        }
        const test = subitems.filter((subitemTest) => subitemTest.id === navId);

        items.push(
          {
            id: item.id,
            item: (<LinkButton activeClassName={test.length > 0 ? null : "active"} href={item.id}>{item.elements.title.value}</LinkButton>),
            subitems,
          },
        );

        return null;
      });
    }

    if (items.length > 0) {
      return <SidebarMenu items={items} />;
    }
  }

  return null;
};

SidebarNavigationGenerator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SidebarNavigationGenerator;
