import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SimpleRichText from "./text/simpleRichText";
import CommonQuestions from "../components/commonQuestions/commonQuestions";
import FaqSection from "../components/faqSection/faqSection";

const KontentItemFaqSection = ({ data, faqSectionWithImage, isPageSection }) => {
  const faqSection = data.value ? data.value[0] : data;

  if (!faqSection || !faqSection.elements) {
    return null;
  }

  const faqItems = [];

  faqSection.elements.section_items.value.forEach((item) => {
    faqItems.push({
      answer: <SimpleRichText data={item.elements.answer} />,
      id: item.id,
      question: item.elements.question.value,
    });
  });

  // todo: g2 maybe remove template flag
  if (faqSectionWithImage) {
    return (
      <FaqSection accordionItems={faqItems} title={faqSection.elements.title.value} />
    );
  }

  return (
    <CommonQuestions
      headline={faqSection.elements.title.value}
      accordionItems={faqItems}
      isPageSection={isPageSection}
    />
  );
};

export const query = graphql`
  fragment KontentItemFaqSection on kontent_item_faq_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      section_items {
        value {
          ... on kontent_item_faq {
            id
            elements {
              answer {
                value
                images {
                  width
                  url
                  image_id
                  height
                  description
                }
                links {
                  link_id
                  codename
                  type
                  url_slug
                }
                type
                name
                modular_content {
                  id
                  system {
                    codename
                  }
                  internal {
                    type
                  }
                  ...KontentItemImage
                  ...KontentItemCtaButton
                }
              }
              question {
                value
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemFaqSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  faqSectionWithImage: PropTypes.bool,
  isPageSection: PropTypes.bool,
};

KontentItemFaqSection.defaultProps = {
  faqSectionWithImage: false,
  isPageSection: false,
};

export default KontentItemFaqSection;
