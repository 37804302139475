import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import SubNavigation from "../../components/subNavigation/subNavigation";

const SubNavigationGenerator = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
      primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "primary_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                    elements {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      secondaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "secondary_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                    elements {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (id) {
    const node = _.find(
      data.links.nodes,
      (child) => child.id.includes(id),
    );

    if (node) {
      const slugParts = node.slug.split("/");
      // Remove first empty element
      slugParts.shift();

      const items = [];

      if (slugParts.length > 0 && slugParts[0]) {
        const navigationData = data.primaryNavigation.nodes[0].elements.elements.value.concat(
          data.secondaryNavigation.nodes[0].elements.elements.value,
        );

        const mainItem = _.find(data.links.nodes, {
          slug: `/${slugParts[0]}`,
        });

        if (mainItem) {
          const mainNavigation = _.find(navigationData, {
            id: mainItem.navigationItemId,
          });

          if (mainNavigation) {
            mainNavigation.elements.elements.value.forEach((item) => {
              if (!item.id) return null;

              items.push(
                { id: item.id, link: item.id, title: item.elements.title.value },
              );

              return null;
            });
          }

          if (items.length > 0) {
            return <SubNavigation items={items} />;
          }
        }
      }
    }
  }

  return null;
};

SubNavigationGenerator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SubNavigationGenerator;
