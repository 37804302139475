const Templates = {};

Templates.KontentItemImage = require("./kontentItemImage").default;
Templates.KontentItemEyecatcher = require("./kontentItemEyecatcher").default;
Templates.KontentItemCtaButton = require("./kontentItemCtaButton").default;
Templates.KontentItemImageGrid = require("./kontentItemImageGrid").default;
Templates.KontentItemMediaGallery = require("./kontentItemMediaGallery").default;
Templates.KontentItemQuote = require("./kontentItemQuote").default;
Templates.KontentItemAccordion = require("./kontentItemAccordion").default;
Templates.KontentItemDownloadGroup = require("./kontentItemDownloadGroup").default;
Templates.KontentItemFaqSection = require("./kontentItemFaqSection").default;
Templates.KontentItemLinkCardGroup = require("./kontentItemLinkCardGroup").default;
Templates.KontentItemVideoEmbed = require("./kontentItemVideoEmbed").default;
Templates.KontentItemEyecatcherIcon = require("./kontentItemEyecatcherIcon").default;
Templates.KontentItemEyecatcherIconSection = require("./kontentItemEyecatcherIconSection").default;
Templates.KontentItemContactPersonsSection = require("./kontentItemContactPersonsSection").default;
Templates.KontentItemEyecatcherContact = require("./kontentItemEyecatcherContact").default;
Templates.KontentItemServicesSection = require("./kontentItemServicesSection").default;
Templates.KontentItemCategoryLinkCard = require("./kontentItemCategoryLinkCard").default;
Templates.KontentItemRepresentativesSection = require("./kontentItemRepresentativesSection").default;
Templates.KontentItemDownloadsSection = require("./kontentItemDownloadsSection").default;
Templates.KontentItemFormsGroup = require("./kontentItemFormsGroup").default;
Templates.KontentItemArticlesEmbed = require("./kontentItemArticlesEmbed").default;

// Dynamic Forms
Templates.KontentItemSectionHeader = require("./form/kontentItemSectionHeader").default;
Templates.KontentItemInputTypeEmail = require("./form/kontentItemInputTypeEmail").default;
Templates.KontentItemInputTypeDropdown = require("./form/kontentItemInputTypeDropdown").default;
Templates.KontentItemInputTypeName = require("./form/kontentItemInputTypeName").default;
Templates.KontentItemInputTypeSalutation = require("./form/kontentItemInputTypeSalutation").default;
Templates.KontentItemInputTypeText = require("./form/kontentItemInputTypeText").default;
Templates.KontentItemInputTypeAddress = require("./form/kontentItemInputTypeAddress").default;
Templates.KontentItemInputTypeCheckbox = require("./form/kontentItemInputTypeCheckbox").default;
Templates.KontentItemInputTypeSingleInput = require("./form/kontentItemInputTypeSingleInput").default;
Templates.KontentItemInputTypeState = require("./form/kontentItemInputTypeState").default;
Templates.KontentItemInputTypeCountry = require("./form/kontentItemInputTypeCountry").default;

export default Templates;
