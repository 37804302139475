import React, { Component, createRef } from "react";

import classNames from "classnames";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { IconDeleteInput18, IconSearch24 } from "../../icons";

import "./searchFilter.scss";

class SearchFilter extends Component {
  constructor() {
    super();
    this.content = createRef();
    this.hasContent = true;
    this.state = {
      filter: null,
    };
  }

  componentDidUpdate() {
    let tmpHasContent = false;

    for (let i = 0; i < this.content.current.children.length && !tmpHasContent; i += 1) {
      if (this.content.current.children[i].classList.contains("search-filter__content-item") && this.content.current.children[i].children.length > 0) {
        tmpHasContent = true;
      }
    }

    if (this.hasContent !== tmpHasContent) {
      this.hasContent = tmpHasContent;
      this.forceUpdate();
    }
  }

  render() {
    const {
      variant,
      children,
      noSearchContent,
    } = this.props;
    const {
      filter,
    } = this.state;

    return (
      <div className={classNames("search-filter", variant && `search-filter--${variant}`)}>
        <div className="container--fullwidth search-filter__search-section">
          <Container fluid>
            <div className="search-filter__input">
              <InputGroup className="prepend large">
                <Form.Control
                  size="lg"
                  placeholder="Search for keywords"
                  onChange={(event) => {
                    this.setState({ filter: event.target.value });
                  }}
                  ref={(node) => {
                    this.searchField = node;
                  }}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <IconSearch24 />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {filter && filter !== "" && (
                  <button
                    type="button"
                    className="btn-clean"
                    onClick={() => {
                      this.searchField.value = "";
                      this.setState({ filter: "" });
                    }}
                  >
                    <IconDeleteInput18 />
                  </button>
                )}
              </InputGroup>
            </div>
          </Container>
        </div>
        {noSearchContent && (
          <div>
            {noSearchContent}
          </div>
        )}
        <div className="search-filter__content" ref={this.content}>
          {React.Children.toArray(children).map((child, index) => (
            <div
              key={`search-filter__content-item--${index.toString()}`}
              className={classNames({
                "search-filter__content-item": !child.props.omit,
              })}
            >
              {React.cloneElement(child, { filter })}
            </div>
          ))}
          {!this.hasContent && (
            <Row className="search-filter__content-no-content">
              <Col xs={{ offset: 2, span: 8 }}>
                <FormattedMessage
                  id="search.noresult"
                  values={{ searchTerm: filter }}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

SearchFilter.propTypes = {
  children: PropTypes.node.isRequired,
  noSearchContent: PropTypes.oneOfType([PropTypes.object]),
  variant: PropTypes.oneOf(["glossary", "faq"]),
};

SearchFilter.defaultProps = {
  noSearchContent: null,
  variant: null,
};

export default injectIntl(SearchFilter);
