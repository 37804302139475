import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";

import NavigationLink from "./navigationLink";
import { IconDropdownExpand18 } from "../../icons";
import { getCurrentLanguage } from "../../utils/navigation";

const SecondaryNavigation = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      secondaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {eq: "secondary_navigation"}}}) {
        nodes {
          id
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const secondaryNavigation = [];

  if (data.secondaryNavigation) {
    const nav = getCurrentLanguage(data.secondaryNavigation.nodes, intl.locale);

    if (nav) {
      const itemCount = 4;

      if (nav.elements.elements.value.length > itemCount + 1) {
        nav.elements.elements.value.slice(0, itemCount).forEach((item) => {
          secondaryNavigation.push((
            <div className="nav-item" key={item.elements.title.value}>
              <NavigationLink {...item} className="nav-link" />
            </div>
          ));
        });

        secondaryNavigation.push((
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
              <FormattedMessage id="navigation.more" />
              <IconDropdownExpand18 />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                nav.elements.elements.value.slice(itemCount).map((item) => (
                  <React.Fragment key={item.elements.title.value}>
                    <NavigationLink {...item} className="dropdown-item" />
                  </React.Fragment>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>

        ));
      } else {
        nav.elements.elements.value.forEach((item) => {
          secondaryNavigation.push((
            <div className="nav-item" key={item.elements.title.value}>
              <NavigationLink {...item} className="nav-link" />
            </div>
          ));
        });
      }
    }
  }

  return secondaryNavigation;
};

export default SecondaryNavigation;
