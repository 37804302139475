import React from "react";

import { RichTextElement } from "@kentico/gatsby-kontent-components";
import sanitizeHtml from "sanitize-html";

import NavigationLink from "../../components/navigation/navigationLink";
import mapSimpleContentItem from "../../utils/mapSimpleKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";

const SimpleRichText = (data, openLinksInNewTab) => {
  const {
    data: {
      value,
      images,
      links,
      modular_content: modularContent,
    },
  } = data;

  if (!value || value === "<p><br></p>") {
    return null;
  }

  // cleanup value
  const text = sanitizeHtml(value.replace(/&nbsp;/g, " "), {
    allowVulnerableTags: true, // todo: remove
    allowedAttributes: false,
    allowedTags: false, // todo: define allowed tags
    exclusiveFilter: (frame) => ["script", "style"].includes(frame.tag),
  });

  return (
    <div className="text--simple">
      <div>
        <RichTextElement
          value={text}
          images={images}
          links={links}
          linkedItems={modularContent}
          resolveImage={(image) => (
            <KontentAssetsImage
              data={image}
              className="mx-auto"
            />
          )}
          resolveLink={(link, domNode) => {
            if (link) {
              return (
                <NavigationLink href={link.link_id} target={openLinksInNewTab ? "_blank" : "_self"}>
                  {domNode.children[0].data}
                </NavigationLink>
              );
            }

            return <>{domNode.children[0].data}</>;
          }}
          resolveLinkedItem={
            (linkedItem) => mapSimpleContentItem(linkedItem)
          }
          resolveDomNode={(domNode, domToReact) => {
            if (domNode.name && domNode.parent && ["h1", "h2", "h3", "h4", "h5"].includes(domNode.parent.name)) {
              return domToReact(
                [domNode],
                {
                  // eslint-disable-next-line react/display-name
                  replace: (dom) => (<>{domToReact(dom.children)}</>),
                },
              );
            }

            if (["h1", "h2", "h3", "h4", "h5"].includes(domNode.name)) {
              // check https://github.com/remarkablemark/html-react-parser#readme
              return domToReact(
                [domNode],
                {
                  // eslint-disable-next-line react/display-name
                  replace: (dom) => (<div className={`title-${dom.name}`}>{domToReact(dom.children)}</div>),
                },
              );
            }

            return domToReact(domNode);
          }}
        />
      </div>
    </div>
  );
};

export default SimpleRichText;
