import React, { useRef, useState } from "react";

import PropTypes from "prop-types";

import DynamicTooltip from "../dynamicForm/tooltip/dynamicTooltip";

const Tooltip = ({ id, tooltipMessage, children }) => {
  const [show, setShow] = useState(false);
  const [focused, setFocused] = useState(false);

  const target = useRef(null);

  if (!tooltipMessage) return children;

  return (
    <div
      ref={target}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => (!focused ? setShow(false) : null)}
      onFocus={() => {
        setShow(true);
        setFocused(true);
      }}
      onBlur={() => {
        setShow(false);
        setFocused(false);
      }}
    >
      {children}

      {tooltipMessage && target.current ? (
        <DynamicTooltip
          tooltipId={`${id}-tooltip`}
          target={target.current}
          show={show}
          placement="top"
          tooltipMessage={tooltipMessage}
        />
      ) : null}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string,
};

Tooltip.defaultProps = {
  tooltipMessage: null,
};

export default Tooltip;
