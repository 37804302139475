import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./iconText.scss";

const IconText = (props) => {
  const {
    icon,
    text,
    variant,
    postIcon,
  } = props;

  return (
    <div
      className={classNames(
        "icon-text",
        `icon-text--${variant}`,
        {
          "icon-text--post-icon": postIcon,
          "typo-body1": variant === "default",
          "typo-small-label": variant !== "default",
        },
      )}
    >
      {icon && (<div className="icon-text__icon">{icon}</div>)}
      {text && (
        <div
          className={classNames(
            "icon-text__text",
            { "order-first": postIcon },
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

IconText.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  postIcon: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(["primary", "learn-area", "donation", "faq", "light", "grey", "events", "donor-path", "default", "grey-dark", "tools"]),
};

IconText.defaultProps = {
  postIcon: false,
  text: null,
  variant: "default",
};

export default IconText;
