import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import CircularIconDepartment from "../components/circularIcon/circularIconDepartment";
import DepartmentTile from "../components/departmentTile/departmentTile";

const KontentItemDepartment = ({ data }) => {
  const tileData = Array.isArray(data) ? data : [data];

  const tileList = [];

  tileData.forEach((tile) => {
    const image = tile.elements.icon.value.length > 0
      ? tile.elements.icon.value[0]
      : null;

    tileList.push(
      <DepartmentTile
        name={tile.elements.headline.value}
        text={tile.elements.lead_text.value}
        media={(
          <CircularIconDepartment
            image={image ? (
              <KontentAssetsImage
                data={image}
                height={30}
                aspectRatio={1}
              />
            ) : null}
          />
        )}
        href={tile.id}
      />,
    );
  });

  return React.Children.toArray(tileList);
};

export const query = graphql`
  fragment KontentItemDepartment on kontent_item_department {
    id
    system {
      codename
    }
    elements {
      icon {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      headline {
        value
      }
      lead_text {
        value
      }
    }
  }
`;

KontentItemDepartment.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDepartment;
