import React, { Component } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconFurther18,
} from "../../icons";
import LinkButton from "../button/linkButton";

import "./listsection.scss";

class ListSection extends Component {
  numberOfTiles = 3;

  numberOfMoreTiles = 3;

  constructor(props) {
    super(props);

    const {
      children,
    } = this.props;

    if (typeof window !== "undefined" && window.innerWidth >= 992) {
      this.numberOfTiles = 3;
      this.numberOfMoreTiles = 3;
    }

    this.state = {
      numberOfShowTiles: children.length < this.numberOfTiles
        ? children.length
        : this.numberOfTiles,
    };
  }

  render() {
    const {
      children,
      headline,
      viewAllLink,
      isFormList,
    } = this.props;

    const { numberOfShowTiles } = this.state;

    return (
      <div className="listsection">
        <Row>
          <Col
            md={8}
            className="listsection__headline"
          >
            {headline !== "" ? `${headline} (${children.length})` : ""}
          </Col>
          {isFormList && (
            <Col xs={12} className="d-none d-xl-block">
              <Row className="listsection__list-columns">
                <Col xl={2} className="typo-label">
                  <FormattedMessage id="listsection.name" />
                </Col>
                <Col xl={4} className="typo-label">
                  <FormattedMessage id="listsection.description" />
                </Col>
                <Col xl={3} className="typo-label">
                  <FormattedMessage id="listsection.category" />
                </Col>
                <Col xl={3} className="typo-label">
                  <FormattedMessage id="listsection.actions" />
                </Col>
              </Row>
            </Col>
          )}
          {
            React.Children.map(children, (child, index) => (
              index < numberOfShowTiles && (
                <Col xs={12} className={index > 0 && "mt-5"}>
                  {child}
                </Col>
              )
            ))
          }
        </Row>
        {viewAllLink !== null && (
          <LinkButton className="listsection__view_all" href={viewAllLink}>
            <FormattedMessage id="list_section.view_all" />
            <IconFurther18 />
          </LinkButton>
        )}
        {children.length > this.numberOfTiles && (
          !(children.length <= this.numberOfTiles)
          && (numberOfShowTiles < children.length
            ? (
              <Button
                onClick={() => {
                  const addMoreElements = numberOfShowTiles + this.numberOfMoreTiles;
                  const changeNumberOfShowElements = (addMoreElements + 1) > children.length
                    ? children.length
                    : addMoreElements;

                  this.setState({
                    numberOfShowTiles: changeNumberOfShowElements,
                  });
                }}
                variant="outline-faq"
                className="listsection__pagination"
              >
                <FormattedMessage id="listsection.show_more" /> <IconAccordionExpand18 />
              </Button>
            )
            : (
              <Button
                onClick={() => {
                  this.setState({
                    numberOfShowTiles: this.numberOfTiles,
                  });
                }}
                variant="outline-faq"
                className="listsection__pagination"
              >
                <FormattedMessage id="listsection.show_less" /> <IconAccordionMinimize18 />
              </Button>
            ))
        )}
      </div>
    );
  }
}

ListSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  headline: PropTypes.string.isRequired,
  isFormList: PropTypes.bool,
  viewAllLink: PropTypes.string,
};

ListSection.defaultProps = {
  isFormList: false,
  viewAllLink: null,
};

export default ListSection;
