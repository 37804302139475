import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AnchorLinkBar from "../../components/anchorLinkBar/anchorLinkBar";
import ContentHeader from "../../components/contentHeader/contentHeader";
import MainLayout from "../../components/mainLayout";
import SearchFilter from "../../components/searchFilter/searchFilter";
import SEO from "../../components/seo";
import { HeaderIconGlossary } from "../../images";
import GlossaryOverviewWrapper from "../glossary/glossaryOverviewWrapper";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";

const KontentItemGlossary = ({ data }) => {
  const { page } = data;

  const glossaryItems = page.elements.glossary_items.value;
  const mappedItems = glossaryItems.map(
    (glossaryItem) => glossaryItem.elements.abbreviation.value.charAt(0),
  );
  const activeItems = [...new Set(mappedItems)];
  const anchorButtonLinks = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={<HeaderIconGlossary />}
      />

      <SearchFilter
        noSearchContent={
          <AnchorLinkBar items={anchorButtonLinks} active={activeItems} />
        }
      >
        <GlossaryOverviewWrapper items={glossaryItems} />
      </SearchFilter>
    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemGlossary(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        glossary_items {
            value {
              ...KontentItemGlossaryItem
            }
        }
      }
    }
  }
`;

KontentItemGlossary.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemGlossary;
