import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import Divider from "../../components/divider/divider";
import MainLayout from "../../components/mainLayout";
import Section from "../../components/section/section";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeader from "../../components/textHeader/textHeader";
import { IconDownload18 } from "../../icons";
import KontentItemContactBox from "../kontentItemContactBox";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import InfoBarSnippet from "../utils/infoBarSnippet";

const KontentItemFactsheet = ({ data }) => {
  const contactView = [];

  if (data.page.elements.contacts.value.length > 0) {
    data.page.elements.contacts.value.forEach((item) => {
      contactView.push((
        <KontentItemContactBox data={item} key={item.id} />
      ));
    });
  }

  return (
    <MainLayout>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value}
        ogDescription={data.page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={data.page.id} />

      <BreadcrumbGenerator id={data.page.id} />

      <TextHeader
        headline={data.page.elements.hero_headline.value}
        description={data.page.elements.lead_text.value}
        button={
          data.page.elements.button_url.value.length > 0
            && data.page.elements.button_url.value[0].elements.asset_item.value.length > 0
            ? (
              <IconButton
                href={data.page.elements.button_url.value[0].elements.asset_item.value[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.page.elements.button_text.value} <IconDownload18 />
              </IconButton>
            )
            : null
        }
      />

      <SidebarLayout
        isRight
        sidebar={(
          <InfoBarSnippet
            infoBarHeadline={data.page.elements.info_bar__info_bar_headline.value}
            quicklinksTitle={data.page.elements.info_bar__quicklinks_title.value}
            quicklinkTextSource={data.page.elements.body.value}
            linksTitle={data.page.elements.info_bar__links_title.value}
            externalUrl={data.page.elements.info_bar__external_url}
            contactDataTitle={data.page.elements.info_bar__contact_data_title.value}
            contactData={data.page.elements.info_bar__contact_data}
            contactTitle={data.page.elements.info_bar__contact_title.value}
            contactMail={data.page.elements.info_bar__contact_mail}
            contactPhone={data.page.elements.info_bar__contact_phone}
            downloadsTitle={data.page.elements.info_bar__downloads_title.value}
            download={data.page.elements.info_bar__download.value}
            glossaryTitle={data.page.elements.info_bar__glossary_title.value}
            glossaryItems={data.page.elements.info_bar__glossary_items.value}
          />
        )}
      >

        <RichText data={data.page.elements.body} isSidebarLayout />

        {data.page.elements.section_headline.value !== "" && (
          <>
            <Divider />

            <Section
              headline={data.page.elements.section_headline.value}
              text={data.page.elements.section_description.value}
              colProps={{ md: 6 }}
            >
              {contactView}
            </Section>
          </>
        )}

        <div className="d-xl-none">
          <Divider />
        </div>

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemFactsheet(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
           value
        }
        lead_text {
          value
        }
        section_headline {
          value
        }
        section_description {
          value
        }
        info_bar__info_bar_headline {
          value
        }
        info_bar__quicklinks_title {
          value
        }
        info_bar__links_title {
          value
        }
        info_bar__external_url {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_data_title {
          value
        }
        info_bar__contact_data {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_title {
          value
        }
        info_bar__contact_mail {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__contact_phone {
         value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
          }
        }
        info_bar__downloads_title {
          value
        }
        info_bar__download {
          value {
            ...KontentItemAsset
          }
        }
        info_bar__glossary_title {
          value
        }
        info_bar__glossary_items {
          value {
            ...KontentItemGlossaryItem
          }
        }
        contacts {
          value {
            ...KontentItemContactBox
          }
        }
        button_text {
          value
        }
        button_url {
           value {
              ...KontentItemAsset
           }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
      }
    }
  }
`;

KontentItemFactsheet.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFactsheet;
