import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCategoryLinkCard from "./kontentItemCategoryLinkCard";
import Section from "../components/section/section";

const KontentItemServicesAndProcessesSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <Section
      headline={section.elements.headline.value}
      text={section.elements.text.value}
      colProps={{ md: 6, xl: 4 }}
    >
      {section.elements.services_and_processes.value.map((service) => (
        <KontentItemCategoryLinkCard data={service} key={service.id} />
      ))}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemServicesAndProcessesSection on kontent_item_services_and_processes_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      services_and_processes {
        value {
          ...KontentItemCategoryLinkCard
        }
      }
    }
  } 
`;

KontentItemServicesAndProcessesSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemServicesAndProcessesSection;
