import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Logo from "../logo";
import BottomFooterNavigation from "../navigation/bottomFooterNavigation";
import FooterNavigation from "../navigation/footerNavigation";

import "./footer.scss";

const Footer = () => (
  <footer className="footer">
    <div className="footer-menu container--fullwidth">
      <Container fluid>
        <Row className="footer-menu__header">
          <Col xs={12} className="footer-menu__header-logo">
            <Logo breakpoint="sm" />
          </Col>
        </Row>
        <Row className="footer-menu__body footer-navigation">
          <FooterNavigation />
        </Row>
      </Container>
    </div>
    <div className="footer-copyright">
      <div className="footer-copyright__menu">
        <BottomFooterNavigation />
      </div>
      <div className="footer-copyright__text typo-body2">
        {process.env.GATSBY_SITE === "prof"
          && <>&copy; {new Date().getFullYear()} DKMS Group gGmbH</>}

        {process.env.GATSBY_SITE === "labs"
          && <>&copy; {new Date().getFullYear()} DKMS Life Science Lab gGmbH</>}
      </div>
    </div>
  </footer>
);

export default Footer;
