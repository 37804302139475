import React, { useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";

import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import IconButton from "../button/iconButton";
import CardSlider from "../cardSlider/cardSlider";
import TextSection from "../textSection/textSection";

import "./storySection.scss";

const StorySection = (props) => {
  const {
    children,
    headline,
    text,
    readMoreText,
  } = props;

  const [showAll, setShowAll] = useState(false);

  return (
    <div
      className={classNames(
        "container--fullwidth story-section",
        {
          "story-section--read-more": readMoreText,
        },
      )}
    >
      <Container fluid>
        <TextSection
          headline={headline}
          text={text}
        />
        {readMoreText && (
          <>
            <Collapse in={showAll}>
              <div>
                <TextSection
                  text={readMoreText}
                />
              </div>
            </Collapse>
            <IconButton
              variant="link"
              onClick={() => {
                setShowAll(!showAll);
              }}
            >
              {showAll
                ? <><FormattedMessage id="text.read_less" /> <IconAccordionMinimize18 /></>
                : <><FormattedMessage id="text.read_more" /> <IconAccordionExpand18 /></>}
            </IconButton>
          </>
        )}
        <CardSlider>
          {children}
        </CardSlider>
      </Container>
    </div>
  );
};

StorySection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  headline: PropTypes.string,
  readMoreText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
};

StorySection.defaultProps = {
  headline: null,
  readMoreText: null,
  text: null,
};

export default StorySection;
