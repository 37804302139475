import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SimpleRichText from "./text/simpleRichText";
import FaqAccordion from "../components/faqAccordion/faqAccordion";

const KontentItemAccordion = ({ data }) => {
  const accordion = data.value ? data.value[0] : data;

  if (!accordion || !accordion.elements) {
    return null;
  }

  return (
    <FaqAccordion
      accordionItems={[
        {
          answer: (<SimpleRichText data={accordion.elements.tab_content} />),
          id: accordion.id,
          question: accordion.elements.tab_label.value,
        },
      ]}
    />
  );
};

export const query = graphql`
  fragment KontentItemAccordion on kontent_item_accordion {
    id
    system {
      codename
    }
    elements {
      tab_label {
        value
      }
      tab_content {
        value
        images {
          width
          url
          image_id
          height
          description
        }
        links {
          link_id
          codename
          type
          url_slug
        }
        type
        name
        modular_content {
          id
          system {
            codename
          }
          internal {
            type
          }
          ...KontentItemImage
          ...KontentItemCtaButton
        }
      }
    }
  }
`;

KontentItemAccordion.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemAccordion;
