import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
} from "../../icons";
import BrowserDetection from "../browserDetection";

import "./footerSection.scss";

class FooterSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSectionExpanded: false,
    };
  }

  toggleIsSectionExpanded() {
    const {
      isSectionExpanded,
    } = this.state;

    this.setState({ isSectionExpanded: !isSectionExpanded });
  }

  render() {
    const {
      isSectionExpanded,
    } = this.state;

    const {
      children,
      sectionName,
    } = this.props;

    const isMobile = BrowserDetection.hasTouchScreen();

    return (
      <Accordion className="footer-section">
        <Card>
          <Accordion.Toggle
            as="div"
            eventKey="0"
            onClick={() => {
              this.toggleIsSectionExpanded();
            }}
          >
            <Row className={classNames("footer-section__header", isSectionExpanded ? "footer-section__header--active" : "")}>
              <Col xs={10} sm={11} className={classNames("footer-section__section-name", { mobile: isMobile })}>
                {sectionName}
              </Col>
              <Col xs={2} sm={1} className="d-lg-none justify-content-end d-flex">
                {
                  isSectionExpanded
                    ? <IconAccordionMinimize18 className="footer-section__icon" />
                    : <IconAccordionExpand18 className="footer-section__icon" />
                }
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {children}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

FooterSection.propTypes = {
  children: PropTypes.node,
  sectionName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

FooterSection.defaultProps = {
  children: null,
};

export default FooterSection;
