import React, { Component } from "react";

import classNames from "classnames";
import {
  FormattedMessage,
} from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReactIdSwiper from "react-id-swiper";

import {
  IconAnchorLeft18,
  IconAnchorRight18,
  IconFurther24,
} from "../../icons";
import LinkButton from "../button/linkButton";
import TitleRow from "../titleRow";

import "./cardSlider.scss";

class CardSlider extends Component {
  constructor(props) {
    super(props);

    this.swiper = null;
    this.swiperRef = null;
    this.state = {
      nextDisabled: false,
      prevDisabled: true,
    };
  }

  componentDidMount() {
    this.checkButtons();
  }

  next = () => {
    this.swiper.slideNext();
  };

  previous = () => {
    this.swiper.slidePrev();
  };

  checkButtons() {
    if (this.swiper !== null) {
      this.setState({
        nextDisabled: this.swiper.isEnd,
        prevDisabled: this.swiper.isBeginning,
      });
    }
  }

  render() {
    const {
      header,
      children,
      description,
      className,
      showSlideButtons,
      related,
      targetGroup,
      equalCardHeights,
      subHeader,
      twoCols,
      inline,
      moreUrl,
      isSidebarLayout,
    } = this.props;

    const {
      prevDisabled,
      nextDisabled,
    } = this.state;

    const childrenArray = React.Children.toArray(children);

    let colProps = {
      lg: 4,
      sm: 6,
      xs: childrenArray.length === 1 ? 12 : 10,
    };

    let breakpointProps = {
      0: {
        slidesPerView: "auto",
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    };

    if (twoCols) {
      colProps = {
        md: 6,
        sm: 12,
        xs: childrenArray.length === 1 ? 12 : 10,
      };

      breakpointProps = {
        0: {
          slidesPerView: "auto",
        },
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      };
    }

    if (isSidebarLayout) {
      colProps = {
        md: 6,
        xs: 10,
      };

      breakpointProps = {
        0: {
          slidesPerView: "auto",
        },
      };
    }

    const swiperProps = {
      breakpoints: { ...breakpointProps },
      keyboard: true,
      mousewheel: {
        forceToAxis: true,
      },
      on: {
        init: () => {
          this.swiper = this.swiperRef.swiper;
        },
        slideChange: () => {
          this.checkButtons();
        },
      },
      shouldSwiperUpdate: true,
    };

    const swiperJsx = (
      <ReactIdSwiper
        ref={(swiper) => {
          this.swiperRef = swiper;
        }}
        className="row"
        {...swiperProps}
      >
        {childrenArray.slice(0, related ? 3 : undefined).map(
          (child) => (
            <Col
              {...colProps}
              key={child.key}
              className={classNames({ "equal-height": equalCardHeights })}
            >
              {child}
            </Col>
          ),
        )}
      </ReactIdSwiper>
    );

    return (
      <div
        className={classNames(
          "card-slider",
          className,
          {
            "card-slider--inline": inline,
            "card-slider--no-headers": !header && !subHeader,
            "card-slider--two-cols": twoCols,
          },
        )}
      >
        {header && header !== "" && (
          <TitleRow description={description}>{header}</TitleRow>
        )}
        {subHeader && (
          <Row>
            <Col md={6} lg={7} xl={6} className="card-slider__subheader">
              <h4>{subHeader}</h4>
            </Col>
          </Row>
        )}
        {inline
          ? (
            <>{swiperJsx}</>
          )
          : (
            <div className="container--fullwidth card-slider__slider">
              <Container id={this.id} fluid>
                {swiperJsx}
              </Container>
            </div>
          )}

        {!related && (
          (
            showSlideButtons && (
              (twoCols && childrenArray.length > 2) || (!twoCols && childrenArray.length > 3)
            )
          )
          || moreUrl
        ) && (
          <Row
            className={
              classNames(
                "card-slider__control",
                {
                  "card-slider__show-all": moreUrl,
                  "justify-content-end": !moreUrl,
                },
              )
            }
          >
            {moreUrl && (
              <Col className="card-slider__control-link">
                <LinkButton href={moreUrl}>
                  <FormattedMessage id="cardslider.view_all" />
                  <IconFurther24 />
                </LinkButton>
              </Col>
            )}
            {showSlideButtons && childrenArray.length > 3 && (
              <>
                <Col className="card-slider__control-prev">
                  <LinkButton
                    disabled={prevDisabled}
                    variant="normal"
                    onClick={this.previous}
                  >
                    <IconAnchorLeft18 />
                    <FormattedMessage id="cardslider.previous" />
                  </LinkButton>
                </Col>
                <Col className="card-slider__control-next">
                  <LinkButton
                    disabled={nextDisabled}
                    variant="normal"
                    onClick={this.next}
                  >
                    <FormattedMessage id="cardslider.next" />
                    <IconAnchorRight18 />
                  </LinkButton>
                </Col>
              </>
            )}
          </Row>
        )}
        {targetGroup && (
          <div className="card-slider__target-group">
            {targetGroup}
          </div>
        )}
      </div>
    );
  }
}

CardSlider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  equalCardHeights: PropTypes.bool,
  header: PropTypes.string,
  inline: PropTypes.bool,
  isSidebarLayout: PropTypes.bool,
  moreUrl: PropTypes.string,
  related: PropTypes.bool,
  showSlideButtons: PropTypes.bool,
  subHeader: PropTypes.string,
  targetGroup: PropTypes.oneOfType([PropTypes.object]),
  twoCols: PropTypes.bool,
};

CardSlider.defaultProps = {
  className: null,
  description: null,
  equalCardHeights: false,
  header: null,
  inline: false,
  isSidebarLayout: false,
  moreUrl: null,
  related: false,
  showSlideButtons: true,
  subHeader: null,
  targetGroup: null,
  twoCols: false,
};

export default CardSlider;
