import React from "react";

import PropTypes from "prop-types";

import "./relatedStudy.scss";

const RelatedStudy = ({ headline, headlineText, button }) => (
  <div className="related-study">
    <div className="related-study__content">
      <div className="related-study__headline">{headline}</div>
      <div className="related-study__headline-text">{headlineText}</div>
    </div>
    <div className="related-study__button">{button}</div>
  </div>
);

RelatedStudy.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headline: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headlineText: PropTypes.string.isRequired,
};

export default RelatedStudy;
