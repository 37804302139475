import { graphql, useStaticQuery } from "gatsby";

import NewsCard from "./newsCard";

const LatestNewsAndEventsCards = () => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemNewsArticle(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCard
        }
      }
    }
  `);

  const articles = data.allKontentItemNewsArticle.nodes;

  if (data.allKontentItemNewsArticle) {
    return NewsCard({ currentFirst: true, data: articles, limit: 10 });
  }

  return null;
};

export default LatestNewsAndEventsCards;
