import React from "react";

import { graphql, StaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import _ from "lodash";
import PropTypes from "prop-types";

import Divider from "../../components/divider/divider";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import MainLayout from "../../components/mainLayout";
import OverviewText from "../../components/overviewText/overviewText";
import SEO from "../../components/seo";
import KontentItemEyecatcherHighlight from "../kontentItemEyecatcherHighlight";
import KontentItemHubSectionHome from "../kontentItemHubSectionHome";
import KontentItemNetworkSlider from "../kontentItemNetworkSlider";
import KontentItemNewsEventsSection from "../kontentItemNewsEventsSection";
import KontentItemPublicationsSection from "../kontentItemPublicationsSection";
import KontentItemServicesAndProcessesSection from "../kontentItemServicesAndProcessesSection";

const StaticQueryKontentItemHome = ({ data }) => {
  const intl = useIntl();

  const page = _.find(data.allKontentItemHome.nodes, ["preferred_language", intl.locale]);

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={intl.formatMessage({ id: "title" })}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <OverviewText headline={page.elements.headline.value}>
        {page.elements.lead_text.value}
      </OverviewText>

      <KontentItemEyecatcherHighlight data={page.elements.top_highlight} />

      <KontentItemHubSectionHome data={page.elements.hub_section} />

      <Divider isFullWidth />

      <KontentItemServicesAndProcessesSection
        data={page.elements.services_and_processes_highlights}
      />

      <KontentItemNetworkSlider data={page.elements.network} />

      <KontentItemPublicationsSection data={page.elements.publications} />

      <Divider isFullWidth />

      <KontentItemNewsEventsSection data={page.elements.news_events} />

    </MainLayout>
  );
};

const KontentItemHome = (props) => (
  <StaticQuery
    query={graphql`
      {
        allKontentItemHome(filter: {system: {type: {eq: "home"}}}) {
          nodes {
            elements {
              metadata__meta_description {
                value
              }
              metadata__twitter_title {
                value
              }
              metadata__twitter_site {
                value
              }
              metadata__twitter_image {
                value {
                  url
                }
              }
              metadata__twitter_description {
                value
              }
              metadata__twitter_creator {
                value
              }
              metadata__og_title {
                value
              }
              metadata__og_image {
                value {
                  url
                }
              }
              metadata__og_description {
                value
              }
              metadata__meta_title {
                value
              }
              hero_headline {
                value
              }
              headline {
                value
              }
              lead_text {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
              top_highlight {
                value {
                  ...KontentItemEyecatcherHighlight
                }
              }
              hub_section {
                value {
                  ...KontentItemHubSectionHome
                }
              }
              services_and_processes_highlights {
                value {
                  ...KontentItemServicesAndProcessesSection
                }
              }
              network {
                value {
                  ...KontentItemNetworkSlider
                }
              }
              publications {
                value {
                  ...KontentItemPublicationsSection
                }
              }
              news_events {
                value {
                  ...KontentItemNewsEventsSection
                }
              }
            }
            preferred_language
          }
        }
      }
    `}
    render={(data) => <StaticQueryKontentItemHome data={data} {...props} />}
  />
);

StaticQueryKontentItemHome.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHome;
