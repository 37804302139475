import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleCard from "../../components/articleCard/articleCard";
import KontentItemImage from "../kontentItemImage";

const NewsCard = (props) => {
  const {
    data,
    currentFirst,
    limit,
    variant,
  } = props;

  let newsData = Array.isArray(data) ? data : data.value;

  if (currentFirst) {
    newsData = newsData.sort(
      (a, b) => new Date(b.elements.publishdate.value) - new Date(a.elements.publishdate.value),
    );
  }

  const newsList = [];

  newsData.forEach((news) => {
    newsList.push(
      <ArticleCard
        image={
          news.elements.hero_image.value.length > 0
            ? (
              <KontentItemImage
                data={news.elements.hero_image}
                height={225}
                width={400}
                crop
                placeholderTyp={variant}
              />
            )
            : null
        }
        category={
          news.elements.categories.value.length > 0
            ? news.elements.categories.value[0].name
            : null
        }
        date={new Date(news.elements.publishdate.value)}
        headline={news.elements.headline.value}
        href={news.id}
        excerpt={news.elements.lead_text.value}
      />,
    );
  });

  if (limit) {
    return React.Children.toArray(newsList.slice(0, limit));
  }

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment NewsCard on kontent_item_news_article {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      categories {
        value {
          codename
          name
        }
      }
      publishdate {
        value
      }
    }
  }
`;

NewsCard.propTypes = {
  articleMigration: PropTypes.oneOfType([PropTypes.array]),
  currentFirst: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
  variant: PropTypes.oneOf(["events, news"]),
};

NewsCard.propTypes = {
  articleMigration: null,
  currentFirst: false,
  limit: null,
  variant: null,
};

export default NewsCard;
