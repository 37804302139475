import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import getYear from "date-fns/getYear";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setFilterCriterions, setFilterText, setTargetItem } from "../../features/articleSlice";
import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconDeleteInput18,
  IconFilter24,
  IconSearch24,
  IconSelect24,
  IconSelectEmpty24,
} from "../../icons";
import { dateFactory } from "../../utils/dateHelper";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import FilterTag from "../filterTag/filterTag";

import "./publicationSection.scss";

const ScientificPosterSection = ({ headline, intl, children }) => {
  const [refresh, setRefresh] = useState(false);
  const [showDropdownCategory, setShowDropdownCategory] = useState(false);
  const [showDropdownYear, setShowDropdownYear] = useState(false);

  const targetItem = useSelector((state) => state.article.targetItem);
  const targetIndex = useSelector((state) => state.article.targetIndex);
  const filterCriterionsPoster = useSelector((state) => state.article.filterCriterionsPoster);
  const filterTextPoster = useSelector((state) => state.article.filterTextPoster);

  const filterCriterionsYear = useMemo(() => [], []);
  const filterCriterionsCategory = useMemo(() => [], []);
  const labelsYear = useMemo(() => [], []);
  const labelsCategory = useMemo(() => [], []);
  const numberOfArticle = 3;
  const [numberOfShowArticle, setNumberOfShowArticle] = useState(targetIndex || numberOfArticle);

  const dispatch = useDispatch();

  useEffect(() => {
    children.forEach((child) => {
      if (child.props.categories.length > 0) {
        child.props.categories.forEach((category) => {
          if (labelsCategory.indexOf(category) === -1) {
            labelsCategory.push(category);
          }
        });
      }
    });

    children.forEach((child) => {
      if (child.props.categories.length > 0) {
        child.props.categories.forEach((category) => {
          if (labelsCategory.indexOf(category) === -1) {
            labelsCategory.push(category);
          }
        });
      }
    });

    children.forEach((child) => child.props.date
      && labelsYear.indexOf(getYear(dateFactory(child.props.date))) === -1
      && labelsYear.push(getYear(dateFactory(child.props.date))));

    labelsYear.sort().reverse();

    filterCriterionsYear.splice(0, filterCriterionsYear.length - 1);
    filterCriterionsCategory.splice(0, filterCriterionsCategory.length - 1);

    filterCriterionsPoster.criterionYear.forEach((year) => {
      filterCriterionsYear.push(year);
    });

    filterCriterionsPoster.criterionCategory.forEach((category) => {
      filterCriterionsCategory.push(category);
    });

    setRefresh(!refresh);

    if (targetIndex > 0) {
      setTimeout(() => {
        const targetPoster = document.querySelector(`#${targetItem}`);

        if (targetPoster) {
          targetPoster.scrollIntoView({ behavior: "auto", block: "start" });
        } else {
          setNumberOfShowArticle(numberOfArticle);
          dispatch(
            setTargetItem({ index: null, item: null }),
          );
        }
      }, 500);
    }
  }, []);

  const setFilterCriterion = (label, filterType) => {
    const indexOfRemove = filterType.indexOf(label);

    if (indexOfRemove !== -1) {
      filterType.splice(indexOfRemove, 1);
    } else {
      filterType.push(label);
    }
  };

  const applyFilter = () => {
    const cloneCriterionYear = cloneDeep(filterCriterionsYear);
    const cloneCriterionCategory = cloneDeep(filterCriterionsCategory);

    dispatch(
      setFilterCriterions({
        key: "poster",
        value: {
          criterionCategory: cloneCriterionCategory,
          criterionYear: cloneCriterionYear,
        },
      }),
    );

    setShowDropdownCategory(false);
    setShowDropdownYear(false);
  };

  const filterChildren = () => {
    let articles = children;

    if (filterCriterionsPoster.criterionYear.length > 0
      || filterCriterionsPoster.criterionCategory.length > 0) {
      if (filterCriterionsPoster.criterionYear.length > 0) {
        articles = articles.filter((child) => {
          if (!child.props.date) {
            return false;
          }

          return filterCriterionsPoster.criterionYear
            .indexOf(getYear(dateFactory(child.props.date))) !== -1;
        });
      }

      if (filterCriterionsPoster.criterionCategory.length > 0) {
        articles = articles.filter((child) => (
          child.props.categories.some((cat) => (
            filterCriterionsPoster.criterionCategory.indexOf(cat) !== -1))
        ));
      }
    } else {
      articles = children;
    }

    articles = articles.filter((child) => (
      (child.props.author !== null
        && child.props.author.toLowerCase().includes(filterTextPoster.toLowerCase()))
      || (child.props.title !== null
        && child.props.title.toLowerCase().includes(filterTextPoster.toLowerCase()))
    ));

    return articles;
  };

  const articles = filterChildren();

  const conferences = {};
  let sortedConferencesNames;

  if (typeof articles !== "undefined" && articles !== null) {
    articles.forEach((item) => {
      if (Object.keys(conferences).indexOf(item.props.conference) === -1) {
        conferences[item.props.conference] = [item];
      } else {
        conferences[item.props.conference].push(item);
      }
    });

    Object.keys(conferences).forEach((item) => {
      conferences[item].sort((a, b) => new Date(b.props.date) - new Date(a.props.date));
    });

    sortedConferencesNames = Object.keys(conferences).sort(
      (a, b) => conferences[b][0].props.date - conferences[a][0].props.date,
    );
  }

  return (
    <div className="publication-section scientific-poster-section">
      {headline && (
        <div className="publication-section__headline">
          {headline}
        </div>
      )}

      <InputGroup className="prepend large">
        <Form.Control
          size="lg"
          placeholder={intl.formatMessage({ id: "publication_section.search_for_keywords" })}
          onChange={(event) => {
            dispatch(
              setFilterText({ key: "poster", value: event.target.value }),
            );
          }}
          className="typo-h4"
          value={filterTextPoster}
        />
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch24 />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {
          filterTextPoster && filterTextPoster !== ""
          && (
            <button
              type="button"
              className="btn-clean"
              onClick={() => {
                dispatch(
                  setFilterText({ key: "poster", value: "" }),
                );
              }}
            >
              <IconDeleteInput18 />
            </button>
          )
        }
      </InputGroup>

      <div>
        <LinkButton
          className="publication-section__filter-button"
          onClick={() => {
            setShowDropdownYear(!showDropdownYear);
            setShowDropdownCategory(false);
          }}
        >
          <IconFilter24 /><FormattedMessage id="publication_section.year" />
        </LinkButton>
        <LinkButton
          className="publication-section__filter-button"
          onClick={() => {
            setShowDropdownYear(false);
            setShowDropdownCategory(!showDropdownCategory);
          }}
        >
          <IconFilter24 /><FormattedMessage id="publication_section.category" />
        </LinkButton>

        <div className="d-flex flex-wrap">
          {filterCriterionsPoster.criterionYear.length > 0 && (

            filterCriterionsPoster.criterionYear.map((tag) => (
              <FilterTag
                key={tag}
                onClick={() => {
                  setFilterCriterion(tag, filterCriterionsYear);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))
          )}
          {filterCriterionsPoster.criterionCategory.length > 0 && (
            filterCriterionsPoster.criterionCategory.map((tag) => (
              <FilterTag
                key={tag.id}
                onClick={() => {
                  setFilterCriterion(tag, filterCriterionsCategory);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))
          )}
        </div>
      </div>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownYear })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage
                id="publication_section.types_selected"
                values={{ count: filterCriterionsYear.length }}
              />
            </div>
            <div className="publication-section__label-container">
              {
                labelsYear.map((label) => (
                  <div
                    className={classNames("publication-section__label", (filterCriterionsYear.indexOf(label) !== -1) && "active")}
                    key={label}
                    onClick={() => {
                      setFilterCriterion(label, filterCriterionsYear);
                      setRefresh(!refresh);
                    }}
                    role="presentation"
                  >
                    {label} {
                    (filterCriterionsYear.indexOf(label) !== -1)
                      ? <IconSelect24 />
                      : <IconSelectEmpty24 />
                  }
                  </div>
                ))
              }
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {
                filterCriterionsYear.length > 0
                  ? (
                    <LinkButton
                      onClick={() => {
                        filterCriterionsYear.splice(0, filterCriterionsYear.length - 1);
                        setRefresh(!refresh);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.reset" />
                    </LinkButton>
                  )
                  : (
                    <LinkButton
                      onClick={() => {
                        setShowDropdownYear(false);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.cancel" />
                    </LinkButton>
                  )
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownCategory })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage
                id="publication_section.types_selected"
                values={{ count: filterCriterionsCategory.length }}
              />
            </div>
            <div className="publication-section__label-container">
              {
                labelsCategory.map((label) => (
                  <div
                    className={classNames("publication-section__label", (filterCriterionsCategory.indexOf(label) !== -1) && "active")}
                    key={label.id}
                    onClick={() => {
                      setFilterCriterion(label, filterCriterionsCategory);
                      setRefresh(!refresh);
                    }}
                    role="presentation"
                  >
                    {label} {
                    (filterCriterionsCategory.indexOf(label) !== -1)
                      ? <IconSelect24 />
                      : <IconSelectEmpty24 />
                  }
                  </div>
                ))
              }
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {
                filterCriterionsCategory.length > 0
                  ? (
                    <LinkButton
                      onClick={() => {
                        filterCriterionsCategory.splice(0, filterCriterionsCategory.length - 1);
                        setRefresh(!refresh);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.reset" />
                    </LinkButton>
                  )
                  : (
                    <LinkButton
                      onClick={() => {
                        setShowDropdownCategory(false);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.cancel" />
                    </LinkButton>
                  )
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("publication-section__menu", { "d-none": !showDropdownCategory })}>
          <div>
            <div className="publication-section__menu-headline">
              <FormattedMessage
                id="publication_section.types_selected"
                values={{ count: filterCriterionsCategory.length }}
              />
            </div>
            <div className="publication-section__label-container">
              {
                labelsCategory.map((label) => (
                  <div
                    className={classNames("publication-section__label", (filterCriterionsCategory.indexOf(label) !== -1) && "active")}
                    key={label.id}
                    onClick={() => {
                      setFilterCriterion(label, filterCriterionsCategory);
                      setRefresh(!refresh);
                    }}
                    role="presentation"
                  >
                    {label} {
                    (filterCriterionsCategory.indexOf(label) !== -1)
                      ? <IconSelect24 />
                      : <IconSelectEmpty24 />
                  }
                  </div>
                ))
              }
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="publication_section.apply_filter" />
              </Button>
              {
                filterCriterionsCategory.length > 0
                  ? (
                    <LinkButton
                      onClick={() => {
                        filterCriterionsCategory.splice(0, filterCriterionsCategory.length - 1);
                        setRefresh(!refresh);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.reset" />
                    </LinkButton>
                  )
                  : (
                    <LinkButton
                      onClick={() => {
                        setShowDropdownCategory(false);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="publication_section.cancel" />
                    </LinkButton>
                  )
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="publication-section__article-container">
        {sortedConferencesNames.map((child) => (
          <>
            <Col
              key={child}
              xs={12}
              className="publication-section__conference-label"
            >{child} {`(${conferences[child].length})`}
            </Col>
            {conferences[child].map((item, index) => {
              if (index < numberOfShowArticle) {
                return (
                  <Col
                    key={`item${index.toString()}`}
                    xs={12}
                    id={`${child}${index}`}
                    onClick={() => {
                      dispatch(
                        setTargetItem({ index: numberOfShowArticle, item: `${child}${index}` }),
                      );
                    }}
                  >
                    {item}
                  </Col>
                );
              }

              return null;
            })}

            {(
              !(conferences[child].length <= numberOfArticle)
            )
              && (
                <div className="publication-section__button-container">
                  {
                    !(conferences[child].length <= numberOfArticle)
                    && (numberOfShowArticle < conferences[child].length
                      ? (
                        <IconButton
                          variant="none"
                          className="publication-section__pagination"
                          onClick={() => {
                            setNumberOfShowArticle(numberOfShowArticle + numberOfArticle);
                          }}
                        >
                          <FormattedMessage id="publication_section.show_more" /> <IconAccordionExpand18 />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          variant="none"
                          className="publication-section__pagination"
                          onClick={() => {
                            setNumberOfShowArticle(numberOfArticle);
                          }}
                        >
                          <FormattedMessage id="publication_section.show_less" /> <IconAccordionMinimize18 />
                        </IconButton>
                      ))
                  }
                </div>
              )}
          </>
        ))}
      </Row>
    </div>
  );
};

ScientificPosterSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string,
  intl: PropTypes.oneOfType([PropTypes.object]),
};

ScientificPosterSection.defaultProps = {
  headline: null,
  intl: null,
};

export default injectIntl(ScientificPosterSection);
