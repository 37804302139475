import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CircularIconDepartment from "../../components/circularIcon/circularIconDepartment";
import MainLayout from "../../components/mainLayout";
import SEO from "../../components/seo";
import SidebarLayout from "../../components/sidebarLayout/sidebarLayout";
import TextHeaderSidebar from "../../components/textHeaderSidebar/textHeaderSidebar";
import mapContentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemImage from "../kontentItemImage";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SidebarNavigationGenerator from "../navigation/sidebarNavigationGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemDepartment = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <SidebarLayout
        sidebar={
          <SidebarNavigationGenerator id={page.id} />
        }
      >
        <BreadcrumbGenerator id={page.id} />

        <TextHeaderSidebar
          icon={
            page.elements.icon.value.length > 0
              ? (
                <CircularIconDepartment
                  image={(
                    <KontentAssetsImage
                      data={page.elements.icon.value[0]}
                      height={30}
                      width={30}
                    />
                  )}
                />
              )
              : null
          }
          title={page.elements.headline.value}
          text={page.elements.lead_text.value}
          image={
            page.elements.hero_image.value.length > 0
              ? (
                <KontentItemImage
                  data={page.elements.hero_image}
                  aspectRatio={21 / 9}
                />
              )
              : null
          }
        />

        <RichText data={page.elements.body} isSidebarLayout />

        {page.elements.page_sections.value.map((item) => mapContentItem(item))}

      </SidebarLayout>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemDepartment(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        icon {
          value {
            height
            size
            name
            type
            url
            width
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemLinkCardGroup
            ...KontentItemVideoEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherIcon
            ...KontentItemEyecatcherContact
            ...KontentItemArticlesEmbed
          }
        }
        page_sections {
          value {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemContactPersonsSection
            ...KontentItemServicesSection
            ...KontentItemRepresentativesSection
            ...KontentItemDownloadsSection
            ...KontentItemEyecatcherIconSection
          }
        }
      }
    }
  }
`;

KontentItemDepartment.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDepartment;
