import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { IconDelete18 } from "../../icons";

import "./filterTag.scss";

const FilterTag = (props) => {
  const {
    children,
    onClick,
    variant,
    icon,
  } = props;

  return (
    <div
      className={classNames(
        "filter-tag",
        variant && `filter-tag--${variant}`,
        { "filter-tag--action": onClick !== null },
      )}
    >
      {children}
      {React.cloneElement(icon, { onClick })}
    </div>
  );
};

FilterTag.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "events", "article-teaser"]),
};

FilterTag.defaultProps = {
  icon: <IconDelete18 />,
  onClick: null,
  variant: "primary",
};

export default FilterTag;
