import React from "react";

import { RichTextElement } from "@kentico/gatsby-kontent-components";
import sanitizeHtml from "sanitize-html";

import NavigationLink from "../../components/navigation/navigationLink";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";

const RichText = (data) => {
  const {
    data: {
      value,
      images,
      links,
      modular_content: modularContent,
    },
    isArticle,
    isSidebarLayout,
  } = data;

  // cleanup value
  const text = sanitizeHtml(value.replace(/&nbsp;/g, " "), {
    allowVulnerableTags: true, // todo: remove
    allowedAttributes: false,
    allowedTags: false, // todo: define allowed tags
    exclusiveFilter: (frame) => ["script", "style"].includes(frame.tag),
  });

  let countAnchors = 0;

  const richText = (
    <RichTextElement
      value={text}
      images={images}
      links={links}
      linkedItems={modularContent}
      resolveImage={(image) => (
        <KontentAssetsImage
          data={image}
          className="mx-auto"
        />
      )}
      resolveLink={(link, domNode) => {
        if (link) {
          return (
            <NavigationLink href={link.link_id}>
              {domNode.children[0].data}
            </NavigationLink>
          );
        }

        return <>{domNode.children[0].data}</>;
      }}
      resolveLinkedItem={
        (linkedItem) => mapKontentItem(linkedItem, { isSidebarLayout, keyPrefix: "rt" })
      }
      resolveDomNode={(domNode, domToReact) => {
        if (domNode.name && domNode.parent && ["h1", "h2", "h3", "h4", "h5"].includes(domNode.parent.name)) {
          return domToReact(
            [domNode],
            {
              // eslint-disable-next-line react/display-name
              replace: (dom) => (<>{domToReact(dom.children)}</>),
            },
          );
        }

        if (domNode.name === "h3") {
          // check https://github.com/remarkablemark/html-react-parser#readme
          return domToReact(
            [domNode],
            {
              // eslint-disable-next-line react/display-name
              replace: (dom) => {
                countAnchors += 1;

                return (<h3 id={`anchor-point-${countAnchors}`}>{domToReact(dom.children)}</h3>);
              },
            },
          );
        }

        return domToReact(domNode);
      }}
    />
  );

  if (isArticle) {
    return (
      <div className="text-section text row">
        <div className="col-lg-8 col-sm-10">
          {richText}
        </div>
      </div>
    );
  }

  return (
    <div className="text-section text">
      <div>
        {richText}
      </div>
    </div>
  );
};

export default RichText;
