import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import MainLayout from "../../components/mainLayout";
import ScientificPosterSection from "../../components/publicationSection/scientificPosterSection";
import SEO from "../../components/seo";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemScientificPosterCards from "../kontentItemScientificPosterCard";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemPostersOverview = ({ data }) => {
  const { page, posters } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        media={
          page.elements.icon.value.length > 0
            ? (
              <KontentAssetsImage
                data={page.elements.icon.value[0]}
                height={150}
              />
            )
            : null
        }
        description={page.elements.lead_text.value}
      />

      <ScientificPosterSection>
        {KontentItemScientificPosterCards({ data: posters.nodes })}
      </ScientificPosterSection>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    posters: allKontentItemPoster {
      nodes {
        ...ScientificPosterCards
      }
    }
    page: kontentItemPostersOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        icon {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
      }
    }
  }
`;

KontentItemPostersOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPostersOverview;
