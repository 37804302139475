import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./infoSection.scss";

const InfoSection = (props) => {
  const {
    children,
    sectionHeadline,
    sectionIcon,
    className,
  } = props;

  return (
    <div className={classNames("info-section", className)}>
      <div className="info-section__headline">
        {sectionIcon}{sectionHeadline}
      </div>
      <div className="info-section__body">
        {children}
      </div>
    </div>
  );
};

InfoSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  sectionHeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sectionIcon: PropTypes.oneOfType([PropTypes.object]),
};

InfoSection.defaultProps = {
  className: null,
  sectionIcon: null,
};

export default InfoSection;
