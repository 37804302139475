import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContactOffice from "../components/contactOffice/contactOffice";

const KontentItemContactOffice = ({ data }) => {
  const contactOffice = data;

  if (!contactOffice || !contactOffice.elements) {
    return null;
  }

  return (
    <ContactOffice
      office={contactOffice.elements.office.value}
      street={contactOffice.elements.street.value}
      city={contactOffice.elements.city.value}
      phone={contactOffice.elements.phone.value}
      fax={contactOffice.elements.fax.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemContactOffice on kontent_item_contact_office {
    id
    system {
      codename
    }
    elements {
      office {
        value
      }
      street {
        value
      }
      city {
        value
      }
      phone {
        value
      }
      fax {
        value
      }
    }
  } 
`;

KontentItemContactOffice.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemContactOffice;
