import React from "react";

import PropTypes from "prop-types";

import { IconAnchorRight24 } from "../../icons";
import LinkButton from "../button/linkButton";

const EyecatcherIcon = (props) => {
  const {
    title,
    buttonLink,
    buttonText,
    children,
    icon,
  } = props;

  return (
    <div className="eyecatcher eyecatcher--icon text-section__fullwidth">
      {icon && (
        <div className="eyecatcher__icon">
          {icon}
        </div>
      )}
      <div className="eyecatcher__title">
        {title && <h3>{title}</h3>}
        {buttonLink && buttonText && (
          <LinkButton href={buttonLink}>
            {buttonText}
            <IconAnchorRight24 />
          </LinkButton>
        )}
      </div>
      {children && (
        <div className="eyecatcher__elements-wrapper">
          <div className="eyecatcher__elements">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

EyecatcherIcon.propTypes = {
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  title: PropTypes.string,
};

EyecatcherIcon.defaultProps = {
  buttonLink: null,
  buttonText: null,
  icon: null,
  title: null,
};

export default EyecatcherIcon;
