import React, { useRef } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { IconClose24, IconRetry24 } from "../../icons";
import LinkButton from "../button/linkButton";

import "./cacheReload.scss";

const CacheReload = () => {
  const reloadNotification = useRef(null);

  return (
    <Container className="notification-container cache-reload" ref={reloadNotification}>
      <Row className="align-items-center">
        <Col xl={{ offset: 1, span: 10 }}>
          <Row
            className="no-gutters notification notification--reload"
          >
            <Col md={6} className="d-flex align-items-center">
              <div className="notification__text typo-label">
                <FormattedMessage
                  id="notification.reload_page_text"
                  defaultMessage="This page has been updated. Please refresh your cache."
                />
              </div>
            </Col>
            <Col md={6}>
              <Row className="notification__buttons">
                <Col className="col-auto">
                  <LinkButton onClick={() => window.location.reload(true)}>
                    <FormattedMessage
                      id="notification.reload_page"
                      defaultMessage="Reload page"
                    />
                    <IconRetry24 />
                  </LinkButton>
                </Col>
                <Col className="col-auto">
                  <LinkButton
                    onClick={() => {
                      reloadNotification.current.style.display = "none";
                    }}
                  >
                    <FormattedMessage
                      id="notification.dismiss"
                      defaultMessage="Dismiss"
                    /> <IconClose24 />
                  </LinkButton>
                </Col>
              </Row>
            </Col>

          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CacheReload;
