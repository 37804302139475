import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setFilterCriterions, setFilterText, setTargetItem } from "../../features/articleSlice";
import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconDeleteInput18,
  IconFilter24,
  IconSearch24,
  IconSelectBlue24,
  IconSelectEmpty24,
} from "../../icons";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import FilterTag from "../filterTag/filterTag";

import "./studySection.scss";

const StudySection = ({ headline, children }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const targetIndex = useSelector((state) => state.article.targetIndex);
  const filterCriterionsStudy = useSelector((state) => state.article.filterCriterionsStudy);
  const targetItem = useSelector((state) => state.article.targetItem);
  const filterTextStudy = useSelector((state) => state.article.filterTextStudy);

  const filterCriterions = useMemo(() => [], []);

  const dispatch = useDispatch();
  const intl = useIntl();

  const labels = useMemo(() => [], []);
  const numberOfArticle = 3;
  const number = (targetIndex / numberOfArticle) || 1;
  const [numberOfShowArticle, setNumberOfShowArticle] = useState(
    numberOfArticle * Math.ceil(number),
  );

  useEffect(() => {
    children.forEach((child) => child.props.type
  && labels.indexOf(child.props.type) === -1
  && labels.push(child.props.type));

    filterCriterions.splice(0, filterCriterions.length - 1);
    filterCriterionsStudy.forEach((item) => {
      filterCriterions.push(item);
    });

    setRefresh(!refresh);

    if (targetIndex > 0) {
      setTimeout(() => {
        const targetStudy = document.querySelector(`#${targetItem}`);

        if (targetStudy) {
          targetStudy.scrollIntoView({ behavior: "auto", block: "start" });
        } else {
          setNumberOfShowArticle(numberOfArticle);
          dispatch(
            setTargetItem({ index: null, item: null }),
          );
        }
      }, 500);
    }
  }, []);

  const setFilterCriterion = (label) => {
    const indexOfRemove = filterCriterions.indexOf(label);

    if (indexOfRemove !== -1) {
      filterCriterions.splice(indexOfRemove, 1);
    } else {
      filterCriterions.push(label);
    }
  };

  const filterChildren = () => {
    let articles = children.filter((child) => child.props.category !== null);

    if (filterCriterionsStudy.length > 0) {
      articles = articles.filter((child) => (
        filterCriterionsStudy.indexOf(child.props.type) !== -1
      ));
    }

    articles = articles.filter((child) => (
      child.props.source.toLowerCase().includes(filterTextStudy.toLowerCase())
      || child.props.title.toLowerCase().includes(filterTextStudy.toLowerCase())
    ));

    return articles;
  };

  const applyFilter = () => {
    dispatch(
      setFilterCriterions({ key: "study", value: [...filterCriterions] }),
    );
    setShowDropdown(false);
  };

  const articles = filterChildren();

  const ongoing = articles.filter((item) => (item.props.category === "ongoing"));
  const completed = articles.filter((item) => (item.props.category === "completed"));

  return (
    <div className="study-section">
      {headline && (
        <div className="study-section__headline">
          {headline}
        </div>
      )}

      <InputGroup className="prepend large">
        <Form.Control
          size="lg"
          placeholder={intl.formatMessage({ id: "study_section.search_for_keywords" })}
          onChange={(event) => {
            dispatch(
              setFilterText({ key: "study", value: event.target.value }),
            );
          }}
          className="typo-h4"
          value={filterTextStudy}
        />
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch24 />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {filterTextStudy && filterTextStudy !== "" && (
          <button
            type="button"
            className="btn-clean"
            onClick={() => {
              dispatch(
                setFilterText({ key: "study", value: "" }),
              );
            }}
          >
            <IconDeleteInput18 />
          </button>
        )}
      </InputGroup>

      <div className="study-section__filter">
        <LinkButton
          className="study-section__filter-button"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <IconFilter24 /><FormattedMessage id="study_section.type" />
        </LinkButton>
        {filterCriterionsStudy.length > 0 && (
          <div className="d-flex flex-wrap">
            {filterCriterionsStudy.map((tag) => (
              <FilterTag
                key={tag}
                onClick={() => {
                  setFilterCriterion(tag);
                  applyFilter();
                }}
                variant="primary"
              >
                {tag}
              </FilterTag>
            ))}
          </div>
        )}
      </div>
      <Row className="position-relative">
        <Col md={6} xl={4} className={classNames("study-section__menu", { "d-none": !showDropdown })}>
          <div>
            <div className="study-section__menu-headline">
              <FormattedMessage id="study_section.types_selected" values={{ count: filterCriterions.length }} />
            </div>
            <div className="study-section__label-container">
              {labels.map((label) => (
                <div
                  className={classNames("study-section__label", (filterCriterions.indexOf(label) !== -1) && "active")}
                  key={label}
                  onClick={() => {
                    setFilterCriterion(label);
                    setRefresh(!refresh);
                  }}
                  role="presentation"
                >
                  {label} {
                    (filterCriterions.indexOf(label) !== -1)
                      ? <IconSelectBlue24 />
                      : <IconSelectEmpty24 />
                  }
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button onClick={() => applyFilter()} size="sm" variant="primary">
                <FormattedMessage id="study_section.apply_filter" />
              </Button>
              {filterCriterions.length > 0
                ? (
                  <LinkButton
                    onClick={() => {
                      filterCriterions.splice(0, filterCriterions.length - 1);
                      setRefresh(!refresh);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="study_section.reset" />
                  </LinkButton>
                )
                : (
                  <LinkButton
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}
                    variant="normal"
                  >
                    <FormattedMessage id="study_section.cancel" />
                  </LinkButton>
                )}
            </div>
          </div>
        </Col>
      </Row>
      <div className="study-section__article-container">
        {ongoing.length > 0 && (
          <div className="study-section__title typo-title2">
            Ongoing {`(${ongoing.length})`}
          </div>
        )}

        {ongoing.map((childOngoing, index) => {
          if (index < numberOfShowArticle) {
            const mainIndex = ongoing.indexOf(childOngoing);

            return (
              <div
                key={childOngoing.props.key}
                id={`ongoing${mainIndex}`}
                onClick={() => {
                  dispatch(
                    setTargetItem({ index: mainIndex, item: `ongoing${mainIndex}` }),
                  );
                }}
                role="presentation"
              >
                {childOngoing}
              </div>
            );
          }

          return null;
        })}

        {completed.length > 0 && (
          <div className="study-section__title typo-title2">
            Completed {`(${completed.length})`}
          </div>
        )}

        {completed.map((child, index) => {
          if (index < numberOfShowArticle) {
            const mainIndex = completed.indexOf(child);

            return (
              <div
                key={child.props.key}
                id={`completed${mainIndex}`}
                onClick={() => {
                  dispatch(
                    setTargetItem({ index: mainIndex, item: `completed${mainIndex}` }),
                  );
                }}
                role="presentation"
              >
                {child}
              </div>
            );
          }

          return null;
        })}
      </div>

      {articles.length > numberOfArticle && (
        <div className="study-section__button-container">
          {numberOfShowArticle < ongoing.length || numberOfShowArticle < completed.length
            ? (
              <IconButton
                variant="none"
                className="study-section__pagination"
                onClick={() => {
                  setNumberOfShowArticle(numberOfShowArticle + numberOfArticle);
                }}
              >
                <FormattedMessage id="study_section.show_more" /> <IconAccordionExpand18 />
              </IconButton>
            )
            : (
              <IconButton
                variant="none"
                className="study-section__pagination"
                onClick={() => {
                  setNumberOfShowArticle(numberOfArticle);
                }}
              >
                <FormattedMessage id="study_section.show_less" /> <IconAccordionMinimize18 />
              </IconButton>
            )}
        </div>
      )}
    </div>
  );
};

StudySection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string,
};

StudySection.defaultProps = {
  headline: null,
};

export default StudySection;
