import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import RegistryBanner from "../components/banner/registryBanner";
import Section from "../components/section/section";

const KontentItemEyecatcherHighlight = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  return (
    <Section isFullWidthChild>
      <RegistryBanner
        title={eyecatcher.elements.headline.value}
        description={eyecatcher.elements.text.value}
        buttonText={eyecatcher.elements.button_text.value}
        buttonUrl={
          eyecatcher.elements.button_url.value.length > 0
            ? eyecatcher.elements.button_url.value[0].id
            : null
        }
        backgroundImageStyle={
          backgroundImageHelper(eyecatcher.elements.image, { w: 1000 })
        }
      />
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherHighlight on kontent_item_eyecatcher_highlight {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemEyecatcherHighlight.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherHighlight;
