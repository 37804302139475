import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import NavigationLink from "../navigation/navigationLink";

import "./departmentTile.scss";

const DepartmentTile = (props) => {
  const {
    media,
    name,
    text,
    href,
    target,
    key,
  } = props;

  const isExternalLink = /^(http|\/\/)/.test(href);

  return (
    <NavigationLink
      href={href}
      className={classNames(
        "department-tile",
        "no-style",
        { "department-tile-external": isExternalLink },
      )}
      target={target}
      key={key}
    >
      <div className="department-tile__header">
        <div className="department-tile__media">
          {media}
        </div>
        <span>
          {name}
        </span>
      </div>
      {text && (
        <div className="department-tile__body">
          {text}
        </div>
      )}
    </NavigationLink>
  );
};

DepartmentTile.propTypes = {
  href: PropTypes.string.isRequired,
  key: PropTypes.string,
  media: PropTypes.oneOfType([PropTypes.object]),
  name: PropTypes.string.isRequired,
  target: PropTypes.string,
  text: PropTypes.string,
};

DepartmentTile.defaultProps = {
  key: null,
  media: null,
  target: null,
  text: null,
};

export default DepartmentTile;
