import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import TextSection from "../textSection/textSection";

import "./networkSection.scss";

const NetworkSection = (props) => {
  const {
    children,
    headline,
    text,
    variant,
  } = props;

  const items = React.Children.toArray(children);

  let colProps;

  switch (variant) {
    case "lab":
      colProps = {
        md: 6,
        xl: 4,
        xs: 12,
      };
      break;
    default: colProps = {
      md: 6,
      xs: 12,
    };
  }

  return (
    <div className={classNames("container--fullwidth network-section",
      { [`network-section--${variant}`]: variant })}
    >
      <Container fluid>
        <TextSection
          headline={headline}
          text={text}
        />
        <Row className="network-section__grid">
          {items.map((item) => (
            <Col {...colProps} key={item.key}>
              {item}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

NetworkSection.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf(["lab"]),
};

NetworkSection.defaultProps = {
  headline: null,
  text: null,
  variant: null,
};

export default NetworkSection;
