import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./storyTile.scss";

const StoryTile = (props) => {
  const {
    number,
    title,
    text,
    formatNumber,
  } = props;

  const intl = useIntl();

  return (
    <div className="story-tile">
      <div className="story-tile__number">
        {formatNumber ? intl.formatNumber(number) : number}
      </div>
      <div className="story-tile__title">
        {title}
      </div>
      {text && (<p>{text}</p>)}
    </div>
  );
};

StoryTile.propTypes = {
  formatNumber: PropTypes.bool,
  number: PropTypes.number.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

StoryTile.defaultProps = {
  formatNumber: false,
  text: null,
};

export default StoryTile;
