import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ExternalResourcesCategoryListElements from "./tools/externalResourceCategoryListElements";
import IconButton from "../components/button/iconButton";
import NavigationLink from "../components/navigation/navigationLink";
import Section from "../components/section/section";
import { IconAnchorRight18, IconFurther18 } from "../icons";

const KontentItemResourceSection = ({ data, limit }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  const categories = [];

  section.elements.categories.value.forEach((category) => {
    categories.push(category.codename);
  });

  const listElements = ExternalResourcesCategoryListElements({ categories });

  return (
    <Section
      headline={(
        <>
          {section.elements.headline.value}
          {section.elements.button_url.value.length > 0 && (
            <NavigationLink
              href={section.elements.button_url.value[0].id}
              className="no-style"
            >
              <IconAnchorRight18 />
            </NavigationLink>
          )}
        </>
      )}
      isFullWidthChild
      button={section.elements.button_url.value.length > 0 && listElements.length > limit && (
        <IconButton variant="outline-faq" href={section.elements.button_url.value[0].id}>
          <FormattedMessage id="resources_overview.view_all" /> <IconFurther18 />
        </IconButton>
      )}
    >
      <div className="d-none d-xl-block mb-xl-5">
        <Row className="resources-section__list-columns">
          <Col xl={2} className="typo-label">
            <FormattedMessage id="resourcessection.name" />
          </Col>
          <Col xl={4} className="typo-label">
            <FormattedMessage id="resourcessection.description" />
          </Col>
          <Col xl={3} className="typo-label">
            <FormattedMessage id="resourcessection.type" />
          </Col>
          <Col xl={3} className="typo-label">
            <FormattedMessage id="resourcessection.actions" />
          </Col>
        </Row>
      </div>
      {listElements.slice(0, limit)}
    </Section>
  );
};

export const query = graphql`
  fragment KontentItemResourceSection on kontent_item_resource_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      categories {
        value {
          codename
          name
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemResourceSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
};

KontentItemResourceSection.defaultProps = {
  limit: null,
};

export default KontentItemResourceSection;
