import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";

import Breadcrumb from "../../components/breadcrumb/breadcrumb";

const BreadcrumbGenerator = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
    }
  `);

  if (id) {
    const node = _.find(
      data.links.nodes,
      (child) => child.id.includes(id),
    );

    if (node) {
      const slugParts = node.slug.split("/");
      // Remove first empty element
      slugParts.shift();

      const items = [];
      let slug = "";

      if (slugParts.length > 0 && slugParts[0]) {
        slugParts.forEach((slugPart) => {
          slug += `/${slugPart}`;

          const tmpNode = _.find(data.links.nodes, {
            slug,
          });

          if (tmpNode) {
            items.push({ link: tmpNode.slug, title: tmpNode.title });
          } else {
            items.push({ title: _.startCase(slugPart) });
          }
        });
      }

      return <Breadcrumb items={items} />;
    }
  }

  return null;
};

BreadcrumbGenerator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BreadcrumbGenerator;
